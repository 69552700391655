<template lang="">
  <div>
    <!-- inicio:: Asignacion ods secundarios-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Objetivos de Desarrollo Sostenible secundarios
            </v-subheader>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-on:submit.prevent="asignarOdsSecundario"
          v-model="validFormOdsSecundario"
          class="pt-4 pl-2 pr-2"
        >
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosOds.odsId"
                :items="ods"
                :loading="ddOdsLoading"
                :clearable="true"
                dense
                filled
                label="Seleccionar Objetivo de Desarrollo Sostenible"
                item-text="nombreODS"
                item-value="id"
                :no-data-text="
                  ods.length > 0
                    ? 'Seleccione Objetivo de Desarrollo Sostenible secundario'
                    : 'No se han encontrado Objetivos de Desarrollo Sostenible secundario'
                "
                :rules="[selectRequired('Ods')]"
                menu-props="auto"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row v-if="!seccionesBloqueadas || tipoUsuario == `int`">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="!validFormOdsSecundario"
                :loading="btnRegistroLoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="mt-4">
          <v-col cols="12" md="12" sm="12">
            <v-subheader class="text-subtitle-1 black---text">
              ODS secundarios registrados
            </v-subheader>
            <v-divider class="mt-0 pt-0"></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <!--inicio:: tabla de documentos cargados -->
          <v-col cols="12" md="12" sm="12" class="pt-4">
            <v-data-table
              class="elevation-1"
              :headers="headersOds"
              :items="odsSecundarios"
              :loading="tableLoadingOdsSecundarios"
              hide-default-footer
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}',
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <!-- <td>{{ item.municipio ? item.municipio + ", " :  ""}} {{item.departamento}}</td> -->
                  <td>{{ item.nombreODS }}</td>
                  <!-- <td> {{ item.metaODS }}</td>
                                        <td>{{ item.metaKatun }}</td> -->
                  <!--<td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>-->
                  <!-- <td> {{parseFloat(item.montoSuscrito).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</td> -->
                  <td>
                    <v-btn
                      v-if="item.estadosId == 1 && !seccionesBloqueadas"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarOdsDisabled"
                      :loading="btnEliminarOdsDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarOdsSecunadario(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar ODS
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin:: Asignacion ods secundarios-->

    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
  </div>
</template>
<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

import {
  OBTENER_ODS_SECUNDARIOS,
  REGISTRAR_ODS_SECUNDARIO,
  ELIMINAR_ODS_SECUNDARIO,
} from "@/core/services/store/proyectoscns/coberturas/coberturacns.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

export default {
  name: "AsigOdsSecundarioComponent",
  props: ["proyectoId", "tipoProyecto", "tipoAccion", "seccionesBloqueadas", "tipoUsuario"], //TipoPRoyecto 1 CNS, 2 CNSC... Tipo acción 1 Registro, actualización, 2 Consulta
  components: {
    DialogLoader,
    SnackAlert,
  },
  data() {
    return {
      validFormOdsSecundario: false,
      ddOdsLoading: false,
      btnRegistroLoading: false,

      btnEliminarOdsDisabled: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      ods: [],
      tableLoadingOdsSecundarios: false,
      odsSecundarios: [],
      datosOds: {
        odsId: 0,
        estadosId: 1,
        tipoProyecto: this.tipoProyecto,
        usuarioCreacion: "admin",
      },
      ...validations,
    };
  },

  methods: {
    //Obtener las ods
    async obtenerOds() {
      this.ddOdsLoading = true;
      this.ods = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `ODS/all/1` })
        .then((res) => {
          if (res.status === 200) {
            this.ods = res.data;
          }
          this.ddOdsLoading = false;
        })
        .catch(() => {
          this.pnds = [];
          this.ddOdsLoading = false;
        });
    },

    //Obteener los objetivos secotriales PGG por objetivo estrategico pgg Id
    async obtenerOdsRegistrados(tipoProyecto, proyectoId) {
      this.tableLoadingOdsSecundarios = true;
      //this.datosCobertura.ObjetivosSectorialesPGGId =0;
      this.odsSecundarios = [];
      await this.$store
        .dispatch(OBTENER_ODS_SECUNDARIOS, {
          tipo: tipoProyecto,
          id: proyectoId,
          estadoId: 1,
        })
        .then((res) => {
          if (res.status === 200) {
            this.odsSecundarios = res.data;
          }
          this.tableLoadingOdsSecundarios = false;
        })
        .catch(() => {
          this.odsSecundarios = [];
          this.tableLoadingOdsSecundarios = false;
        });
    },

    async asignarOdsSecundario() {
      this.btnRegistroLoading = true;

      if (this.datosOds.tipoProyecto === 1) {
        this.datosOds.proyectosCnsId = this.proyectoId;
      } else if (this.datosOds.tipoProyecto === 2) {
        this.datosOds.proyectoCnscId = this.proyectoId;
      }

      await this.$store
        .dispatch(REGISTRAR_ODS_SECUNDARIO, { datos: this.datosOds })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerOdsRegistrados(this.tipoProyecto, this.proyectoId);
            this.datosOds.odsId = 0;
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch((error) => {
          this.btnRegistroLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Eliminar registro ods secundario
    async eliminarOdsSecunadario(id) {
      this.btnEliminarOdsDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_ODS_SECUNDARIO, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerOdsRegistrados(this.tipoProyecto, this.proyectoId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarOdsDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarOdsDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },
  },

  created() {
    this.obtenerOds();
    this.obtenerOdsRegistrados(this.tipoProyecto, this.proyectoId);
  },

  computed: {
    headersOds() {
      return [
        {
          text: "Id",
          //align: "start",
          sortable: false,
          value: "id",
          align: " d-none",
        },

        {
          text: "ODS secundario",
          align: "start",
          sortable: true,
          value: "nombre",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "",
        },
      ];
    },
  },
};
</script>
<style lang=""></style>
