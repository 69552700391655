var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Contactos del proyecto ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-row',{staticClass:"pb-3"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-alert',{attrs:{"outlined":"","color":"#1488c2","border":"left"}},[_c('p',{staticClass:"mb-1"},[_vm._v(" Los campos marcados con un asterisco ("),_c('span',{staticClass:"red--text",attrs:{"color":"red"}},[_vm._v("*")]),_vm._v(") son obligatorios ")])])],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"formProyeccionDesembolsos",on:{"submit":function($event){$event.preventDefault();return _vm.registrarPosicion.apply(null, arguments)}},model:{value:(_vm.validFormPosicion),callback:function ($$v) {_vm.validFormPosicion=$$v},expression:"validFormPosicion"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Información del proyecto ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Dirección física del proyecto","rules":[
                  _vm.required('dirección física del proyecto'),
                  _vm.minLength('dirección física del proyecto', 5),
                  _vm.maxLength('dirección física del proyecto', 200)
                ],"maxlength":"200","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosPosicion.direccionFisicaProyecto),callback:function ($$v) {_vm.$set(_vm.datosPosicion, "direccionFisicaProyecto", $$v)},expression:"datosPosicion.direccionFisicaProyecto"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Teléfono directo del proyecto","rules":[
                  _vm.required('teléfono'),
                  _vm.minLength('teléfono', 5),
                  _vm.maxLength('teléfono', 25)
                ],"maxlength":"25","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosPosicion.telefonoDirectoProyecto),callback:function ($$v) {_vm.$set(_vm.datosPosicion, "telefonoDirectoProyecto", $$v)},expression:"datosPosicion.telefonoDirectoProyecto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","large":"","elevation":0,"disabled":!_vm.validFormPosicion ||
                    (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"loading":_vm.btnRegistroPosicionLoading}},[_vm._v(" Guardar información ")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Contactos "),_c('v-spacer'),(
                  (_vm.tipoUsuario != "int" && !_vm.seccionesBloqueadas) ||
                    _vm.tipoUsuario === "int"
                )?_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","small":"","elevation":0},on:{"click":function($event){return _vm.limpiarCamposContactos()}}},[_vm._v(" Limpiar campos ")]):_vm._e()],1),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),(
            (_vm.tipoUsuario != "int" && !_vm.seccionesBloqueadas) ||
              _vm.tipoUsuario === "int"
          )?_c('v-form',{ref:"formProyeccionDesembolsos",on:{"submit":function($event){$event.preventDefault();return _vm.registrarContactos.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.tiposContacto,"color":"blue-grey lighten-2","label":"Seleccione el puesto del contacto","item-text":"tipo","item-value":"id","rules":[_vm.selectRequired('tipo de contacto')],"disabled":_vm.accion === 2 ||
                    (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"menu-props":"auto","return-object":""},model:{value:(_vm.tipoContactoSeleccionado),callback:function ($$v) {_vm.tipoContactoSeleccionado=$$v},expression:"tipoContactoSeleccionado"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":_vm.tipoContactoSeleccionado.id === 8
                    ? 'Nombre de la máxima autoridad'
                    : _vm.tipoContactoSeleccionado.id === 7
                    ? 'Nombre del gerente'
                    : 'Nombre',"rules":[
                  _vm.required('nombre del contacto'),
                  _vm.minLength('nombre del contacto', 5),
                  _vm.maxLength('nombre del contacto', 100)
                ],"maxlength":"100","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosContacto.nombre),callback:function ($$v) {_vm.$set(_vm.datosContacto, "nombre", $$v)},expression:"datosContacto.nombre"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"color":"blue-grey lighten-2","autocomplete":"off","dense":"","filled":"","label":"Teléfono institucional","rules":[
                  _vm.required('teléfono institucional'),
                  _vm.maxLength('teléfono institucional', 20)
                ],"maxlength":"20","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosContacto.telefonoInstitucional),callback:function ($$v) {_vm.$set(_vm.datosContacto, "telefonoInstitucional", $$v)},expression:"datosContacto.telefonoInstitucional"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":_vm.tipoContactoSeleccionado.id === 8
                    ? 'Correo institucional de la máxima autoridad'
                    : _vm.tipoContactoSeleccionado.id === 7
                    ? 'Correo institucional del gerente'
                    : 'Correo institucional',"rules":[
                  _vm.required('correo electrónico'),
                  _vm.minLength('correo electrónico', 5),
                  _vm.maxLength('correo electrónico', 150),
                  _vm.validEmail('correo electrónico')
                ],"maxlength":"150","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosContacto.correoElectronicoInstitucional),callback:function ($$v) {_vm.$set(_vm.datosContacto, "correoElectronicoInstitucional", $$v)},expression:"datosContacto.correoElectronicoInstitucional"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","large":"","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroContactoLoading}},[_vm._v(" "+_vm._s(_vm.accion === 1 ? "Guardar información" : "Guardar información")+" ")])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text pb-0"},[_vm._v(" Contactos registrados ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersContactos,"items":_vm.contactos,"loading":_vm.tableContactosLoading,"hide-default-footer":"","footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.tipo))]),_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[_vm._v(" "+_vm._s(item.telefonoInstitucional)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.correoElectronicoInstitucional)+" ")]),_c('td',[_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"label":"","color":item.estadosId === 1
                          ? 'blue lighten-4'
                          : item.estadosId === 2
                          ? 'deep-orange lighten-4'
                          : item.estadosId === 6
                          ? 'cyan lighten-4'
                          : item.estadosId === 7
                          ? 'deep-orange lighten-4'
                          : 'pink lighten-4',"text-color":item.estadosId === 1
                          ? 'blue lighten-1'
                          : item.estadosId === 2
                          ? 'deep-orange lighten-1'
                          : item.estadosId === 6
                          ? 'cyan darken-1'
                          : item.estadosId === 7
                          ? 'deep-orange lighten-1'
                          : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[(
                        item.estadosId == 1 &&
                          ((_vm.tipoUsuario != "int" && !_vm.seccionesBloqueadas) ||
                            _vm.tipoUsuario === "int")
                      )?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarDisabled,"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarContacto(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1):_vm._e(),(
                        item.estadosId == 1 &&
                          ((_vm.tipoUsuario != "int" && !_vm.seccionesBloqueadas) ||
                            _vm.tipoUsuario === "int")
                      )?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarDisabled,"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.obtenerDatosContacto(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Editar ")],1):_vm._e()],1)])]}}],null,false,3800674702)})],1)],1)],1)],1),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }