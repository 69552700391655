<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
        <v-subheader class="text-h5 black--text">
          Detalle del monto suscrito comprometido en el convenio
        </v-subheader>
      </v-col>
    </v-row>

    <v-row class="pb-3">
      <v-col cols="12" md="12" sm="12">
        <v-alert outlined color="#1488c2" border="left">
          <p class="mb-1">
            Los campos marcados con un asterisco (<span
              color="red"
              class="red--text"
              >*</span
            >) son obligatorios
          </p>
        </v-alert>
      </v-col>
    </v-row>

    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="card-label text-h6 font-weight-bolder text-dark">
              Ingreso a presupuesto</span
            ><br />
            <span class="red--text mt-3 font-weight-bold text-subtitle-1">
              *Indique si el proyecto ingresa a presupuesto
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-divider class="mt-0 pt-3 pb-2"></v-divider>
          </v-col>
        </v-row>

        <v-form
          ref="formDatosIngresaPresupuesto"
          v-on:submit.prevent="registrarDatosIngresaPresupuesto"
          v-model="validFormDatosPresupuesto"
        >
          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosIngresaPresupuesto.ingresaPresupuesto"
                :items="ingresaAPresupuestoItems"
                :loading="false"
                dense
                class="required"
                filled
                :rules="[selectRequired('ingresa a presupuesto')]"
                label="¿El proyecto ingresa a presupuesto?"
                item-text="text"
                item-value="id"
                menu-props="auto"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
              sm="12"
              class="pt-0 pb-0"
              v-if="datosIngresaPresupuesto.ingresaPresupuesto === 1"
            >
              <v-text-field
                dense
                filled
                autocomplete="off"
                :class="
                  datosIngresaPresupuesto.ingresaPresupuesto === 1
                    ? 'required'
                    : ''
                "
                color="blue-grey lighten-2"
                v-model="datosIngresaPresupuesto.codigoInstitucion"
                label="Código de la institución"
                :disabled="
                  datosIngresaPresupuesto.ingresaPresupuesto === 2 ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :rules="[
                  datosIngresaPresupuesto.ingresaPresupuesto === 1
                    ? required('código de la institución')
                    : true,
                  datosIngresaPresupuesto.ingresaPresupuesto === 1
                    ? maxLength('código de la institución', 50)
                    : true
                ]"
                maxlength="50"
              >
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
              sm="12"
              class="pt-0 pb-0"
              v-if="datosIngresaPresupuesto.ingresaPresupuesto === 1"
            >
              <v-text-field
                dense
                filled
                autocomplete="off"
                :class="
                  datosIngresaPresupuesto.ingresaPresupuesto == 1
                    ? 'required'
                    : ''
                "
                color="blue-grey lighten-2"
                v-model="datosIngresaPresupuesto.codigoFuenteCooperante"
                label="Código de la fuente cooperante"
                :disabled="
                  datosIngresaPresupuesto.ingresaPresupuesto == 2 ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :rules="[
                  datosIngresaPresupuesto.ingresaPresupuesto == 1
                    ? required('Código de la fuente cooperante')
                    : true,
                  datosIngresaPresupuesto.ingresaPresupuesto == 1
                    ? maxLength('Código de la fuente cooperante', 50)
                    : true
                ]"
                maxlength="50"
              >
              </v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
              sm="12"
              class="pt-0 pb-0"
              v-if="datosIngresaPresupuesto.ingresaPresupuesto === 1"
            >
              <v-text-field
                dense
                filled
                autocomplete="off"
                :class="
                  datosIngresaPresupuesto.ingresaPresupuesto == 1
                    ? 'required'
                    : ''
                "
                color="blue-grey lighten-2"
                v-model="datosIngresaPresupuesto.codigoProyecto"
                label="Código del proyecto"
                :disabled="
                  datosIngresaPresupuesto.ingresaPresupuesto == 2 ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :rules="[
                  datosIngresaPresupuesto.ingresaPresupuesto == 1
                    ? required('Código del proyecto')
                    : true,
                  datosIngresaPresupuesto.ingresaPresupuesto == 1
                    ? maxLength('Código del proyecto', 50)
                    : true
                ]"
                maxlength="50"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502 darken-4"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="
                  !validFormDatosPresupuesto ||
                    (tipoUsuario == `ext` && seccionesBloqueadas) ||
                    btnRegistroDatosPresupuestoLoading
                "
                :loading="btnRegistroDatosPresupuestoLoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="card-label text-h6 font-weight-bolder text-dark">
              Mecanismos de ejecución</span
            ><br />
            <span class="red--text mt-3 font-weight-bold text-subtitle-1">
              *Seleccione el tipo de normas que aplicará para los procesos de
              adquisición/contratación
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-divider class="mt-0 pt-3 pb-2"></v-divider>
          </v-col>
        </v-row>

        <v-form
          ref="formDatosFinancieros"
          v-on:submit.prevent="registrarMecanismoEjecucionCns"
          v-model="validFormMecanismo"
        >
          <v-row class="pt-2">
            <v-col cols="12" md="8" sm="6" xs="12" class="pt-0 pb-0">
              <v-select
                v-model="datosMecanismoEjecucion.mecanismosEjecucionId"
                :items="mecanismosEjecucion"
                :loading="false"
                dense
                filled
                label="Mecanismo"
                item-text="nombreMecanismo"
                item-value="id"
                :rules="[selectRequired('moneda')]"
                :no-data-text="
                  mecanismosEjecucion != null
                    ? 'Selecciona un mecanismo'
                    : 'No se han encontrado mecanismos'
                "
                menu-props="auto"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502 darken-4"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="
                  !validFormMecanismo ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :loading="btnRegistroMecanismoLoading"
              >
                Registrar mecanismo
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="mt-4">
          <!--inicio:: tabla datos financieros -->
          <v-col cols="12" md="12" sm="12" class="pt-2">
            <v-data-table
              outlined
              dense
              class="elevation-1"
              :headers="headersMecanismosEjecucion"
              :items="mecanismosRegistrados"
              :loading="tableMecanismosLoading"
              :header-props="{
                sortByText: 'Ordenar por'
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.nombreMecanismo }}</td>
                  <td>
                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarMecanismoDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarMecanismo(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existen mecanismos
                  registrados para este proyecto
                </p>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-6" outlined>
      <v-card-text>
        <!-- inicio:: seccion datos financieros-->
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Datos financieros del proyecto
            </v-subheader>
            <v-divider class="mt-0 pt-1 pb-6"></v-divider>
          </v-col>
        </v-row>

        <v-form
          ref="formDatosFinancieros"
          v-on:submit.prevent="agregarDatoFinanciero"
          v-model="validFormDatosFinancieros"
        >
          <v-row>
            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
              <v-select
                v-model="datosFinancieros.tiposDatosFinancierosId"
                :items="tiposDatosFinancieros"
                :loading="ddTiposDatosFinancierosLoading"
                dense
                filled
                label="Datos financieros"
                item-text="nombreTipoDatoFinanciero"
                item-value="id"
                :rules="[selectRequired('tipo de datos financieros')]"
                :no-data-text="
                  tiposDatosFinancieros != null
                    ? 'Selecciona un tipo de dato financiero'
                    : 'No se han encontrado tipos de datos financieros'
                "
                menu-props="auto"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
              <v-autocomplete
                v-model="monedaSeleccionada"
                :items="monedas"
                :loading="ddMonedasLoading"
                dense
                filled
                label="Moneda"
                class="required"
                :item-text="
                  item => item.nombreMoneda + ' (' + item.codigoMoneda + ')'
                "
                item-value="id"
                return-object
                :rules="[selectRequired('moneda')]"
                :no-data-text="
                  monedas != null
                    ? 'Selecciona una moneda'
                    : 'No se han encontrado monedas'
                "
                menu-props="auto"
                @change="
                  monedaSeleccionada.id === 125
                    ? (tipoCambio = `1`)
                    : (tipoCambio = ``)
                "
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="montoMonedaOriginal"
                :label="
                  `Monto moneda original (${
                    monedaSeleccionada.codigoMoneda
                      ? monedaSeleccionada.codigoMoneda
                      : 'Moneda no seleccionada'
                  })`
                "
                :rules="[
                  required('monto moneda original'),
                  notComma('monto moneda original'),
                  decimals10('monto moneda original')
                ]"
                maxlength="30"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="tipoCambio"
                label="Tipo de cambio"
                :rules="[
                  required('tipo de cambio'),
                  decimals10('tipo de cambio')
                ]"
                maxlength="30"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                :disabled="true"
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                suffix="GTQ"
                v-model="datosFinancieros.montoTotalQuetzales"
                label="Monto total quetzales"
                maxlength="30"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="
                  !validFormDatosFinancieros ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :loading="btnDatosFinancierosLoading"
              >
                Agregar datos financieros
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-alert outlined type="error" color="orange darken-1" dense>
                Si la moneda es Quetzales (GTQ) el tipo de cambio debe ser igual
                a 1
              </v-alert>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="mt-4">
          <v-col cols="12" md="12" sm="12">
            <v-subheader class="text-subtitle-1 black--text">
              Datos financieros registrados
            </v-subheader>
            <v-divider class="mt-0 pt-0"></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <!--inicio:: tabla datos financieros -->
          <v-col cols="12" md="12" sm="12" class="pt-2">
            <v-data-table
              outlined
              dense
              class="elevation-1"
              :headers="headersDatosFinancieros"
              :items="datosFinancierosProyecto"
              :loading="tableDatosFinancierosLoading"
              :header-props="{
                sortByText: 'Ordenar por'
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.nombreTipoDatoFinanciero }}</td>
                  <td>{{ item.nombreMoneda }}</td>
                  <td class="text-right">
                    {{ `${item.codigoMoneda}  ${item.montoMonedaOriginal}` }}
                  </td>
                  <td class="text-right">{{ item.tipoCambio }}</td>
                  <td class="text-right">
                    {{
                      parseFloat(
                        item.montoTotalQuetzales
                      ).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      })
                    }}
                  </td>
                  <!-- <td class="text-right"> Q {{ parseFloat(item.montoTotalQuetzales) }}</td> -->
                  <td>
                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarDatoFinanciero(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de datos financieros
                </p>
              </template>
            </v-data-table>

            <v-row
              class="mt-4"
              v-if="
                contraPartidaRegistrada &&
                  otrosAportesRegistrados &&
                  alertaDatosFinancierosMonedaCambio
              "
            >
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-alert outlined type="error" color="red darken-2" dense>
                  Los datos de la contrapartida y otros aportes no coinciden
                  (Revise la moneda y el tipo de cambio de ambos datos
                  financieros)
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
          <!--fin:: tabla datos financieros -->
          <!-- inicio: total de la secion -->

          <v-col
            cols="12"
            md="6"
            sm="12"
            class="pt-0 pb-0"
            v-if="datosFinancierosProyecto.length > 0"
          >
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto Suscrito Fuente Cooperante</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6">{{
                  parseFloat(totalDatosFinancierosFC).toLocaleString("es-GT", {
                    style: "currency",
                    currency: "GTQ",
                    maximumFractionDigits: maxDigits
                  })
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            cols="12"
            md="6"
            sm="12"
            class="pt-0 pb-0"
            v-if="datosFinancierosProyecto.length > 0"
          >
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto Suscrito Nacional + Otros</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6">{{
                  parseFloat(totalDatosFinancierosCP).toLocaleString("es-GT", {
                    style: "currency",
                    currency: "GTQ",
                    maximumFractionDigits: maxDigits
                  })
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!--  <v-col cols="12" md="6" sm="12" class="pt-0 pb-0"  v-if="datosFinancierosProyecto.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Monto Suscrito Otros</v-list-item-title>
                                <v-list-item-subtitle class="text-h6">{{parseFloat(totalDatosFinancierosOtros).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: maxDigits })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col> -->

          <v-col
            cols="12"
            md="6"
            sm="12"
            class="pt-0 pb-0"
            v-if="datosFinancierosProyecto.length > 0"
          >
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto Suscrito Total</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6">{{
                  parseFloat(totalDatosFinancieros).toLocaleString("es-GT", {
                    style: "currency",
                    currency: "GTQ",
                    maximumFractionDigits: maxDigits
                  })
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!--fin:: total de la seccion -->
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin:: seccion datos financieros-->

    <!-- inicio:: seccion distribucion monto suscrito fuente cooperante-->
    <v-card class="mt-6" outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="card-label text-h6 font-weight-bolder text-dark">
              Distribución del monto suscrito con la fuente cooperante </span
            ><br />
            <span class="red--text mt-3 font-weight-bold text-subtitle-1">
              *El aporte nacional será ejecutado en su totalidad por la Unidad
              Ejecutora
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-divider class="mt-0 pt-3 pb-4"></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <!--<v-subheader class="text-h5 black--text">
                            
                        </v-subheader>-->
            <v-alert
              text
              :value="datosFinancierosProyecto.length <= 0"
              type="warning"
              color="orange darken-1"
              icon="mdi-alert"
            >
              Para poder agregar la distribución de desembolsos, debe registrar
              datos financieros del proyecto!
            </v-alert>
            <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
          </v-col>
        </v-row>

        <v-form
          ref="formProyeccionDesembolsos"
          v-on:submit.prevent="agregarDistribucionMontoSuscrito"
          v-model="validFormDistribucion"
        >
          <v-row class="mt-4">
            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
              <v-select
                v-model="datosDistribucion.tiposDatosFinancierosDistId"
                :items="tiposDatosFinancierosDistribucion"
                :loading="ddTiposDatosFinancierosDistLoading"
                :disabled="datosFinancierosProyecto.length <= 0"
                dense
                filled
                class="required"
                label="Datos financieros"
                item-text="tipo"
                :rules="[selectRequired('datos financieros')]"
                item-value="id"
                :no-data-text="
                  mecanismosEjecucion != null
                    ? 'Selecciona un tipo de dato financiero'
                    : 'No se han encontrado tipos de datos financieros'
                "
                menu-props="auto"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                :disabled="datosFinancierosProyecto.length <= 0"
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                :suffix="monedaFuenteCooperante"
                v-model="datosDistribucion.montoMonedaOriginal"
                label="Monto moneda original"
                :rules="[
                  notComma('monto moneda original'),
                  required('monto moneda original'),
                  decimals10('monto moneda original')
                ]"
                maxlength="250"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="
                  !validFormDistribucion ||
                    datosFinancierosProyecto.length <= 0 ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :loading="btnDistribucionLoading"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <!--inicio:: tabla distribucion monto suscrito fuente cooperante-->
          <v-col cols="12" md="12" sm="12" class="pt-6">
            <v-data-table
              dense
              class="elevation-1"
              :headers="headersDistribucion"
              :items="distribucionesMontoSuscrito"
              :loading="tableLoadingDistribucion"
              :header-props="{
                sortByText: 'Ordenar por'
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              hide-default-footer
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.tipo }}</td>
                  <td class="text-right">
                    {{
                      item.codigoMoneda
                        ? item.codigoMoneda
                        : monedaFuenteCooperante
                    }}
                    {{ item.montoMonedaOriginal }}
                  </td>
                  <td class="text-right">{{ item.tipoCambio }}</td>
                  <td class="text-right">
                    {{
                      (
                        parseFloat(item.montoMonedaOriginal) *
                        parseFloat(item.tipoCambio)
                      ).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits
                      })
                    }}
                  </td>
                  <td>
                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      :disabled="btnEliminarDistribucionFuenteDisabled"
                      @click="eliminarDistribucionMontoSuscrito(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de distribución de montos
                </p>
              </template>
            </v-data-table>
          </v-col>
          <!--fin:: tabla datos financieros -->

          <!-- inicio: total de la secion -->
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto total</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6">{{
                  parseFloat(totalDistribucionMontos).toLocaleString("es-GT", {
                    style: "currency",
                    currency: "GTQ",
                    maximumFractionDigits: maxDigits,
                  })
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!--fin:: total de la seccion -->

          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <!--<v-subheader class="text-h5 black--text">
                            
                        </v-subheader>-->
            <v-alert
              text
              :value="alertaTotalDistribucion"
              type="error"
              color="orange darken-1"
              icon="mdi-alert"
            >
              El total de la distribución
              {{
                parseFloat(totalDistribucionMontos).toLocaleString("es-GT", {
                  style: "currency",
                  currency: "GTQ",
                  maximumFractionDigits: maxDigits
                })
              }}
              sobrepasa el monto suscrito por la fuente cooperante
              {{
                parseFloat(totalDatosFinancierosFC).toLocaleString("es-GT", {
                  style: "currency",
                  currency: "GTQ",
                  maximumFractionDigits: maxDigits
                })
              }}
            </v-alert>
            <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
          </v-col>
        </v-row>
        <!-- fin:: seccion distribucion monto suscrito fuente cooperante-->
      </v-card-text>
    </v-card>

    <!-- inicio:: seccion proyeccion de desembolosos-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Proyección de desembolsos
            </v-subheader>
            <v-alert
              v-if="habilitarProyeccionDesembolsos"
              text
              :value="datosFinancierosProyecto.length <= 0"
              type="error"
              icon="mdi-alert"
            >
              Para poder agregar la proyección de desembolsos, debe registrar
              datos financieros del proyecto!
            </v-alert>
            <v-divider class="mt-0 pt-1 pb-6"></v-divider>
          </v-col>
        </v-row>

        <v-form
          ref="formProyeccionDesembolsos"
          v-on:submit.prevent="actualizarCuentaCondicionesDesembolsos"
          v-model="validFormCuentaDesembolsos"
        >
          <v-row>
            <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0">
              <v-select
                v-model="cuentaCondicionDesembolsoSeleccionada"
                :items="condicionesDesembolsosItems"
                :loading="false"
                dense
                filled
                class="required"
                return-object
                label="¿Cuenta con condiciones de desembolsos?"
                item-text="text"
                item-value="id"
                menu-props="auto"
                :disabled="
                  cuentaDesembolsosLoading ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="
                  !validFormCuentaDesembolsos ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :loading="cuentaDesembolsosLoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-divider class="mt-4 pt-0"></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="pt-6 pb-0"
            v-if="habilitarProyeccionDesembolsos"
          >
            <v-form
              ref="formProyeccionDesembolsos"
              v-on:submit.prevent="agregarProyeccionDesembolsos"
              v-model="validFormProyeccionDesembolsos"
            >
              <v-row>
                <v-col cols="12" md="3" sm="6" class="pt-0 pb-0">
                  <v-select
                    v-model="datosProyeccionDesembolsos.periodosId"
                    :items="periodos"
                    :loading="ddPeriodosLoading"
                    :disabled="datosFinancierosProyecto.length <= 0"
                    dense
                    filled
                    label="Año/Periodo"
                    item-text="periodo"
                    item-value="id"
                    :rules="[selectRequired('periodo / año')]"
                    :no-data-text="
                      tiposDatosFinancieros != null
                        ? 'Selecciona un periodo'
                        : 'No se han encontrado periodos'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                  <v-select
                    v-model="datosProyeccionDesembolsos.tiposDesembolsoId"
                    :items="tiposDesembolso"
                    :loading="ddTiposDesembolsoLoading"
                    dense
                    filled
                    :disabled="datosFinancierosProyecto.length <= 0"
                    label="Tipo de desembolso"
                    item-text="detalle"
                    item-value="id"
                    :rules="[selectRequired('Tipo de desembolso')]"
                    :no-data-text="
                      tiposDesembolso != null
                        ? 'Selecciona un tipo de desembolso'
                        : 'No se han encontrado tipos de desembolso'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    :disabled="datosFinancierosProyecto.length <= 0"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosProyeccionDesembolsos.montoOriginal"
                    label="Monto moneda original"
                    :rules="[
                      notComma('monto moneda original'),
                      required('monto moneda original'),
                      decimals10('monto moneda original'),
                    ]"
                    maxlength="250"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="2" sm="2" class="pt-0 pb-0">
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    :elevation="0"
                    :disabled="
                      !validFormProyeccionDesembolsos ||
                        datosFinancierosProyecto.length <= 0 ||
                        (tipoUsuario == `ext` && seccionesBloqueadas)
                    "
                    :loading="btnRegistroProyeccionLoading"
                  >
                    Agregar proyección
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <!--inicio:: tabla proyeccion de desembolosos-->
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="pt-4"
            v-if="habilitarProyeccionDesembolsos"
          >
            <v-data-table
              dense
              class="elevation-1"
              :headers="headersProyeccionDesembolsos"
              :items="proyeccionesDesembolsos"
              :loading="tableLoadingProyecciones"
              :header-props="{
                sortByText: 'Ordenar por'
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              hide-default-footer
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.periodo }}</td>
                  <td>{{ item.detalle }}</td>
                  <td class="text-right">
                    {{ monedaFuenteCooperante }} {{ item.montoOriginal }}
                  </td>
                  <td class="text-right">{{ item.tipoCambio }}</td>
                  <td class="text-right">
                    {{
                      (
                        parseFloat(item.montoOriginal) *
                        parseFloat(item.tipoCambio)
                      ).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits
                      })
                    }}
                  </td>
                  <!--<td>{{ item.detalleFormaOperar }}</td>-->
                  <td>
                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      :disabled="btnEliminarProyeccionDesembolsoDisabled"
                      @click="eliminarProyeccionDesembolsos(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de proyección de desembolsos
                </p>
              </template>
            </v-data-table>
          </v-col>
          <!--fin:: tabla datos financieros -->

          <!-- inicio: total de la secion -->
          <v-col
            cols="12"
            md="6"
            sm="12"
            class="pt-0 pb-0"
            v-if="habilitarProyeccionDesembolsos"
          >
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto total fuente cooperante</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6"
                  >{{
                    parseFloat(
                      totalProyeccionDesembolsos
                    ).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ",
                      maximumFractionDigits: maxDigits,
                    })
                  }}
                  /
                  {{
                    parseFloat(totalDatosFinancierosFC).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits
                      }
                    )
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!--fin:: total de la seccion -->

          <!-- inicio: total de la secion -->
          <v-col
            cols="12"
            md="6"
            sm="12"
            class="pt-0 pb-0"
            v-if="habilitarProyeccionDesembolsos"
          >
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto total contrapartida</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6"
                  >{{
                    parseFloat(
                      totalProyeccionDesembolsosContraPartida
                    ).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ",
                      maximumFractionDigits: maxDigits
                    })
                  }}
                  /
                  {{
                    parseFloat(totalDatosFinancierosCP).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits
                      }
                    )
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!--fin:: total de la seccion -->

          <!-- inicio: detalle forma de operar -->
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="mt-4 pt-0 pb-0"
            v-if="!habilitarProyeccionDesembolsos"
          >
            <v-form
              ref="formDetalleFormaOperar"
              v-on:submit.prevent="registrarFormaOperar"
              v-model="validFormFormaOperar"
            >
              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-textarea
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    :disabled="
                      habilitarProyeccionDesembolsos ||
                        (tipoUsuario == `ext` && seccionesBloqueadas)
                    "
                    color="blue-grey lighten-2"
                    v-model="datosFormaOperar.detalleFormaOperar"
                    label="Observaciones"
                    :rules="[
                      required('observaciones'),
                      minLength('observaciones', 5),
                      maxLength('observaciones', 2000)
                    ]"
                    maxlength="2000"
                    rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-4">
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right mt-2"
                    type="submit"
                    :elevation="0"
                    :disabled="
                      !validFormFormaOperar ||
                        (tipoUsuario == `ext` && seccionesBloqueadas)
                    "
                    :loading="btnRegistroFormaOperarLoading"
                  >
                    Actualizar observaciones
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <!-- fin:: detalle forma de operar -->
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin:: seccion proyeccion de desembolosos-->

    <!-- inicio:: seccion detalle de monto suscrito por departamento-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Detalle del monto suscrito de la fuente cooperante a nivel
              territorial
            </v-subheader>
          </v-col>
        </v-row>

        <v-row v-if="habilitarMontoTerritorial">
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <!--<v-subheader class="text-h5 black--text">
                            
                        </v-subheader>-->
            <v-alert
              text
              :value="datosFinancierosProyecto.length <= 0"
              type="error"
              color="orange darken-1"
              icon="mdi-alert"
            >
              Para poder agregar el detalle del monto suscrito de la fuente
              cooperante a nivel territorial, debe registrar datos financieros
              del proyecto!
            </v-alert>
            <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
          </v-col>
        </v-row>

        <v-form
          ref="formDetalleNivelTerritorial"
          v-on:submit.prevent="actualizarCuentaMontoTerritorial"
          v-model="validFormCuentaMontoTerritoral"
        >
          <v-row class="mt-4">
            <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0">
              <v-select
                v-model="cuentaMontoSuscritoTerritoralSeleccionado"
                :items="montoSuscritoTerritorioItems"
                :loading="false"
                dense
                filled
                class="required"
                return-object
                label="¿Cuenta con información del monto suscrito a nivel territorial?"
                item-text="text"
                item-value="id"
                menu-props="auto"
                :disabled="
                  cuentaMonoSuscritoTerritorialLoading ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="
                  cuentaMonoSuscritoTerritorialLoading ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :loading="cuentaMonoSuscritoTerritorialLoading"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-">
            <v-divider class="mt-0 pt-1 pb-6"></v-divider>
          </v-col>
        </v-row>

        <v-row v-if="habilitarMontoTerritorial">
          <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
            <v-form
              ref="formProyeccionDesembolsos"
              v-on:submit.prevent="agregarMontoDepartamento"
              v-model="validFormMontosDepartamento"
            >
              <v-row>
                <!--  <v-col cols="12">
                              
                            </v-col>
 -->

                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    @change="
                      obtenerMunicipiosPorDepartamento(
                        departamentoSeleccionadoMonto.id,
                        1
                      )
                    "
                    v-model="departamentoSeleccionadoMonto"
                    :items="departamentos"
                    :loading="departamentosLoading"
                    dense
                    class="required"
                    filled
                    label="Departamento"
                    item-text="departamento"
                    item-value="id"
                    :no-data-text="
                      departamentos != null
                        ? 'Seleccione un departamento'
                        : 'No se han encontrado departamentos'
                    "
                    :rules="[selectRequired('departamento')]"
                    return-object
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    v-model="municipioSeleccionadoMonto"
                    :items="municipiosMonto"
                    :loading="municipiosMontoLoading"
                    dense
                    filled
                    label="Municipio"
                    item-text="municipio"
                    item-value="id"
                    :no-data-text="
                      departamentos != null
                        ? 'Seleccione un municipio'
                        : 'No se han encontrado municipios'
                    "
                    @change="
                      setLatLong(
                        municipioSeleccionadoMonto.latitud,
                        municipioSeleccionadoMonto.longitud
                      )
                    "
                    return-object
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class=""
                    color="blue-grey lighten-2"
                    v-model="montoLatitud"
                    label="Latitud"
                    :rules="[maxLength('longitud', 25), latitude('latitud')]"
                    maxlength="25"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class=""
                    color="blue-grey lighten-2"
                    v-model="montoLongitud"
                    label="Longitud"
                    :rules="[maxLength('longitud', 25), longitude('longitud')]"
                    maxlength="25"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosMontoSuscritoDepartamento.monto"
                    label="Monto en quetzales"
                    :rules="[
                      notComma('monto departamento'),
                      required('monto departamento'),
                      decimals10('monto departamento'),
                    ]"
                    maxlength="250"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-left"
                    @click="dialogObtenerUbicacion = true"
                  >
                    <v-icon left>mdi-map</v-icon>
                    Seleccionar ubicación
                  </v-btn>

                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    :elevation="0"
                    :disabled="
                      !validFormMontosDepartamento ||
                        Object.keys(departamentoSeleccionadoMonto).length ===
                          0 ||
                        (tipoUsuario == `ext` && seccionesBloqueadas)
                    "
                    :loading="btnMontosDeptoLoading"
                  >
                    {{
                      accionMontoTerritorio === 1
                        ? `Agregar`
                        : `Actualizar monto`
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <!--inicio:: tabla detalle de monto suscrito por departamento -->
          <v-col cols="12" md="12" sm="12" class="pt-4 mt-4">
            <v-data-table
              dense
              class="elevation-1"
              :headers="headersMontoDepartamento"
              :items="montosDepartamento"
              :loading="tableLoadingMontosDepto"
              :header-props="{
                sortByText: 'Ordenar por',
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}',
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.municipio ? item.municipio + `,` : `` }}
                    {{ item.departamento }}
                  </td>

                  <td>
                    {{
                      `Latidud: ` +
                        item.latitud +
                        ` - LONGITUD: ` +
                        item.longitud
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      parseFloat(item.monto).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      })
                    }}
                  </td>
                  <!--<td>{{ item.tipoCambio }}</td>
                                        <td>{{ item.montoTotalQuetzales }}</td>-->

                  <td>
                    <v-btn
                      v-if="item.latitud && item.longitud"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarMontoDepartamentoDisabled"
                      color="blue-grey lighten-5"
                      @click="mostrarMapa(item.latitud, item.longitud, 1)"
                    >
                      <v-icon left>mdi-map</v-icon> Ver mapa
                    </v-btn>

                    <v-btn
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarMontoDepartamentoDisabled"
                      color="blue-grey lighten-5"
                      @click="obtenerDatoMontoTerritorial(item.id)"
                    >
                      <v-icon left>mdi-pencil</v-icon> Editar
                    </v-btn>

                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarMontoDepartamentoDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarMontoDepartamento(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de montos por departamento
                </p>
              </template>
            </v-data-table>

            <v-row>
              <v-col cols="12" md="12">
                <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  :disabled="montosDepartamento.lengt <= 0"
                  color="blue-grey lighten-5"
                  @click="mostrarMapa(0, 0, 2)"
                >
                  <v-icon left>mdi-map</v-icon> Ver mapa
                </v-btn>
              </v-col>
            </v-row>

            <template v-slot:no-data>
              <p class="text-h7">
                <v-icon left>mdi-alert</v-icon> No existe información registrada
                de montos suscritos por departamento
              </p>
            </template>
          </v-col>
          <!--fin:: tabla detalle de monto suscrito por departamento -->

          <!-- inicio: total de la secion -->
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto total</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6"
                  >{{
                    parseFloat(
                      totalMontoSuscritoDepartamento
                    ).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ",
                      maximumFractionDigits: maxDigits,
                    })
                  }}/
                  {{
                    parseFloat(totalDatosFinancierosFC).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      }
                    )
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!--fin:: total de la seccion -->
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin:: seccion detalle de monto suscrito por departamento-->

    <!-- inicio:: seccion proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->
    <v-card class="mt-6" outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="card-label text-h6 font-weight-bolder text-dark">
              Distribución del monto a ejecutar
            </span>

            <v-btn
              class="float-right mx-2"
              fab
              dark
              small
              color="light-blue-502"
              @click.stop="dialogAyuda = true"
            >
              <v-icon dark>mdi-help-circle-outline</v-icon>
            </v-btn>

            <!--<span class="text-muted mt-3 font-weight-bold font-size-md">
                            (Componente, objetivo, indicador, resultado, meta)
                        </span>-->
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-divider class="mt-0 pt-3 pb-2"></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <!--<v-subheader class="text-h5 black--text">
                            
                        </v-subheader>-->
            <v-alert
              text
              :value="datosFinancierosProyecto.length <= 0"
              type="error"
              color="orange darken-1"
              icon="mdi-alert"
            >
              Para poder agregar la distribución del monto a ejecutar, debe
              registrar datos financieros del proyecto!
            </v-alert>
            <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
          </v-col>
        </v-row>

        <v-form
          ref="formProyeccionDesembolsos"
          v-on:submit.prevent="agregarProyeccionSubDivision"
          v-model="validFormProyeccionSubDivision"
        >
          <v-row class="mt-">
            <v-col cols="12" md="12" sm="12" class="pb-0">
              <v-subheader class="text-h6 black--text pb-0">
                Subdivisión del monto suscrito de la fuente cooperante
              </v-subheader>
            </v-col>
          </v-row>

          <v-row
            v-if="tipoUsuario == `int` && referenciasSubdivisionesFC.length > 0"
          >
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="referenciaSubdivsionesFCSeleccionada"
                :items="referenciasSubdivisionesFC"
                :loading="false"
                dense
                filled
                label="Referencia SIGEACI"
                :item-text="
                  (item) =>
                    `${item.tipoSubdivision} - ${item.descripcionSubdivision}`
                "
                item-value="id"
                return-object
                :no-data-text="
                  referenciasSubdivisionesFC != null
                    ? 'Selecciona una referencia'
                    : 'No se han encontrado referencias para este proyecto'
                "
                @change="agregarReferenciaFC"
                menu-props="auto"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosEjecucion.actividadesEjecucionId"
                :items="subDivisiones"
                :loading="false"
                dense
                filled
                label="Subdivisión"
                item-text="nombreActividad"
                item-value="id"
                :rules="[selectRequired('subdivisión')]"
                :no-data-text="
                  subDivisiones != null
                    ? 'Selecciona una subdivisión'
                    : 'No se han encontrado subdivisiones'
                "
                menu-props="auto"
              ></v-select>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-textarea
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="datosEjecucion.descripcion"
                label="Descripción"
                :rules="[
                  required('descripción de la subdivisión'),
                  maxLength('descripción de la subdivisión', 2500),
                ]"
                maxlength="2500"
                rows="3"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
              <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                hint=""
                suffix="GTQ"
                v-model="datosEjecucion.aporteFuenteCooperante"
                label="Aporte Fuente Cooperante"
                :rules="[
                  notComma('aporte fuente cooperante'),
                  required('aporte de la fuente cooperante'),
                  decimals10('aporte de la fuente cooperante'),
                  maxLength('aporte de la fuente cooperante', 30),
                ]"
                maxlength="30"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="aportaContrapartida"
                :items="aportaContrapartidaItems"
                :loading="false"
                dense
                :disabled="
                  !contraPartidaRegistrada ||
                    accionSubdivicionFC == 2 ||
                    Object.keys(referenciaSubdivsionesFCSeleccionada).length > 0"
                filled
                label="¿Presenta aporte a contrapartida?"
                item-text="text"
                item-value="id"
                :no-data-text="
                  aportaContrapartidaItems.length > 0
                    ? 'Selecciona una opción'
                    : 'No se han encontrado opciones'
                "
                menu-props="auto"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="4"
              sm="6"
              class="pt-0 pb-0"
              v-if="aportaContrapartida === 1"
            >
              <v-text-field
                dense
                filled
                autocomplete="off"
                color="blue-grey lighten-2"
                v-model="datosEjecucionContraPartidaCooperante.monto"
                suffix="GTQ"
                label="Aporte Contrapartida Quetzales"
                :rules="[
                  aportaContrapartida === 1
                    ? decimals10('aporte de la contrapartida')
                    : true,
                  /*aportaContrapartida===1 ? maxNumber('aporte de la contrapartida', parseFloat(totalDatosFinancierosCP.toFixed(10) - totalSubDivisionContrapartida.toFixed(10))) : true,*/
                  aportaContrapartida === 1
                    ? required('aporte de la contrapartida')
                    : true,
                  aportaContrapartida === 1
                    ? maxLength('aporte de la contrapartida', 26)
                    : true,
                ]"
                hint=""
                maxlength="20"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-spacer></v-spacer>

              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right"
                type="submit"
                :elevation="0"
                :disabled="
                  !validFormProyeccionSubDivision ||
                    datosFinancierosProyecto.length <= 0 ||
                    (tipoUsuario == `ext` && seccionesBloqueadas)
                "
                :loading="btnRegistroEjecucionActividadLoading"
              >
                {{
                  accionSubdivicionFC === 1
                    ? `Agregar distribución`
                    : `Actualizar distribución`
                }}
              </v-btn>

              <v-btn
                color="grey lighten-5"
                elevation="0"
                class="mb-2 float-right grey lighten-5 mr-2"
                @click="resetItemsSubdivision"
                :disabled="btnRegistroEjecucionActividadLoading"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <!--  <v-row class="mt-">
                    
                    <v-col cols="12" md="12" sm="12" class="pb-0">
                        <v-subheader class="text-h6 black--text pb-0">
                            Monto suscrito de la fuente cooperante
                        </v-subheader>
                    </v-col>
                </v-row> -->

        <v-row>
          <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->
          <v-col cols="12" md="12" sm="12" class="pt-4">
            <v-data-table
              class="elevation-1"
              dense
              :headers="headersSubDivisionesCooperante"
              :items="proyeccionesSubDivision"
              :loading="tableLoadingSubdivisionCooperante"
              :header-props="{
                sortByText: 'Ordenar por',
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}',
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.nombreActividad }}</td>
                  <td>{{ item.descripcion }}</td>
                  <td class="text-right">
                    {{
                      parseFloat(
                        item.aporteFuenteCooperante
                      ).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      })
                    }}
                  </td>

                  <td>
                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      :disabled="bntEliminarDistribucionCooperanteDisabled"
                      @click="cargarFormSubdivisionFC(item)"
                    >
                      <v-icon left>mdi-square-edit-outline</v-icon> Editar
                    </v-btn>

                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      :disabled="bntEliminarDistribucionCooperanteDisabled"
                      @click="eliminarDistribucionCooperante(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de proyección de fuente cooperante
                </p>
              </template>
            </v-data-table>
          </v-col>
          <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->

          <!-- inicio: total de la secion -->
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Total Monto Fuente Cooperante</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6"
                  >{{
                    parseFloat(
                      totalSubDivisionCooperante
                    ).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ",
                      maximumFractionDigits: maxDigits,
                    })
                  }}
                  /
                  {{
                    parseFloat(totalDatosFinancierosFC).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      }
                    )
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col 
            v-if="
              parseFloat(totalSubDivisionCooperante) !=
                parseFloat(totalDatosFinancierosFC)
            "
            cols="12"
            md="12"
            sm="12"
            class="pt-0 pb-0"
          >
            <v-alert text type="error" color="orange darken-1" icon="mdi-alert">
              Las cantidad del monto suscrito de la(s) subdivisión(es)
              registrada(s)
              {{
                parseFloat(totalSubDivisionCooperante).toLocaleString("es-GT", {
                  style: "currency",
                  currency: "GTQ",
                  maximumFractionDigits: maxDigits,
                })
              }}
               no coincide con el monto suscrito de la fuente cooperante  
              {{
                parseFloat(totalDatosFinancierosFC).toLocaleString("es-GT", {
                  style: "currency",
                  currency: "GTQ",
                  maximumFractionDigits: maxDigits,
                })
              }}
            </v-alert>
            <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
          </v-col>
          <!--fin:: total de la seccion -->
        </v-row>

        <v-row v-if="contraPartidaRegistrada">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="pb-0">
            <v-subheader class="text-h6 black--text pb-0">
              Subdivisión del monto suscrito de la contrapartida / otros aportes
            </v-subheader>
          </v-col>

          <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
            <v-form
              ref="formProyeccionDesembolsos"
              v-on:submit.prevent="agregarProyeccionSubDivisionContrapartida(0)"
              v-model="validFormProyeccionSubDivisionContraPartida"
            >
              <v-row
                v-if="
                  tipoUsuario == `int` && referenciasSubdivisionesCP.length > 0
                "
              >
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-select
                    v-model="referenciaSubdivsionesCPSeleccionada"
                    :items="referenciasSubdivisionesCP"
                    :loading="false"
                    dense
                    filled
                    label="Referencia SIGEACI"
                    :item-text="
                      (item) =>
                        `${item.tipoSubdivision} - ${item.descripcionSubdivision}`
                    "
                    item-value="id"
                    return-object
                    :no-data-text="
                      referenciasSubdivisionesCP != null
                        ? 'Selecciona una referencia'
                        : 'No se han encontrado referencias para este proyecto'
                    "
                    @change="agregarReferenciaCP"
                    menu-props="auto"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                  <v-select
                    v-model="datosEjecucionContraPartida.actividadesEjecucionId"
                    :items="subDivisiones"
                    :loading="false"
                    dense
                    filled
                    label="Subdivisión"
                    item-text="nombreActividad"
                    item-value="id"
                    :rules="[selectRequired('subdivisión')]"
                    :no-data-text="
                      subDivisiones != null
                        ? 'Selecciona una subdivisión'
                        : 'No se han encontrado subdivisiones'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-textarea
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosEjecucionContraPartida.descripcion"
                    label="Descripción"
                    :rules="[
                      required('descripción de la proyección'),
                      maxLength('descripción de la subdivisión', 2500),
                    ]"
                    maxlength="2000"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    suffix="GTQ"
                    v-model="datosEjecucionContraPartida.monto"
                    label="Aporte contrapartida"
                    :rules="[
                      notComma('aporte contrapartida'),
                      required('aporte contrapartida'),
                      decimals10('aporte contrapartida'),
                      maxLength('aporte contrapartida', 30),
                    ]"
                    hint=""
                    maxlength="30"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-spacer></v-spacer>

                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    :elevation="0"
                    :disabled="
                      !validFormProyeccionSubDivisionContraPartida ||
                        datosFinancierosProyecto.length <= 0 ||
                        (tipoUsuario == `ext` && seccionesBloqueadas)
                    "
                    :loading="btnRegistroEjecucionActividadContraPartidaLoading"
                  >
                    {{
                      accionSubdivicionCP === 1
                        ? `Agregar distribución`
                        : `Actualizar distribución`
                    }}
                  </v-btn>

                  <v-btn
                    color="grey lighten-5"
                    elevation="0"
                    class="mb-2 float-right grey lighten-5 mr-2"
                    @click="resetItemsEjecucionContrapartida"
                    :disabled="
                      btnRegistroEjecucionActividadContraPartidaLoading
                    "
                  >
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <!--inicio:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA-->
          <v-col cols="12" md="12" sm="12" class="pt-4">
            <v-data-table
              dense
              class="elevation-1"
              :headers="headersSubDivisionesContrapartida"
              :items="proyeccionesSubDivisionContrapartida"
              :loading="tableLoadingSubdivisionContrapartida"
              :header-props="{
                sortByText: 'Ordenar por'
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.nombreActividad }}</td>
                  <td>{{ item.descripcion }}</td>
                  <td class="text-right">
                    {{
                      parseFloat(item.monto).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      })
                    }}
                  </td>
                  <td>
                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      color="blue-grey lighten-5"
                      :disabled="bntEliminarDistribucionCooperanteDisabled"
                      @click="cargarFormSubdivisionCP(item)"
                    >
                      <v-icon left>mdi-square-edit-outline</v-icon> Editar
                    </v-btn>

                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarDistribucionContrapartidaDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarDistribucionContrapartida(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de proyección de contrapartida
                </p>
              </template>
            </v-data-table>
          </v-col>
          <!--fin:: tabla proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta CONTRAPARTIDA -->

          <!-- inicio: total de la secion -->
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Total Monto Nacional + Otros Aportes</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6"
                  >{{
                    parseFloat(
                      totalSubDivisionContrapartida
                    ).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ",
                      maximumFractionDigits: maxDigits,
                    })
                  }}
                  /
                  {{
                    parseFloat(totalDatosFinancierosCP).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits,
                      }
                    )
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col 
            v-if="
              parseFloat(totalSubDivisionCooperante) !=
                parseFloat(totalDatosFinancierosFC)
            "
            cols="12"
            md="12"
            sm="12"
            class="pt-0 pb-0"
          >
            <v-alert text type="error" color="orange darken-1" icon="mdi-alert">
              Las cantidad del monto suscrito de la(s) subdivisión(es)
              registrada(s)
              {{
                parseFloat(totalSubDivisionContrapartida).toLocaleString("es-GT", {
                  style: "currency",
                  currency: "GTQ",
                  maximumFractionDigits: maxDigits,
                })
              }}
               no coincide con el monto suscrito de la contrapartida  
              {{
                parseFloat(totalDatosFinancierosCP).toLocaleString("es-GT", {
                  style: "currency",
                  currency: "GTQ",
                  maximumFractionDigits: maxDigits,
                })
              }}
            </v-alert>
            <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
          </v-col>
          <!--fin:: total de la seccion -->
          <!-- inicio: total de la secion 
                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Total Desembolsos</v-list-item-title>
                                <v-list-item-subtitle class="text-h6">{{parseFloat(totalSubDivisiones).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ',  maximumFractionDigits: maxDigits })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    fin:: total de la seccion -->
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin:: seccion proyeccion de ejecucion por componente/objetivo/indicador/resultado/meta -->

    <!-- inicio:: seccion detalle de monto suscrito por subdivisión-->
    <v-card class="mt-6" outlined v-if="habilitarMontoTerritorial">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              <!--Detalle del monto suscrito por subdivisión fuente cooperante-->
              Detalle del monto suscrito por subdivisión fuente cooperante en
              territorio
            </v-subheader>
          </v-col>
        </v-row>

        <v-row>
          <!-- v-if="habilitarMontoTerritorial" -->
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <!--<v-subheader class="text-h5 black--text">
                            
                        </v-subheader>-->
            <v-alert
              text
              :value="datosFinancierosProyecto.length <= 0"
              type="error"
              color="orange darken-1"
              icon="mdi-alert"
            >
              Para poder agregar el detalle del monto suscrito por actividad,
              debe registrar la subdivisión del monto suscrito de la fuente
              cooperante
            </v-alert>
            <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
          </v-col>
        </v-row>

        <!-- <v-form 
                    ref="formDetalleNivelTerritorial"
                    v-on:submit.prevent="actualizarCuentaMontoTerritorial"
                    v-model="validFormCuentaMontoTerritoral"
                    >

                    <v-row class="mt-4">

                        <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0">
                            <v-select
                                v-model="cuentaMontoSuscritoTerritoralSeleccionado"
                                    :items="montoSuscritoTerritorioItems"
                                    :loading="false"
                                    dense
                                    filled
                                    class="required"
                                    return-object   
                                    label="¿Cuenta con información del monto suscrito a nivel territorial?"
                                    item-text="text"
                                    item-value="id"
                                    menu-props="auto"
                                    :disabled="cuentaMonoSuscritoTerritorialLoading"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                                
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="cuentaMonoSuscritoTerritorialLoading"
                                :loading="cuentaMonoSuscritoTerritorialLoading"
                            >
                                Actualizar
                            </v-btn>
                        </v-col>


                    </v-row>

                </v-form> -->

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-">
            <v-divider class="mt-0 pt-1 pb-1"></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <!-- v-if="habilitarMontoTerritorial"-->

          <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
            <v-form
              ref="formProyeccionDesembolsos"
              v-on:submit.prevent="agregarMontoSubdivisionDeptoMuni"
              v-model="validFormMontoSubdivision"
            >
              <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-select
                    v-model="ejecucionActividadTerritorioSeleccionada"
                    :items="proyeccionesSubDivision"
                    :loading="false"
                    dense
                    filled
                    return-object
                    label="Subdivisión"
                    :item-text="
                      (item) =>
                        item.descripcion +
                        ` | ` +
                        item.nombreActividad +
                        ` | GTQ ` +
                        item.aporteFuenteCooperante
                    "
                    item-value="id"
                    :no-data-text="
                      subDivisiones != null
                        ? 'Selecciona una subdivisión'
                        : 'No se han encontrado subdivisiones'
                    "
                    menu-props="auto"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    v-model="datosSubdivisionTerritorio.departamentosId"
                    @change="
                      obtenerMunicipiosPorDepartamento(
                        datosSubdivisionTerritorio.departamentosId,
                        2
                      )
                    "
                    class="required"
                    :items="departamentos"
                    :loading="departamentosLoading"
                    dense
                    filled
                    label="Departamento"
                    item-text="departamento"
                    item-value="id"
                    :no-data-text="
                      departamentos > 0
                        ? 'Selecciona un departamento'
                        : 'No se han encontrado departamentos'
                    "
                    :rules="[selectRequired('departamento')]"
                    menu-props="auto"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    v-model="datosSubdivisionTerritorio.municipiosId"
                    :items="municipios"
                    :loading="ddMunicipiosLoading"
                    :clearable="true"
                    dense
                    class=""
                    filled
                    label="Municipio"
                    item-text="municipio"
                    item-value="id"
                    :no-data-text="
                      municipios > 0
                        ? 'Seleccione un municipio'
                        : 'No se han encontrado municipios'
                    "
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                  <v-text-field
                    dense
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosSubdivisionTerritorio.montoQuetzales"
                    label="Monto en quetzales"
                    suffix="GTQ"
                    :rules="[
                      notComma('monto quetzales'),
                      required('monto quetzales'),
                      decimals10('monto quetzales'),
                    ]"
                    hint=""
                    maxlength="250"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right"
                    type="submit"
                    :elevation="0"
                    :disabled="
                      !validFormMontoSubdivision ||
                        (tipoUsuario == `ext` && seccionesBloqueadas)
                    "
                    :loading="btnMontoSubdivisionLoading"
                  >
                    Agregar monto
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col cols="12" md="12" sm="12" class="pb-0  mt-4">
            <v-subheader class="text-subtitle-1 black--text pb-0">
              Subdivisión por departamento/municipio registrada
            </v-subheader>
          </v-col>

          <!--inicio:: tabla detalle de monto suscrito por departamento -->
          <v-col cols="12" md="12" sm="12" class="pt-4">
            <v-data-table
              dense
              class="elevation-1"
              :headers="headersEjecucionSubdivisionTerritorio"
              :items="ejecucionesActividadTerritorio"
              :loading="tableLoadingEjecucionesTerritorio"
              :header-props="{
                sortByText: 'Ordenar por'
              }"
              no-data-text="No se encontraron registros"
              loading-text="Cargando..."
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.descripcion + ` | ` + item.nombreActividad }}</td>
                  <td>
                    {{ item.municipio ? item.municipio + `, ` : ``
                    }}{{ item.departamento }}
                  </td>
                  <td class="text-right">
                    {{
                      parseFloat(item.montoQuetzales).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                        maximumFractionDigits: maxDigits
                      })
                    }}
                  </td>
                  <!--<td>{{ item.tipoCambio }}</td>
                                        <td>{{ item.montoTotalQuetzales }}</td>-->

                  <td>
                    <v-btn
                      v-if="
                        (tipoUsuario == `ext` && !seccionesBloqueadas) ||
                          tipoUsuario == `int`
                      "
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarSubdivisionTerritorioDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarSubdivisionTerritorio(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de montos por departamento
                </p>
              </template>
            </v-data-table>

            <template v-slot:no-data>
              <p class="text-h7">
                <v-icon left>mdi-alert</v-icon> No existe información registrada
                de montos suscritos por departamento
              </p>
            </template>
          </v-col>
          <!--fin:: tabla detalle de monto suscrito por departamento -->

          <!-- inicio: total de la secion -->
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto total</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6"
                  >{{
                    parseFloat(
                      totalEjecucionActividadTerritorio
                    ).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ",
                      maximumFractionDigits: maxDigits,
                    })
                  }}/
                  {{
                    parseFloat(
                      totalSubDivisionCooperante
                    ).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ",
                      maximumFractionDigits: maxDigits,
                    })
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!--fin:: total de la seccion -->
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin:: seccion detalle de monto suscrito por subdivision-->

    <v-dialog
      v-model="dialogAyuda"
      max-width="800"
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Ayuda
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogAyuda = false" class="float-right">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p class="text-h6 text--primary">
            Los proyectos se pueden describir de las siguientes maneras:
          </p>

          <v-list>
            <v-list-item v-for="division in subDivisiones" :key="division.id">
              <v-list-item-content>
                <v-list-item-title class="text-h5 black--text">
                  {{ division.nombreActividad }}
                </v-list-item-title>
                <v-list-item-subtitle
                  style="white-space: normal;"
                  class="text-h6"
                  >{{ division.descripcion }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <!--  <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title> 2.	Actividad: </v-list-item-title>
                                <v-list-item-subtitle style="white-space: normal;" class="text-h6">Aplica si el proyecto tiene una acción operacional con relación a la entrega de uno o varios insumos. </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->

            <v-divider></v-divider>

            <!--  <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title> 3.	Otros: </v-list-item-title>
                                <v-list-item-subtitle style="white-space: normal;" class="text-h6">Proyectos que no se identifican dentro de la subdivisión de componente o actividad.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light-blue-502" text @click="dialogAyuda = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--dialog mapa ubicaciones-->
    <v-dialog v-model="dialogUbicaciones" transition="scroll-y-transition">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="false"
            @click="dialogUbicaciones = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="letter-spacing: 0.1px !important">
          <GmapMap
            :center="{ lat: 16.062775, lng: -89.3520961 }"
            :zoom="8"
            ref="map"
            id="map"
            style="width: 100%; height: 800px"
          >
            <GmapMarker
              v-for="(m, index) in marcadores"
              :key="index"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="
                center = m.position;
                openGmapInfo(index);
              "
            >
              <!--  <gmap-info-window
                    :position="infoWindow.position"
                    :opened="infoWindow.open[index]"
                    @closeclick="infoWindow.open[index] = false"
                >
                    <div v-html="infoWindow.template"></div>
                </gmap-info-window> -->
            </GmapMarker>
          </GmapMap>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialog mapa-->

    <!--dialog mapa para obtener ubicación-->
    <v-dialog v-model="dialogObtenerUbicacion" transition="scroll-y-transition">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="false"
            @click="dialogObtenerUbicacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="letter-spacing: 0.1px !important">
          <GmapMap
            :center="{ lat: 16.062775, lng: -89.3520961 }"
            :zoom="8"
            ref="map"
            id="map"
            style="width: 100%; height: 800px"
            @click="handleMapClick"
          >
            <GmapMarker
              :position="marker.position"
              :clickable="true"
              :draggable="true"
              @drag="handleMarkerDrag"
              @click="panToMarker"
            >
              <!--  <gmap-info-window
                    :position="infoWindow.position"
                    :opened="infoWindow.open[index]"
                    @closeclick="infoWindow.open[index] = false"
                >
                    <div v-html="infoWindow.template"></div>
                </gmap-info-window> -->
            </GmapMarker>
          </GmapMap>
          <v-row class="mt-2">
            <v-col cols="12" md="6" xs="6">
              <p class="text-h6 black--text  pb-0 mb-0">Latitud:</p>
              <p class="text-h5">{{ montoLatitud }}</p>
            </v-col>

            <v-col cols="12" md="6" xs="6">
              <p class="text-h6 black--text  pb-0 mb-0">Longitud:</p>
              <p class="text-h5">{{ montoLongitud }}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialog mapa-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>
<script>
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";
import {
  OBTENER_DATOS_FINANCIEROS_CNS,
  REGISTRAR_DATO_FINANCIERO_CNS,
  ELIMINAR_DATO_FINANCIERO_CNS,
  OBTENER_DISTRIBUCIONES_FUENTE_COOPERANTE_CNS,
  REGISTRAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS,
  ELIMINAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS,
  OBTENER_PROYECCIONES_DESEMBOLSOS_CNS,
  REGISTRAR_PROYECCION_DESEMBOLSOS_CNS,
  ELIMINAR_PROYECCION_DESEMBOLSO_CNS,
  OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS,
  REGISTRAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS,
  ELIMINAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS,
  OBTENER_MONTO_SUSCRITO_DEPARTAMENTO_CNS,
  ACTUALIZAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_CNS,
  REGISTRAR_EJECUCION_ACTIVIDAD_CNS,
  ACTUALIZAR_EJECUCION_ACTIVIDAD_CNS,
  ELIMINAR_EJECUCION_ACTIVIDAD_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS,
  REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS,
  ACTUALIZAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS,
  ELIMINAR_EJECUCION_CONTRAPARTIDA_CNS,
  OBTENER_EJECUCIONES_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS,
  REGISTRAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS,
  ELIMINAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS,
} from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

import {
  OBTENER_MECANISMOS_EJECUCION_CNS,
  REGISTRAR_MECANISMO_EJECUCION_CNS,
  ELIMINAR_MECANISMO_EJECUCION_CNS,
} from "@/core/services/store/proyectoscns/mecanismosejecucion/mecanismoejecucioncns.module";
import {
  OBTENER_DATOS_SECCIONES_PROYECTO,
  ACTUALIZAR_CUENTA_CONDICIONES_DESEMOLSOS,
  ACTUALIZAR_CUENTA_MONTO_TERRITORIO,
  ACTUALIZAR_FORMA_OPERAR,
  ACTUALIZAR_DATOS_INGRESA_PRESUPUESTO_CNS,
} from "@/core/services/store/proyectoscns/proyectocns.module";

export default {
  name: "SeccionDatosFinancierosProyecto",
  props: ["id", "tipoUsuario", "codSigeaci", "confirmacion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  data() {
    return {
      maxDigits: 2,
      dialogAyuda: false,
      dialogUbicaciones: false,
      seccionesBloqueadas: false,
      validForm: false,
      validFormMecanismo: false,
      validFormDatosFinancieros: false,
      validFormDistribucion: false,
      validFormCuentaDesembolsos: false,
      validFormCuentaMontoTerritoral: false,
      validFormProyeccionDesembolsos: false,
      validFormFormaOperar: false,
      btnMecanismoLoading: false,
      btnRegistroFormaOperarLoading: false,
      validFormMontosDepartamento: false,
      validFormProyeccionSubDivision: false,
      validFormProyeccionSubDivisionContraPartida: false,
      btnEliminarDisabled: false,
      btnEliminarProyeccionDesembolsoDisabled: false,
      btnEliminarMontoDepartamentoDisabled: false,
      btnEliminarDistribucionFuenteDisabled: false,
      btnRegistroEjecucionActividadContraPartidaLoading: false,
      bntEliminarDistribucionCooperanteDisabled: false,
      btnEliminarDistribucionContrapartidaDisabled: false,
      ddTiposDatosFinancierosLoading: false,
      ddTiposDatosFinancierosDistLoading: false,
      ddMonedasLoading: false,
      departamentosLoading: false,

      tableDatosFinancierosLoading: false,
      datosFinancierosProyecto: [],
      proyeccionesDesembolsosProyecto: [],
      btnDatosFinancierosLoading: false,
      btnRegistroProyeccionLoading: false,
      btnDistribucionLoading: false,
      btnProyeccionLoading: false,
      tableLoadingDistribucion: false,
      tableLoadingProyecciones: false,
      tableLoadingMontosDepto: false,

      tableLoadingSubdivisionCooperante: false,
      tableLoadingSubdivisionContrapartida: false,
      mecanismosEjecucion: [],
      datoFinanciero: {},
      moneda: {},
      montoTotalQuetzales: "",
      totalDatosFinancieros: "0.00",
      totalDatosFinancierosFC: 0.0,
      totalDatosFinancierosCP: 0.0,
      totalDatosFinancierosOtros: 0.0,
      totalDistribucionMontos: 0.0,

      tiposDatosFinancieros: [],
      tiposDatosFinancierosDist: [],

      mecanismosRegistrados: [],
      tableMecanismosLoading: false,
      btnEliminarMecanismoDisabled: false,

      datosProyecto: [],
      tiposDatosFinancierosDistribucion: [],

      periodos: [],
      ddPeriodosLoading: false,
      monedas: [],
      departamentos: [],
      subDivisiones: [],
      datoFinancieroDistribucion: [],
      montoMonedaOriginalDistribucion: 0,
      referenciaSubdivsionesFCSeleccionada: {},
      referenciasSubdivisionesFC: [],
      referenciaSubdivsionesCPSeleccionada: {},
      referenciasSubdivisionesCP: [],

      datoFinancieroProyeccion: "",
      anio: "",
      montoMonedaOriginalProyeccion: "",
      detalleFormaOperar: "",
      totalProyeccionDesembolsos: "0.00",
      totalProyeccionDesembolsosContraPartida: "0.00",

      montoMonedaOriginal: "",
      monedaOriginalDistribuicion: "",
      tipoCambio: "",

      departamento: {},
      montoDepartamento: "",
      totalMontoSuscritoDepartamento: "0.00",

      subDivision: {},
      descripcionSubdivision: "",
      aporteFuenteCooperante: "",
      aporteContrapartida: "",
      totalSubDivisiones: "0.00",
      totalSubDivisionCooperante: 0.0,
      totalSubDivisionContrapartida: 0.0,
      datosEjecucionContraPartidaCooperante: {
        monto: "",
      },

      datosItem: {
        mecanismosEjecucionId: 0,
      },
      monedaSeleccionada: {},
      datosFinancieros: {
        id: 0,
        tiposDatosFinancierosId: 0,
        proyectosCNSId: 0,
        monedasId: 0,
        estadosId: 1,
        montoMonedaOriginal: 0,
        tipoCambio: 0,
        montoTotalQuetzales: 0,
        usuarioCreacion: "admin",
      },

      datosDistribucion: {
        id: 0,
        proyectosCNSId: this.id,
        tiposDatosFinancierosDistId: 0,
        tipoCambio: 0,
        montoMonedaOriginal: 0,
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      cuentaCondicionDesembolsoSeleccionada: {},
      condicionesDesembolsosItems: [
        { id: 2, text: "No" },
        { id: 1, text: "Si" },
      ],
      habilitarProyeccionDesembolsos: false,
      datosCuentaDesembolsos: {
        proyectosCNSId: this.id,
        proyectaDesembolsos: 0,
      },
      cuentaDesembolsosLoading: false,
      tiposDesembolso: [],
      ddTiposDesembolsoLoading: false,
      datosFormaOperar: {
        detalleFormaOperar: "",
        proyectosCNSId: this.id,
        usuarioActualizacion: "admin",
      },
      btnRegistroMecanismoLoading: false,
      datosMecanismoEjecucion: {
        proyectosCNSId: this.id,
        estadosId: 1,
        usuarioCreacion: "admin",
        mecanismosEjecucionId: 0,
      },
      datosProyeccionDesembolsos: {
        id: 0,
        proyectosCNSId: 0,
        periodosId: 0,
        tiposDesembolsoId: 0,
        montoOriginal: 0,
        detalleFormaOperar: "",
        estadosId: 1,
        usuarioCreacion: "",
      },

      btnMontosDeptoLoading: false,
      cuentaMontoSuscritoTerritoralSeleccionado: {},
      cuentaMonoSuscritoTerritorialLoading: false,
      montoSuscritoTerritorioItems: [
        { id: 2, text: "No" },
        { id: 1, text: "Si" },
      ],
      habilitarMontoTerritorial: false,
      montoLatitud: "",
      montoLongitud: "",
      municipiosMonto: [],
      municipiosMontoLoading: false,
      datosMontoSuscritoDepartamento: {
        id: 0,
        proyectosCNSId: this.id,
        departamentosId: 0,
        municipiosId: 0,
        latitud: 0,
        longitud: 0,
        monto: "",
        codigoMoneda: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      datosCuentaMontoTerritorio: {
        proyectosCNSId: this.id,
        detalleMontoPorDepartamento: 0,
      },

      aportaContrapartida: 2,
      aportaContrapartidaItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" },
      ],

      btnRegistroEjecucionActividadLoading: false,

      datosEjecucion: {
        proyectosCNSId: this.id,
        actividadesEjecucionId: 0,
        descripcion: "",
        aporteFuenteCooperante: "",
        contraPartida: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      datosEjecucionContraPartida: {
        proyectosCNSId: this.id,
        actividadesEjecucionId: 0,
        descripcion: "",
        monto: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      tableLoadingEjecucionesActividad: false,
      distribucionesMontoSuscrito: [],
      proyeccionesDesembolsos: [],
      montosDepartamento: [],
      detalleMontosSuscritosDepto: [],
      proyeccionesSubDivision: [],
      proyeccionesSubDivisionContrapartida: [],

      datoFinancieroFCRegistrado: 0,
      datoFinancieroCPRegistrado: 0,
      monedaFuenteCooperante: "",
      monedasIdContrapartida: 0,
      monedasIdFuenteCooperante: 0,
      monedaContraPartida: "",
      alertaTotalDistribucion: false,
      contraPartidaRegistrada: false,
      validFormMontoSubdivision: false,
      btnMontoSubdivisionLoading: false,
      municipios: [],
      ddMunicipiosLoading: false,

      tableLoadingEjecucionesTerritorio: false,
      ejecucionesActividadTerritorio: [],
      btnEliminarSubdivisionTerritorioDisabled: false,
      totalEjecucionActividadTerritorio: 0,
      ejecucionActividadTerritorioSeleccionada: {},
      datosSubdivisionTerritorio: {
        proyectosCNSId: this.id,
        ejecucionesPorActividadCNSId: 0,
        departamentosId: 0,
        municipiosId: 0,
        montoQuetzales: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      },
      marcadores: [],
      infoWindow: {
        open: [],
        position: { lat: 0, lng: 0 },
        template: "",
      },
      departamentoSeleccionadoMonto: {},
      municipioSeleccionadoMonto: {},
      dialogObtenerUbicacion: false,
      accionMontoTerritorio: 1,
      //Datos para seleccionar la ubicación
      marker: { position: { lat: 10, lng: 10 } },
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: true
      },
      montoSuscritoDeptoAnterior: 0,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      alertaDatosFinancierosMonedaCambio: false,
      otrosAportesRegistrados: false,

      btnRegistroDatosPresupuestoLoading: false,
      validFormDatosPresupuesto: false,
      ingresaAPresupuestoItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      datosIngresaPresupuesto: {
        proyectosCNSId: this.id,
        ingresaPresupuesto: 0,
        codigoInstitucion: "",
        codigoFuenteCooperante: "",
        codigoProyecto: ""
      },
      accionSubdivicionFC: 1,
      accionSubdivicionCP: 1,
      montoSubdivisionFCAnterior: 0,
      montoSubdivisionCPAnterior: 0,
      ...validations
    };
  },
  components: {
    SnackAlert,
    DialogLoader,
  },

  methods: {
    resetItems(tipo, proyectoId) {
      this.datoFinancieroFCRegistrado = 0;
      this.datoFinancieroCPRegistrado = 0;
      this.contraPartidaRegistrada = false;
      this.resetItemsDesembolsos();
      this.resetItemsTerritorio();
      this.resetItemsActividadTerritorio();

      if (tipo === 1) {
        this.skeletonLoading = true;
      }
      this.skeletonLoading = true;

      this.datosIngresaPresupuesto = {
        proyectosCNSId: this.id,
        ingresaPresupuesto: 0,
        codigoInstitucion: "",
        codigoFuenteCooperante: "",
        codigoProyecto: ""
      };

      this.datosFormaOperar = {
        detalleFormaOperar: "",
        proyectosCNSId: this.id,
        usuarioActualizacion: "admin"
      };

      this.datosMecanismoEjecucion = {
        proyectosCNSId: this.id,
        usuarioCreacion: "admin",
        estadosId: 1,
        mecanismosEjecucionId: 0
      };

      this.montoMonedaOriginal = "";
      this.tipoCambio = "";

      this.datosFinancieros = {
        id: 0,
        tiposDatosFinancierosId: 0,
        proyectosCNSId: 0,
        monedasId: 0,
        estadosId: 1,
        montoMonedaOriginal: 0,
        tipoCambio: 0,
        montoTotalQuetzales: 0,
        usuarioCreacion: "admin"
      };

      this.monedasIdContrapartida = 0;
      this.monedasIdFuenteCooperante = 0;

      this.datosDistribucion = {
        id: 0,
        proyectosCNSId: this.id,
        tiposDatosFinancierosDistId: 0,
        tipoCambio: 0,
        montoMonedaOriginal: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      };

      this.totalDatosFinancierosFC = 0;

      this.obtenerDatosEspecificosProyecto(proyectoId).then(() => {
        this.obtenerMecanismosEjecucion();
        this.obtenerTiposDatosFinancieros();
        this.obtenerTiposDatosFinancierosDistribucion();
        this.obtenerMonedas();
        this.obtenerDepartamentos();
        this.obtenerSubDivisiones();
        this.obtenerReferenciasSubdivisionFC(this.codSigeaci);
        this.obtenerReferenciasSubdivisionCP(this.codSigeaci);
        this.obtenerMecanismosProyecto(proyectoId);
        this.obtenerDatosFinancieros(proyectoId).then(() => {
          this.obtenerDatosDistribucionFuenteCooperante(proyectoId);
        });
        this.obtenerProyeccionesDesembolsos(proyectoId);
        this.obtenerMontosSuscritosDepto(proyectoId);
        this.obtenerPeriodos();
        this.obtenerTiposDesembolso();
        //this.obtenerDatosDistribucionFuenteCooperante(this.id);
        this.obtenerEjecucionesPorActividad(proyectoId);
        this.obtenerEjecucionesPorActividadContrapartida(proyectoId);
        this.obtenerEjecucionesPorActividadDeptoMuni(proyectoId);
      });
    },

    resetItemsDesembolsos() {
      this.datosProyeccionDesembolsos = {
        id: 0,
        proyectosCNSId: 0,
        periodosId: 0,
        tiposDesembolsoId: 0,
        montoOriginal: 0,
        detalleFormaOperar: "",
        estadosId: 1,
        usuarioCreacion: "",
      };
    },

    resetItemsTerritorio() {
      this.montoSuscritoDeptoAnterior = 0;
      this.montoLatitud = "";
      this.montoLongitud = "";
      this.municipioSeleccionadoMonto = {};
      this.departamentoSeleccionadoMonto = {};
      this.datosMontoSuscritoDepartamento = {
        id: 0,
        proyectosCNSId: this.id,
        departamentosId: 0,
        municipiosId: 0,
        latitud: 0,
        longitud: 0,
        monto: "",
        codigoMoneda: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      };
    },

    resetItemsSubdivision() {
      this.accionSubdivicionFC = 1;
      this.referenciaSubdivsionesFCSeleccionada = {};
      this.montoSubdivisionFCAnterior = 0;

      this.aportaContrapartida = 2;

      this.datosEjecucion = {
        proyectosCNSId: this.id,
        actividadesEjecucionId: 0,
        descripcion: "",
        aporteFuenteCooperante: "",
        contraPartida: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      };
    },

    resetItemsEjecucionContrapartida() {
      this.accionSubdivicionCP = 1;
      this.referenciaSubdivsionesCPSeleccionada = {};
      this.montoSubdivisionCPAnterior = 0;
      this.datosEjecucionContraPartidaCooperante.monto = "";
      //this.datosEjecucion.descripcion = "";
      this.datosEjecucionContraPartida = {
        proyectosCNSId: this.id,
        monto: "",
        estadosId: 1,
        descripcion: "",
        usuarioCreacion: "admin",
      };
    },

    resetItemsActividadTerritorio() {
      this.ejecucionActividadTerritorioSeleccionada = {};
      this.datosSubdivisionTerritorio = {
        proyectosCNSId: this.id,
        ejecucionesPorActividadCNSId: 0,
        departamentosId: 0,
        municipiosId: 0,
        montoQuetzales: "",
        estadosId: 1,
        usuarioCreacion: "admin",
      };
    },

    async obtenerDatosEspecificosProyecto(idProyecto) {
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto,
        })
        .then((res) => {
          if (res.status === 200) {
            this.datosProyecto = res.data;

            //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
            if (this.datosProyecto.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            if (this.datosProyecto.proyectaDesebolsos === 1) {
              this.cuentaCondicionDesembolsoSeleccionada = {
                id: 1,
                text: "Si"
              };
              this.habilitarProyeccionDesembolsos = true;
            } else {
              this.cuentaCondicionDesembolsoSeleccionada = {
                id: 2,
                text: "No"
              };
              this.habilitarProyeccionDesembolsos = false;
            }

            //Habilitar/Deshabilitar proyección de montos a nivel territorial
            if (this.datosProyecto.detalleMontoPorDepartamento === 1) {
              this.cuentaMontoSuscritoTerritoralSeleccionado = {
                id: 1,
                text: "Si"
              };
              this.habilitarMontoTerritorial = true;
            } else {
              this.cuentaMontoSuscritoTerritoralSeleccionado = {
                id: 2,
                text: "No"
              };
              this.habilitarMontoTerritorial = false;
            }

            //console.log(this.datosProyecto)
            if (this.datosProyecto.ingresaAPresupuesto) {
              {
                this.datosIngresaPresupuesto.ingresaPresupuesto = this.datosProyecto.ingresaAPresupuesto;
                this.datosIngresaPresupuesto.codigoProyecto = this.datosProyecto.codigoDelProyecto;
                this.datosIngresaPresupuesto.codigoInstitucion = this.datosProyecto.codigoInstitucion;
                this.datosIngresaPresupuesto.codigoFuenteCooperante = this.datosProyecto.codigoFuenteCooperante;
              }
            }

            this.datosProyecto.detalleFormaOperar
              ? (this.datosFormaOperar.detalleFormaOperar = this.datosProyecto.detalleFormaOperar)
              : (this.datosFormaOperar.detalleFormaOperar = "");
          }
          //this.skeletonAvance[idProyecto] = false;
        })
        .catch(() => {
          //console.log(error)
          //this.skeletonAvance[idProyecto] = false;
          this.dialogLoaderVisible = false;
          this.datosProyecto = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    //Obtener el lsitado de proyeccion de desempbolosos registrados
    async obtenerProyeccionesDesembolsos(proyectoId) {
      this.proyeccionesDesembolsos = [];
      this.tableLoadingProyecciones = true;
      this.totalProyeccionDesembolsos = 0;
      this.totalProyeccionDesembolsosContraPartida = 0;

      await this.$store
        .dispatch(OBTENER_PROYECCIONES_DESEMBOLSOS_CNS, { id: proyectoId })
        .then((res) => {
          if (res.status === 200) {
            this.proyeccionesDesembolsos = res.data;
            // this.totalDatosFinancieros = this.proyeccionesDesembolsos.reduce((a,b) => a + (b['montoOriginal'] || 0), 0);
            //  this.totalProyeccionDesembolsos = this.proyeccionesDesembolsos.reduce((a,b) => parseFloat(a) + (parseFloat(b['montoOriginal'] * b['tipoCambio']) || 0), 0);

            //Sumar fuente cooperante
            this.totalProyeccionDesembolsos = this.proyeccionesDesembolsos
              .filter(({ tiposDesembolsoId }) => tiposDesembolsoId === 1)
              .reduce(
                (a, b) =>
                  parseFloat(a) +
                  (parseFloat(b["montoOriginal"] * b["tipoCambio"]) || 0),
                0
              );

            //Sumar total contrapartida
            this.totalProyeccionDesembolsosContraPartida = this.proyeccionesDesembolsos
              .filter(({ tiposDesembolsoId }) => tiposDesembolsoId === 2)
              .reduce(
                (a, b) =>
                  parseFloat(a) +
                  (parseFloat(b["montoOriginal"] * b["tipoCambio"]) || 0),
                0
              );
          }
          this.tableLoadingProyecciones = false;
        })
        .catch(() => {
          this.proyeccionesDesembolsos = [];
          this.tableLoadingProyecciones = false;
        });
    },

    //Obtener el lsitado de proyeccion de desempbolosos registrados
    async obtenerMontosSuscritosDepto(proyectoId) {
      this.montosDepartamento = [];
      this.tableLoadingMontosDepto = true;
      this.totalMontoSuscritoDepartamento = "0.00";

      await this.$store
        .dispatch(OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS, { id: proyectoId })
        .then((res) => {
          if (res.status === 200) {
            this.montosDepartamento = res.data;
            this.totalMontoSuscritoDepartamento = this.montosDepartamento.reduce(
              (a, b) => parseFloat(a) + (parseFloat(b["monto"]) || 0),
              0
            );

            this.marcadores = [];
            //Rellenar los marcadores con lat y long
            this.montosDepartamento.forEach((element) => {
              //console.log(element)
              if (element.latitud && element.longitud) {
                this.marcadores.push({
                  position: { lat: element.latitud, lng: element.longitud },
                });
              }
            });
            //console.log(this.marcadores)
          }
          this.tableLoadingMontosDepto = false;
        })
        .catch(() => {
          this.montosDepartamento = [];
          this.tableLoadingMontosDepto = false;
        });
    },

    //Obtener los mecanismos de ejecución registrados
    async obtenerMecanismosProyecto(proyectoId) {
      this.mecanismosRegistrados = [];
      this.tableMecanismosLoading = true;
      // this.totalMontoSuscritoDepartamento = "0.00";

      await this.$store
        .dispatch(OBTENER_MECANISMOS_EJECUCION_CNS, { id: proyectoId })
        .then((res) => {
          if (res.status === 200) {
            this.mecanismosRegistrados = res.data;
            // this.totalMontoSuscritoDepartamento = this.montosDepartamento.reduce((a,b) => parseFloat(a) + (parseFloat(b['monto']) || 0), 0);
          }
          this.tableMecanismosLoading = false;
        })
        .catch(() => {
          this.mecanismosRegistrados = [];
          this.tableMecanismosLoading = false;
        });
    },

    async obtenerDatosFinancieros(proyectoId) {
      this.tableDatosFinancierosLoading = true;
      this.datosFinancierosProyecto = [];
      this.totalDatosFinancierosFC = 0;
      this.totalDatosFinancierosCP = 0;
      this.totalDatosFinancierosOtros = 0;
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_CNS, { id: proyectoId })
        .then((res) => {
          if (res.status === 200) {
            this.datosFinancierosProyecto = res.data;
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
              (a, b) => a + (b["montoTotalQuetzales"] || 0),
              0
            );

            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              (dato) => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
              this.monedasIdFuenteCooperante = searchObjectFC.monedasId;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;
            searchObjectFC
              ? (this.datoFinancieroFCRegistrado = searchObjectFC.length)
              : (this.datoFinancieroFCRegistrado = 0);
            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              (dato) => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.monedasIdContrapartida = searchObjectFC.monedasId;
              this.contraPartidaRegistrada = true;
            } else {
              this.contraPartidaRegistrada = false;
            }

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              (dato) => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.monedaOtros = searchObjectOtros.codigoMoneda;
              this.otrosAportesRegistrados = true;
            } else {
              this.otrosAportesRegistrados = false;
            }

            if (
              searchObjectCP &&
              searchObjectOtros &&
              (searchObjectCP.monedasId != searchObjectOtros.monedasId ||
                searchObjectCP.tipoCambio != searchObjectOtros.tipoCambio)
            ) {
              this.alertaDatosFinancierosMonedaCambio = true;
            } else {
              this.alertaDatosFinancierosMonedaCambio = false;
            }

            //Sumar fuente cooperante
            this.totalDatosFinancierosFC = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 1
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //console.log(this.totalDatosFinancierosFC)
            //Sumar total contrapartida
            /*this.totalDatosFinancierosCP = this.datosFinancierosProyecto.filter(({
                                                                                    tiposDatosFinancierosId
                                                                                }) => tiposDatosFinancierosId === 2).reduce((a,b) =>  a + (b['montoTotalQuetzales'] || 0), 0);
                    */

            this.totalDatosFinancierosCP = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) =>
                  tiposDatosFinancierosId === 2 || tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalDatosFinancierosOtros = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) =>
                  tiposDatosFinancierosId === 2 || tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);
          }
          this.tableDatosFinancierosLoading = false;
        })
        .catch(() => {
          //console.log(error)
          this.datosFinancierosProyecto = [];
          this.tableDatosFinancierosLoading = false;
        });
    },

    async obtenerDatosDistribucionFuenteCooperante(proyectoId) {
      this.tableLoadingDistribucion = true;
      this.distribucionesMontoSuscrito = [];
      this.totalDistribucionMontos = 0;
      this.totalDistribucionMonedaOriginal = 0;
      await this.$store
        .dispatch(OBTENER_DISTRIBUCIONES_FUENTE_COOPERANTE_CNS, {
          id: proyectoId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.distribucionesMontoSuscrito = res.data;
            this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce(
              (a, b) => a + (b["montoMonedaOriginal"] * b["tipoCambio"] || 0),
              0
            );

            this.totalDistribucionMonedaOriginal = this.distribucionesMontoSuscrito.reduce(
              (a, b) => a + (b["montoMonedaOriginal"] || 0),
              0
            );

            //console.log(parseFloat((this.totalDistribucionMontos).toFixed(2)) +"  "+ parseFloat((this.totalDatosFinancierosFC).toFixed(2)));
            if (
              parseFloat(this.totalDistribucionMontos.toFixed(2)) >
              parseFloat(this.totalDatosFinancierosFC.toFixed(2))
            ) {
              // console.log("trueeeeeeee")
              this.alertaTotalDistribucion = true;
            } else {
              //console.log("faaaalsseeeee")
              this.alertaTotalDistribucion = false;
            }
          }
          this.tableLoadingDistribucion = false;
        })
        .catch(() => {
          this.distribucionesMontoSuscrito = [];
          this.tableLoadingDistribucion = false;
        });
    },

    //Obtener las ejecuciones por actividdad de cns
    async obtenerEjecucionesPorActividad(proyectoId) {
      this.tableLoadingSubdivisionCooperante = true;
      this.proyeccionesSubDivision = [];
      this.totalSubDivisionCooperante = 0;
      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CNS, { id: proyectoId })
        .then((res) => {
          if (res.status === 200) {
            this.proyeccionesSubDivision = res.data;
            this.totalSubDivisionCooperante = parseFloat(
              this.proyeccionesSubDivision
                .reduce((a, b) => a + (b["aporteFuenteCooperante"] || 0), 0)
                .toFixed(2)
            );

            // console.log(this.totalSubDivisionCooperante)
          }
          this.tableLoadingSubdivisionCooperante = false;
        })
        .catch(() => {
          this.proyeccionesSubDivision = [];
          this.tableLoadingSubdivisionCooperante = false;
        });
    },

    async obtenerEjecucionesPorActividadContrapartida(proyectoId) {
      this.tableLoadingSubdivisionContrapartida = true;
      this.proyeccionesSubDivisionContrapartida = [];
      this.totalSubDivisionContrapartida = 0;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS, {
          id: proyectoId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.proyeccionesSubDivisionContrapartida = res.data;
            this.totalSubDivisionContrapartida = parseFloat(
              this.proyeccionesSubDivisionContrapartida
                .reduce((a, b) => a + (b["monto"] || 0), 0)
                .toFixed(2)
            );
          }
          this.tableLoadingSubdivisionContrapartida = false;
        })
        .catch(() => {
          this.proyeccionesSubDivisionContrapartida = [];
          this.tableLoadingSubdivisionContrapartida = false;
        });
    },

    async obtenerEjecucionesPorActividadDeptoMuni(proyectoId) {
      this.tableLoadingEjecucionesTerritorio = true;
      this.ejecucionesActividadTerritorio = [];
      this.totalEjecucionActividadTerritorio = 0;
      await this.$store
        .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS, {
          estadoId: 1,
          id: proyectoId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.ejecucionesActividadTerritorio = res.data;
            this.totalEjecucionActividadTerritorio = this.ejecucionesActividadTerritorio.reduce(
              (a, b) => a + (b["montoQuetzales"] || 0),
              0
            );
          }
          this.tableLoadingEjecucionesTerritorio = false;
        })
        .catch(() => {
          this.ejecucionesActividadTerritorio = [];
          this.tableLoadingEjecucionesTerritorio = false;
        });
    },

    //Obtener mecanismos ejecucion
    async obtenerMecanismosEjecucion() {
      this.mecanismosEjecucion = [
        { id: 1, nombreMecanismo: "Nombre o guías del cooperante" },
        { id: 2, nombreMecanismo: "Ley de contrataciones del estado" },
        { id: 3, nombreMecanismo: "Otros" },
      ];

      this.mecanismosEjecucion = [];

      //this.ddTiposDatosFinancierosLoading=true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "MecanismosEjecucion/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.mecanismosEjecucion = res.data;
          }
          //    this.ddTiposDatosFinancierosLoading=false;
        })
        .catch(() => {
          this.mecanismosEjecucion = [];
          //   this.ddTiposDatosFinancierosLoading=false;
        });
    },

    //Obtener tipos de datos financieros
    async obtenerTiposDatosFinancieros() {
      this.tiposDatosFinancieros = [];

      this.ddTiposDatosFinancierosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposDatosFinancieros/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.tiposDatosFinancieros = res.data;
          }
          this.ddTiposDatosFinancierosLoading = false;
        })
        .catch(() => {
          this.tiposDatosFinancieros = [];
          this.ddTiposDatosFinancierosLoading = false;
        });

      //this.agregarDatosDistribucion();
    },

    //Obtener tipos de datos financieros para distribución
    async obtenerTiposDatosFinancierosDistribucion() {
      this.tiposDatosFinancierosDistribucion = [];

      this.ddTiposDatosFinancierosDistLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposDatosFinancierosDist/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.tiposDatosFinancierosDistribucion = res.data;
          }
          this.ddTiposDatosFinancierosDistLoading = false;
        })
        .catch(() => {
          this.tiposDatosFinancierosDistribucion = [];
          this.ddTiposDatosFinancierosDistLoading = false;
        });

      //this.agregarDatosDistribucion();
    },

    //Obtener tipos de datos financieros
    async obtenerPeriodos() {
      this.periodos = [];

      this.ddPeriodosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Periodos/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.periodos = res.data;
          }
          this.ddPeriodosLoading = false;
        })
        .catch(() => {
          this.periodos = [];
          this.ddPeriodosLoading = false;
        });
    },

    //Obtener tipos de desembolso
    async obtenerTiposDesembolso() {
      this.tiposDesembolso = [];

      this.ddTiposDesembolsoLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposDesembolsos/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.tiposDesembolso = res.data;
          }
          this.ddTiposDesembolsoLoading = false;
        })
        .catch(() => {
          this.tiposDesembolso = [];
          this.ddTiposDesembolsoLoading = false;
        });
    },

    async registrarMecanismoEjecucionCns() {
      this.btnRegistroMecanismoLoading = true;
      this.datosMecanismoEjecucion.proyectosCNSId = this.id;
      this.datosMecanismoEjecucion.usuarioActualizacion = "admin";
      await this.$store
        .dispatch(REGISTRAR_MECANISMO_EJECUCION_CNS, {
          id: this.datosMecanismoEjecucion.proyectosCNSId,
          datos: this.datosMecanismoEjecucion,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.obtenerProyeccionesDesembolsos(this.id);
            //this.resetItemsDesembolsos();
            this.datosMecanismoEjecucion.mecanismosEjecucionId = 0;
            this.obtenerMecanismosProyecto(this.id);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroMecanismoLoading = false;
        })
        .catch((error) => {
          this.btnRegistroMecanismoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Eliminar registro de mecanismo de ejecución
    async eliminarMecanismo(id) {
      this.btnEliminarMecanismoDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_MECANISMO_EJECUCION_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerMecanismosProyecto(this.id);
            //this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarMecanismoDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarMecanismoDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    agregarDatosDistribucion() {
      this.tiposDatosFinancierosDistribucion = JSON.parse(
        JSON.stringify(this.tiposDatosFinancieros)
      );

      /* this.tiposDatosFinancierosDistribucion.forEach(function(element){
                element.disabled = true 
            });*/
    },

    //Obtener monedas
    async obtenerMonedas() {
      this.monedas = [];

      this.ddMonedasLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Moneda/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.monedas = res.data;
          }
          this.ddMonedasLoading = false;
        })
        .catch(() => {
          this.monedas = [];
          this.ddMonedasLoading = false;
        });
    },

    //Obtener departamentos
    async obtenerDepartamentos() {
      this.departamentosLoading = true;
      this.departamentosActividadLoading = true;
      this.departamentos = [];
      //this.departamentosActividad = [];
      await this.$store
        .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
        .then((res) => {
          if (res.status === 200) {
            this.departamentos = res.data;
            //this.departamentosActividad = res.data;
          }
          this.departamentosLoading = false;
          // this.departamentosActividadLoading=false;
        })
        .catch(() => {
          this.departamentos = [];
          //this.departamentosActividad = [];
          this.departamentosLoading = false;
          //this.departamentosActividadLoading=false;
        });
    },

    //Obtener municipios por departamento
    async obtenerMunicipiosPorDepartamento(departamentosId, tipo) {
      if (tipo === 1) {
        this.municipiosMonto = [];
        this.municipiosMontoLoading = true;
        this.municipioSeleccionadoMonto = {};
      } else {
        this.ddMunicipiosLoading = true;
        this.datosSubdivisionTerritorio.municipiosId = null;
      }

      await this.$store
        .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, departamentosId) //1 representa al país 1 (Guatemala)
        .then((res) => {
          if (res.status === 200) {
            if (tipo === 1) {
              this.municipiosMonto = res.data;
              //console.log(this.departamentoSeleccionadoMonto)
              this.setLatLong(
                this.departamentoSeleccionadoMonto.latitud,
                this.departamentoSeleccionadoMonto.longitud
              );
            } else {
              this.municipios = res.data;
            }
          }
          this.ddMunicipiosLoading = false;
          this.municipiosMontoLoading = false;
        })
        .catch(() => {
          if (tipo === 1) {
            this.municipiosMonto = [];
          } else {
            this.ddMunicipiosLoading = true;
          }

          this.ddMunicipiosLoading = false;
          this.municipiosMontoLoading = false;
        });
    },

    //Colocar los datos latitud y longitud automáticamente en los input text
    setLatLong(latitud, longitud) {
      this.montoLatitud = latitud.toString();
      this.montoLongitud = longitud.toString();
    },

    //Obtener subdivisiones (Componente, objetivo, indicador, resultado, meta )
    async obtenerSubDivisiones() {
      this.subDivisiones = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "ActividadesEjecucion/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.subDivisiones = res.data;
          }
          //this.ddTiposDatosFinancierosLoading=false;
        })
        .catch(() => {
          this.subDivisiones = [];
          //this.ddTiposDatosFinancierosLoading=false;
        });
    },

    //Obtener las referencias para la subdivision de la fuente cooperante
    async obtenerReferenciasSubdivisionFC(codSigeaci) {
      this.referenciasSubdivisionesFC = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `SubdivisionesEjecucionCoin/1/1/${codSigeaci}`,
        })
        .then((res) => {
          if (res.status === 200) {
            this.referenciasSubdivisionesFC = res.data;
          }
          //this.ddTiposDatosFinancierosLoading=false;
        })
        .catch(() => {
          this.referenciasSubdivisionesFC = [];
          //this.ddTiposDatosFinancierosLoading=false;
        });
    },

    //Obtener las referencias para la subdivision de la contrapartida
    async obtenerReferenciasSubdivisionCP(codSigeaci) {
      this.referenciasSubdivisionesCP = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `SubdivisionesEjecucionCoin/1/2/${codSigeaci}`,
        })
        .then((res) => {
          if (res.status === 200) {
            this.referenciasSubdivisionesCP = res.data;
          }
          //this.ddTiposDatosFinancierosLoading=false;
        })
        .catch(() => {
          this.referenciasSubdivisionesCP = [];
          //this.ddTiposDatosFinancierosLoading=false;
        });
    },

    //Agregar Datos financieros
    async agregarDatoFinanciero() {
      this.btnDatosFinancierosLoading = true;
      this.datosFinancieros.estadosId = 1;
      this.datosFinancieros.usuarioCreacion = "admin";
      this.datosFinancieros.proyectosCNSId = this.id;
      this.datosFinancieros.montoMonedaOriginal = this.montoMonedaOriginal;
      this.datosFinancieros.monedasId = this.monedaSeleccionada.id;
      this.datosFinancieros.tipoCambio = this.tipoCambio;

      //Validar que el primer registro sea fuente cooperante
      if (
        this.datoFinancieroFCRegistrado <= 0 &&
        this.datosFinancieros.tiposDatosFinancierosId != 1
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe registrar inicialmente información para la Fuente Cooperante`
        );
        this.btnDatosFinancierosLoading = false;
        return;
      }

      //Validar que si ya se encuentra contrapartida registrada, y se está registrando Otros aportes, se valide que lleve el mismo tipo de cambio y la misma moneda
      //aplica tambien a viceversa

      //Validar si el que se registra es otros aportes
      if (this.datosFinancieros.tiposDatosFinancierosId === 3) {
        //Buscar si hay contrapartida registrada
        var buscarContrapartida = this.datosFinancierosProyecto.find(
          (item) => item.tiposDatosFinancierosId === 2
        );
        // Si existe contrapartida, validar que la moneda y el tipo de cambio sea la misma
        if (buscarContrapartida) {
          if (
            this.datosFinancieros.monedasId != buscarContrapartida.monedasId
          ) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `La moneda para Otros Aportes debe ser la misma que se registró para la contrapartida.`
            );
            this.btnDatosFinancierosLoading = false;

            return;
          }

          if (
            parseFloat(this.datosFinancieros.tipoCambio) !=
            parseFloat(buscarContrapartida.tipoCambio)
          ) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `El tipo de campo para Otros Aportes debe ser el mismo que se registró para la contrapartida.`
            );
            this.btnDatosFinancierosLoading = false;
            return;
          }
        }
      }

      //Validar si el que se registra es contrapartida
      if (this.datosFinancieros.tiposDatosFinancierosId === 2) {
        //Buscar si hay contrapartida registrada
        var buscarOtrosAportes = this.datosFinancierosProyecto.find(
          (item) => item.tiposDatosFinancierosId === 3
        );
        // Si existe contrapartida, validar que la moneda y el tipo de cambio sea la misma
        if (buscarOtrosAportes) {
          if (this.datosFinancieros.monedasId != buscarOtrosAportes.monedasId) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `La moneda para Otros Aportes debe ser la misma que se registró para la contrapartida.`
            );
            this.btnDatosFinancierosLoading = false;
            return;
          }

          if (
            parseFloat(this.datosFinancieros.tipoCambio) !=
            parseFloat(buscarOtrosAportes.tipoCambio)
          ) {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `El tipo de campo para Otros Aportes debe ser el mismo que se registró para la contrapartida.`
            );
            this.btnDatosFinancierosLoading = false;
            return;
          }
        }
      }

      /*Buscar el tipo de dato financiero dentor del objeto*/
      var datoComparar = this.datosFinancierosProyecto.find(
        (item) =>
          item.tiposDatosFinancierosId ===
          this.datosFinancieros.tiposDatosFinancierosId
      );

      if (datoComparar) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El tipo de datos financiero que intenta agregar ya se encuentra registrado!.`
        );
        this.btnDatosFinancierosLoading = false;
        return;
      }

      //Validar monto que sea mayor a cero 00
      if (parseFloat(this.datosFinancieros.montoTotalQuetzales) <= 0) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          "El monto debe ser mayor a 0.00"
        );
        this.btnDatosFinancierosLoading = false;
        return;
      }

      //console.log(this.datosCobertura);

      await this.$store
        .dispatch(REGISTRAR_DATO_FINANCIERO_CNS, {
          datos: this.datosFinancieros,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.resetItems(2);
            this.obtenerDatosFinancieros(this.id);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnDatosFinancierosLoading = false;
        })
        .catch((error) => {
          this.btnDatosFinancierosLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
        (a, b) => a + (b["montoTotalQuetzales"] || 0),
        0
      );

      /* this.datosFinancierosProyecto.push({
                datosFinancierosId: this.datoFinanciero.id,
                nombreTipoDatoFinanciero: this.datoFinanciero.nombreTipoDatoFinanciero,
                monedasId: this.moneda.id,
                moneda: this.moneda.nombreMoneda,
                montoMonedaOriginal: this.montoMonedaOriginal,
                tipoCambio: this.tipoCambio,
                montoTotalQuetzales: this.montoTotalQuetzales
            });

            //Sumar la columna total Q
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);

          
            //Habilitar tipos de datos financieros para distribución
            //this.tiposDatosFinancierosDistribucion.find( item => item.id === this.datoFinanciero.id).disabled = false;

            
             


            //Limpiar los campos
            this.datoFinanciero = {};
            this.moneda.id = '';
            this.moneda = {};
            this.montoMonedaOriginal = '';
            this.tipoCambio = '';
*/
    },

    async eliminarDatoFinanciero(id) {
      //Validar que no existan distribuciones registradas
      if (this.distribucionesMontoSuscrito.length > 0) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "warning",
          `No puede eliminar datos financieros debido a que existen distribuciones registradas. Debe elimnar las distribuciones correspondientes.`
        );
        return;
      }

      this.btnEliminarDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_DATO_FINANCIERO_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDatosFinancieros(this.id);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      //Deshabilitar tipos de datos financieros para distribución
      //this.tiposDatosFinancierosDistribucion.find( item => item.id === id).disabled = true;
    },

    //Agregar la distribucion de monto suscrito
    async agregarDistribucionMontoSuscrito() {
      /*Buscar el tipo de dato financiero dentor del objeto*/
      let datoComparar = this.distribucionesMontoSuscrito.find(
        (item) =>
          item.tiposDatosFinancierosDistId ===
          this.datosDistribucion.tiposDatosFinancierosDistId
      );

      if (datoComparar) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El tipo de datos financiero que intenta agregar ya se encuentra registrado!.`
        );
        return;
      }

      //Buscar el dato financiero que coincida con el dato financiero de distribución, para verificar su monto

      let registro = this.datosFinancierosProyecto.find(
        (item) => item.tiposDatosFinancierosId === 1
      );
      // console.log(registro)
      //this.datosDistribucion.montoMonedaOriginal = this.monedaOriginalDistribuicion;
      this.datosDistribucion.tipoCambio = registro.tipoCambio;
      this.datosDistribucion.proyectosCNSId = this.id;

      //this.totalDatosFinancierosFC

      //Verificar que la suma no sobrepase el monto
      //let sumaTotalesDist = (this.distribucionesMontoSuscrito.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0) + (registro.tipoCambio * this.montoMonedaOriginalDistribucion));

      // let objetoTotalMontoSuscrito = this.distribucionesMontoSuscrito.find( item => item.tiposDatosFinancierosId === 1);

      //Obtener el total de la moneda original de los datos financieros
      // let totalOriginalDatosFinancieros = this.datosFinancierosProyecto(item => item.tiposDesembolsoId === 1).reduce((a,b) => a + ((b['montoMonedaOriginal'])||0),0);

      let totalOriginalDatosFinancieros = this.datosFinancierosProyecto
        .filter(({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 1)
        .reduce((a, b) => a + (b["montoMonedaOriginal"] || 0), 0);

      let sumaTotalesDist = 0;
      if (this.distribucionesMontoSuscrito.length > 0) {
        sumaTotalesDist =
          this.distribucionesMontoSuscrito.reduce(
            (a, b) =>
              a + (b["montoMonedaOriginal"] /* * b['tipoCambio']*/ || 0),
            0
          ) +
          /*this.datosDistribucion.tipoCambio * */ parseFloat(
            this.datosDistribucion.montoMonedaOriginal
          );
        // console.log("si")
      } else {
        sumaTotalesDist = parseFloat(
          this.datosDistribucion.montoMonedaOriginal
        );
        // console.log("no")
      }

      //console.log(sumaTotalesDist)
      /*console.log(typeof sumaTotalesDist)

            console.log(parseFloat(totalOriginalDatosFinancieros.toFixed(2)))
            console.log(typeof parseFloat(totalOriginalDatosFinancieros.toFixed(2))) */

      if (
        sumaTotalesDist.toFixed(2) >
        parseFloat(totalOriginalDatosFinancieros.toFixed(2))
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La distribución del monto suscrito que desea agregar sobrepasa el total de la Suscripción del convenio!.`
        );
        return;
      }

      this.datosDistribucion.monedasId = this.monedasIdFuenteCooperante;

      let registroDatosDistribucion = this.datosDistribucion;
      registroDatosDistribucion.montoMonedaOriginal = parseFloat(
        Number(this.datosDistribucion.montoMonedaOriginal).toFixed(2)
      );

      this.btnDistribucionLoading = true;
      await this.$store
        .dispatch(REGISTRAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS, {
          datos: registroDatosDistribucion,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.datosDistribucion.tiposDatosFinancierosDistId = 0;
            this.datosDistribucion.montoMonedaOriginal = "";
            this.monedaOriginalDistribuicion = "";
            this.obtenerDatosDistribucionFuenteCooperante(this.id);
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnDistribucionLoading = false;
        })
        .catch((error) => {
          this.btnDistribucionLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      /*
            this.distribucionesMontoSuscrito.push({
                datosFinancierosId: this.datoFinancieroDistribucion.id,
                nombreTipoDatoFinanciero: this.datoFinancieroDistribucion.nombreTipoDatoFinanciero,
                montoMonedaOriginal: this.montoMonedaOriginalDistribucion,
                tipoCambio: registro.tipoCambio,
                montoTotalQuetzales: registro.tipoCambio * this.montoMonedaOriginalDistribucion
            });

            this.datoFinancieroDistribucion = {};

            this.montoMonedaOriginalDistribucion = '';
                */
      //Sumar la columna total Q

      this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce(
        (a, b) => a + (b["montoTotalQuetzales"] || 0),
        0
      );
    },

    async eliminarDistribucionMontoSuscrito(id) {
      this.btnEliminarDistribucionFuenteDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDatosDistribucionFuenteCooperante(this.id);
            this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce(
              (a, b) => a + (b["montoTotalQuetzales"] || 0),
              0
            );
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarDistribucionFuenteDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarDistribucionFuenteDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      //Deshabilitar tipos de datos financieros para distribución
      //this.tiposDatosFinancierosDistribucion.find( item => item.id === id).disabled = true;
    },

    //Actualizar en la tabla proyectos, si cuenta con condiciones de desembolso
    async actualizarCuentaCondicionesDesembolsos() {
      this.cuentaDesembolsosLoading = true;
      this.datosCuentaDesembolsos.proyectosCNSId = this.id;
      this.cuentaCondicionDesembolsoSeleccionada.id === 2
        ? (this.datosCuentaDesembolsos.proyectaDesembolsos = 0)
        : (this.datosCuentaDesembolsos.proyectaDesembolsos = 1);
      await this.$store
        .dispatch(ACTUALIZAR_CUENTA_CONDICIONES_DESEMOLSOS, {
          id: this.id,
          proyectaDesembolsos: this.datosCuentaDesembolsos.proyectaDesembolsos,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            if (this.cuentaCondicionDesembolsoSeleccionada.id === 2) {
              this.habilitarProyeccionDesembolsos = false;
              this.datosFormaOperar.detalleFormaOperar = "";
            } else {
              this.habilitarProyeccionDesembolsos = true;
              this.obtenerProyeccionesDesembolsos(this.id);
            }
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.habilitarProyeccionDesembolsos = false;
          }
          this.cuentaDesembolsosLoading = false;
        })
        .catch((error) => {
          this.cuentaDesembolsosLoading = false;
          if (this.cuentaCondicionDesembolsoSeleccionada.id === 2) {
            this.habilitarProyeccionDesembolsos = false;
          } else {
            this.habilitarProyeccionDesembolsos = true;
          }
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Actualizar en la tabla proyectos, si cuenta con información de monto suscrito a nivel territorial
    async actualizarCuentaMontoTerritorial() {
      this.cuentaMonoSuscritoTerritorialLoading = true;
      this.datosCuentaMontoTerritorio.proyectosCNSId = this.id;
      this.cuentaMontoSuscritoTerritoralSeleccionado.id === 2
        ? (this.datosCuentaMontoTerritorio.detalleMontoPorDepartamento = 0)
        : (this.datosCuentaMontoTerritorio.detalleMontoPorDepartamento = 1);
      await this.$store
        .dispatch(ACTUALIZAR_CUENTA_MONTO_TERRITORIO, {
          id: this.id,
          detalleMontoPorDepartamento: this.datosCuentaMontoTerritorio
            .detalleMontoPorDepartamento
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            if (this.cuentaMontoSuscritoTerritoralSeleccionado.id === 2) {
              this.habilitarMontoTerritorial = false;
            } else {
              this.habilitarMontoTerritorial = true;
            }

            this.obtenerMontosSuscritosDepto(this.id);
            this.$emit(
              "habilitar-cobertura-territorial",
              this.cuentaMontoSuscritoTerritoralSeleccionado.id
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.habilitarMontoTerritorial = false;
          }
          this.cuentaMonoSuscritoTerritorialLoading = false;
        })
        .catch((error) => {
          this.cuentaMonoSuscritoTerritorialLoading = false;
          if (this.cuentaMontoSuscritoTerritoralSeleccionado.id === 2) {
            this.habilitarMontoTerritorial = false;
          } else {
            this.habilitarMontoTerritorial = true;
          }
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Agregar Datos financieros
    async agregarProyeccionDesembolsos() {
      this.btnRegistroProyeccionLoading = true;
      this.datosProyeccionDesembolsos.estadosId = 1;
      this.datosProyeccionDesembolsos.usuarioCreacion = "admin";
      this.datosProyeccionDesembolsos.proyectosCNSId = this.id;
      this.datosProyeccionDesembolsos.montoMonedaOriginal = parseFloat(
        Number(this.montoMonedaOriginal).toFixed(2)
      );

      //Validar si se está registrando para la fuente cooperante, que exista la fuente cooperante registrada en datos financieros
      if (
        this.datosProyeccionDesembolsos.tiposDesembolsoId === 1 &&
        this.datoFinancieroFCRegistrado <= 0
      ) {
        //
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe registrar inicialmente información de datos financieros para la Fuente Cooperante`
        );

        this.btnRegistroProyeccionLoading = false;
        return;
      }

      //Validar si se está registrando para la CONTRAPARTIDA, que exista CONTRAPARTIDA registrada en datos financieros
      if (
        this.datosProyeccionDesembolsos.tiposDesembolsoId === 2 &&
        !this.contraPartidaRegistrada
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe registrar inicialmente información de datos financieros para la Contrapartida`
        );

        this.btnRegistroProyeccionLoading = false;
        return;
      }

      /*Buscar el tipo de dato financiero dentor del objeto*/
      var datoComparar = this.proyeccionesDesembolsosProyecto.find(
        (item) =>
          item.tiposDatosFinancierosId ===
          this.datosProyeccionDesembolsos.tiposDatosFinancierosId
      );

      if (datoComparar) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El tipo de datos financiero que intenta agregar ya se encuentra registrado!.`
        );
        this.btnRegistroProyeccionLoading = false;
        return;
      }

      let registroTipoCambio = null;

      if (this.datosProyeccionDesembolsos.tiposDesembolsoId === 1) {
        registroTipoCambio = this.datosFinancierosProyecto.find(
          (item) => item.tiposDatosFinancierosId === 1
        );
      } else if (this.datosProyeccionDesembolsos.tiposDesembolsoId === 2) {
        //Buscar en datos financieros si hay contrapartida
        let datosContrapartida = this.datosFinancierosProyecto.find(
          (item) => item.tiposDatosFinancierosId === 2
        );
        let datosOtrosAportes = this.datosFinancierosProyecto.find(
          (item) => item.tiposDatosFinancierosId === 3
        );

        if (datosContrapartida != null) {
          registroTipoCambio = this.datosFinancierosProyecto.find(
            (item) => item.tiposDatosFinancierosId === 2
          );
        } else {
          if (datosOtrosAportes != null) {
            registroTipoCambio = this.datosFinancierosProyecto.find(
              (item) => item.tiposDatosFinancierosId === 3
            );
          }
        }
      }
      //Buscar el dato financiero que coincida con el dato financiero de distribución, para verificar su monto

      // console.log(registro);

      this.datosProyeccionDesembolsos.tipoCambio =
        registroTipoCambio.tipoCambio;

      ///Validar

      //console.log(this.datosProyeccionDesembolsos.tiposDesembolsoId)

      //Sumar fuente cooperante
      //let totalFinFC = 0;

      if (this.datosProyeccionDesembolsos.tiposDesembolsoId === 1) {
        let sumTotalFinFC =
          this.proyeccionesDesembolsos
            .filter((item) => item.tiposDesembolsoId === 1)
            .reduce((a, b) => a + (b["montoOriginal"] || 0), 0) *
            this.datosProyeccionDesembolsos.tipoCambio +
          parseFloat(
            this.datosProyeccionDesembolsos.montoOriginal *
              this.datosProyeccionDesembolsos.tipoCambio
          );
        // let sumTotalFinFC = totalFinFC + parseFloat((this.datosProyeccionDesembolsos.montoOriginal) * this.datosProyeccionDesembolsos.tipoCambio );
        /*console.log(sumTotalFinFC)
                console.log(typeof sumTotalFinFC)
                */

        if (
          sumTotalFinFC > parseFloat(this.totalDatosFinancierosFC.toFixed(2))
        ) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El monto que desea registrar para la fuente cooperante sobrepasa el monto registrado en los datos financieros (${parseFloat(
              this.totalDatosFinancierosFC
            ).toLocaleString("es-GT", {
              style: "currency",
              currency: "GTQ",
              maximumFractionDigits: this.maxDigits,
            })})`
          );
          this.btnRegistroProyeccionLoading = false;
          return;
        }
      }

      //console.log(this.totalDatosFinancierosFC)
      //Sumar total contrapartida
      let totalFinCO = 0;

      if (this.datosProyeccionDesembolsos.tiposDesembolsoId === 2) {
        totalFinCO =
          this.proyeccionesDesembolsos
            .filter((item) => item.tiposDesembolsoId === 2)
            .reduce((a, b) => a + (b["montoOriginal"] || 0), 0) *
            this.datosProyeccionDesembolsos.tipoCambio +
          parseFloat(
            this.datosProyeccionDesembolsos.montoOriginal *
              this.datosProyeccionDesembolsos.tipoCambio
          );
        /*console.log(totalFinCO)
                console.log(typeof totalFinCO)
                */
        if (totalFinCO > parseFloat(this.totalDatosFinancierosCP.toFixed(2))) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El monto que desea registrar para la contrapartida es mayor a los datos financieros registrados (${parseFloat(
              this.totalDatosFinancierosCP
            ).toLocaleString("es-GT", {
              style: "currency",
              currency: "GTQ",
              maximumFractionDigits: this.maxDigits,
            })})`
          );
          this.btnRegistroProyeccionLoading = false;
          return;
        }
        //console.log(totalFinCO)
      }

      ///

      //Validar monto que sea mayor a cero 00
      if (parseFloat(this.datosProyeccionDesembolsos.montoOriginal) <= 0) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          "El monto debe ser mayor a 0.00"
        );
        this.btnRegistroProyeccionLoading = false;
        return;
      }

      //console.log(this.datosCobertura);

      await this.$store
        .dispatch(REGISTRAR_PROYECCION_DESEMBOLSOS_CNS, {
          datos: this.datosProyeccionDesembolsos,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProyeccionesDesembolsos(this.id);
            this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroProyeccionLoading = false;
        })
        .catch((error) => {
          this.btnRegistroProyeccionLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      // this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);

      /* this.datosFinancierosProyecto.push({
                datosFinancierosId: this.datoFinanciero.id,
                nombreTipoDatoFinanciero: this.datoFinanciero.nombreTipoDatoFinanciero,
                monedasId: this.moneda.id,
                moneda: this.moneda.nombreMoneda,
                montoMonedaOriginal: this.montoMonedaOriginal,
                tipoCambio: this.tipoCambio,
                montoTotalQuetzales: this.montoTotalQuetzales
            });

            //Sumar la columna total Q
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);

          
            //Habilitar tipos de datos financieros para distribución
            //this.tiposDatosFinancierosDistribucion.find( item => item.id === this.datoFinanciero.id).disabled = false;

            
             


            //Limpiar los campos
            this.datoFinanciero = {};
            this.moneda.id = '';
            this.moneda = {};
            this.montoMonedaOriginal = '';
            this.tipoCambio = '';
*/
    },

    //Registrar la forma de formaOperar
    async registrarFormaOperar() {
      this.btnRegistroFormaOperarLoading = true;
      this.datosFormaOperar.proyectosCNSId = this.id;
      this.datosFormaOperar.usuarioActualizacoin = "admin";
      await this.$store
        .dispatch(ACTUALIZAR_FORMA_OPERAR, {
          id: this.datosFormaOperar.proyectosCNSId,
          datos: this.datosFormaOperar,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //this.obtenerProyeccionesDesembolsos(this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroFormaOperarLoading = false;
        })
        .catch((error) => {
          this.btnRegistroFormaOperarLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Registrar/Actualizar datos ingresa a presupuesto
    async registrarDatosIngresaPresupuesto() {
      this.btnRegistroDatosPresupuestoLoading = true;
      this.datosIngresaPresupuesto.proyectosCNSId = this.id;
      /*this.datosIngresaPresupuesto.ingresaPresupuesto ="admin";
            this.datosIngresaPresupuesto.codigoInstitucion ="admin";
            this.datosIngresaPresupuesto.codigoFuenteCooperante ="admin";
            this.datosIngresaPresupuesto.codigoProyecto ="admin";*/

      await this.$store
        .dispatch(ACTUALIZAR_DATOS_INGRESA_PRESUPUESTO_CNS, {
          id: this.datosIngresaPresupuesto.proyectosCNSId,
          datos: this.datosIngresaPresupuesto,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //this.obtenerProyeccionesDesembolsos(this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroDatosPresupuestoLoading = false;
        })
        .catch((error) => {
          this.btnRegistroDatosPresupuestoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Eliminar proyeccion de desembolsos
    async eliminarProyeccionDesembolsos(id) {
      /* this.proyeccionesDesembolsos.splice(
            this.proyeccionesDesembolsos.findIndex(e => e.tipoDesembolso == tipoDesembolso),1);
            this.totalProyeccionDesembolsos = this.proyeccionesDesembolsos.reduce((a,b) => parseFloat(a) + (parseFloat(b['montoOriginal'] * b['tipoCambio']) || 0), 0);
            */

      this.btnEliminarProyeccionDesembolsoDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_PROYECCION_DESEMBOLSO_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerProyeccionesDesembolsos(this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarProyeccionDesembolsoDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarProyeccionDesembolsoDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Agregar montos por departamento
    async agregarMontoDepartamento() {
      //Validar que la cantidad a registrar + la cantidad registrada por departamento, no sobrepase lo suscrito fuente cooperante en datos financieros
      //Sumar fuente cooperante de datos financieros
      let totalTerrFC = parseFloat(
        this.montosDepartamento
          .reduce((accumulator, object) => {
            return accumulator + parseFloat(object.monto);
          }, 0)
          .toFixed(2)
      );
      //((a,b) =>  a + (b['monto'] || 0), 0))

      let sumTotalTerrFC = 0;

      if (this.accionMontoTerritorio === 1) {
        //sumTotalTerrFC = parseFloat(totalTerrFC) + parseFloat(this.datosMontoSuscritoDepartamento.monto);

        sumTotalTerrFC =
          parseFloat(totalTerrFC.toFixed(2)) +
          parseFloat(
            Number(this.datosMontoSuscritoDepartamento.monto).toFixed(2)
          );
      } else {
        //sumTotalTerrFC = (parseFloat(totalTerrFC) - parseFloat(this.montoSuscritoDeptoAnterior)) + parseFloat(this.datosMontoSuscritoDepartamento.monto);
        sumTotalTerrFC =
          parseFloat(totalTerrFC.toFixed(2)) -
          parseFloat(Number(this.montoSuscritoDeptoAnterior).toFixed(2)) +
          parseFloat(
            Number(this.datosMontoSuscritoDepartamento.monto).toFixed(2)
          );
      }

      //console.log(this.datosMontoSuscritoDepartamento.monto)
      /*  console.log(totalTerrFC);
            console.log(parseFloat(this.datosMontoSuscritoDepartamento.monto))
            console.log(parseFloat(this.totalDatosFinancierosFC));
            console.log(sumTotalTerrFC.toFixed(2)); */

      if (
        sumTotalTerrFC.toFixed(2) >
        parseFloat(this.totalDatosFinancierosFC.toFixed(2))
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El monto que desea registrar sobrepasa el monto registrado en los datos financieros (${parseFloat(
            this.totalDatosFinancierosFC
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ",
            maximumFractionDigits: this.maxDigits,
          })})`
        );
        this.btnRegistroProyeccionLoading = false;
        //console.log(sumTotalTerrFC + " " + this.totalDatosFinancierosFC)
        return;
      }

      //Buscar el departamento dentro del objeto
      /*let datoComparar = this.montosDepartamento.find( item => item.departamentosId === this.datosMontoSuscritoDepartamento.departamentosId);


            if (datoComparar) {
               this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `El departamento que intenta agregar ya se encuentra registrado!.`
                );
                return;
            }*/

      //Agregar latitud y longitud al modelo
      if (this.montoLatitud && this.montoLatitud.length > 0) {
        if (this.montoLongitud && this.montoLongitud.length > 0) {
          this.datosMontoSuscritoDepartamento.latitud = parseFloat(
            this.montoLatitud
          );
          this.datosMontoSuscritoDepartamento.longitud = parseFloat(
            this.montoLongitud
          );
        } else {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Debe especificar una longitud!.`
          );
          return;
        }
      } else if (
        this.montoLongitud &&
        this.montoLongitud.length > 0 &&
        !this.montoLatitud &&
        this.montoLatitud.length <= 0
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe de especificar una latitud!.`
        );
        return;
      } else {
        delete this.datosMontoSuscritoDepartamento.latitud;
        delete this.datosMontoSuscritoDepartamento.longitud;
      }
      //Buscar el dato financiero que coincida con el dato financiero de distribución, para verificar su monto

      // let registro = this.datosFinancierosProyecto.find( item => item.datosFinancierosId === 1);
      // console.log

      //Verificar que la suma no sobrepase el monto
      /*let sumaTotalesProy = (this.montosDepartamento.reduce((a,b) => a + (b['monto'] || 0), 0) + (this.datosMontoSuscritoDepartamento.monto));
            if(sumaTotalesProy > this.totalDatosFinancieros){
                this.$refs.snackalert.SnackbarShow(
                    "warning",
                    "Alerta",
                    `La suma de los montos por departamento sobrepasa el total de la Suscripción del convenio!.`
                );
                return;
            }*/
      this.datosMontoSuscritoDepartamento.municipiosId = this.municipioSeleccionadoMonto.id;
      this.datosMontoSuscritoDepartamento.departamentosId = this.departamentoSeleccionadoMonto.id;

      this.datosMontoSuscritoDepartamento.codigoMoneda = "GTQ";
      this.btnMontosDeptoLoading = true;

      let registroDatosMontoSuscritoDepartamento = this
        .datosMontoSuscritoDepartamento;
      registroDatosMontoSuscritoDepartamento.monto = parseFloat(
        Number(this.datosMontoSuscritoDepartamento.monto).toFixed(2)
      );

      registroDatosMontoSuscritoDepartamento.proyectosCNSId = this.id;

      let dispatch = REGISTRAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS;
      if (this.accionMontoTerritorio === 2) {
        dispatch = ACTUALIZAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS;
      }

      await this.$store
        .dispatch(dispatch, { datos: registroDatosMontoSuscritoDepartamento })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerMontosSuscritosDepto(this.id);
            this.$emit("obtenerCoberturaTerritoralVinculacion", this.id);
            /*this.datosMontoSuscritoDepartamento = {
                        monto: ""
                    };  */
            this.accionMontoTerritorio = 1;
            registroDatosMontoSuscritoDepartamento = {};
            this.resetItemsTerritorio();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnMontosDeptoLoading = false;
        })
        .catch((error) => {
          this.btnMontosDeptoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
        (a, b) => a + (b["montoTotalQuetzales"] || 0),
        0
      );

      this.totalMontoSuscritoDepartamento = this.montosDepartamento.reduce(
        (a, b) => a + (b["montoTotalQuetzales"] || 0),
        0
      );

      this.montoDepartamento = "";
    },

    //eliminarMontoDepartamento
    async eliminarMontoDepartamento(id) {
      this.btnEliminarMontoDepartamentoDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerMontosSuscritosDepto(this.id);
            this.$emit("obtenerCoberturaTerritoralVinculacion", this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarMontoDepartamentoDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarMontoDepartamentoDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //
    async obtenerDatoMontoTerritorial(id) {
      this.btnEliminarMontoDepartamentoDisabled = true;
      this.dialogLoaderText = "Obteniendo información territorial";
      this.dialogLoaderVisible = true;

      await this.$store
        .dispatch(OBTENER_MONTO_SUSCRITO_DEPARTAMENTO_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            let data = res.data;

            //Cambiar la acción a 2 (actualizar)
            this.accionMontoTerritorio = 2;

            //Buscar el departamento seleccionado
            let deptoSeleccionado = this.departamentos.find(
              (item) => item.id == data.departamentosId
            );
            //console.log(deptoSeleccionado)
            this.datosMontoSuscritoDepartamento.id = data.id;
            this.datosMontoSuscritoDepartamento.proyectosCNSId =
              data.proyectosCNSId;
            this.datosMontoSuscritoDepartamento.estadosId = data.estadosId;
            this.departamentoSeleccionadoMonto = deptoSeleccionado;
            this.montoSuscritoDeptoAnterior = data.monto;
            this.obtenerMunicipiosPorDepartamento(
              this.departamentoSeleccionadoMonto.id,
              1
            ).then(() => {
              if (data.municipiosId) {
                let muniSeleccionado = this.municipiosMonto.find(
                  (item) => item.id == data.municipiosId
                );
                this.municipioSeleccionadoMonto = muniSeleccionado;
              }
            });
            this.municipiosMonto = data.monto.toString();
            this.montoLatitud = data.latitud.toString();
            this.montoLongitud = data.longitud.toString();
            this.datosMontoSuscritoDepartamento.monto = data.monto.toString();

            /*
             */
            // this.obtenerMontosSuscritosDepto(this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.accionMontoTerritorio = 1;
          }
          this.dialogLoaderVisible = false;
          this.btnEliminarMontoDepartamentoDisabled = false;
        })
        .catch((error) => {
          this.accionMontoTerritorio = 1;

          this.btnEliminarMontoDepartamentoDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la ejecución del proceso. ${error}`
          );
        });
    },

    //Mostrar el mapa con el/los puntos del proyecto
    mostrarMapa(latitud, longitud, tipo) {
      if (tipo == 1) {
        this.marcadores = [
          {
            position: {
              lat: latitud,
              lng: longitud,
            },
          },
        ];
      } else {
        //Rellenar los marcadores con lat y long
        this.montosDepartamento.forEach((element) => {
          //console.log(element)
          if (element.latitud && element.longitud) {
            this.marcadores.push({
              position: { lat: element.latitud, lng: element.longitud },
            });
          }
        });
      }

      // console.log(this.marcadores)

      this.dialogUbicaciones = true;
    },

    openGmapInfo(index) {
      const {
        lat,
        lng,
        descripcion,
        ejecucionFinanciera,
        ejecucionFisica,
        institucion,
        intervencion,
        linkSnip,
        snip,
      } = this.marcadores[index].position;
      let formatEjecucionFinanciera = parseFloat(
        ejecucionFinanciera
      ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" });
      let formatEjecucionFisica = this.numFor.format(ejecucionFisica);
      // console.log(this.marcadores[index].position)

      this.infoWindow.position = { lat: lat, lng: lng };

      this.infoWindow.template = `
                    <div class="container" style="letter-spacing: 0.1px; width:100%;">
                        <div class="row">
                            <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                                <b>Institución:</b>
                            </div> <!--end:: col-->
                            <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                                ${institucion}
                            </div> <!--end:: col-->

                            <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                                <b>Intervención:</b>
                            </div> <!--end:: col-->
                            <div class="col col-md-12 col-sm-12 col-xs-12 pt-0 pb-3">
                                ${intervencion}
                            </div> <!--end:: col-->`;
      if (descripcion == "Fisica") {
        this.infoWindow.template += ` <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                                <b> Ejecución Física:</b>
                                </div> <!--end:: col-->
                                <div class="col col-md-12 col-sm-6 col-xs-12 pt-0 pb-3">
                                    ${formatEjecucionFisica}
                                </div> <!--end:: col-->`;
      }

      if (descripcion == "Financiera") {
        this.infoWindow.template += ` <div class="col col-md-12 col-sm-12 col-xs-12 pt-1 pb-1">
                                    <b>Ejecución Financiera:</b>
                                </div> <!--end:: col-->
                                <div class="col col-md-6 col-sm-6 col-xs-12 pt-0 pb-3">
                                    ${formatEjecucionFinanciera}
                                </div> <!--end:: col-->`;
      }

      if (snip == "S") {
        this.infoWindow.template += `
                            <div class="col col-md-12 col-sm-12 pt-4 pb-4">
                                <a type="button" href="${linkSnip}" target="_blank" class="btn btn-outline-secondary">Consultar Snip</a>
                            </div>`;
      }

      this.infoWindow.template += `<v-btn class="btn" ref="btnFicha" @click="showFichaProyecto">Ver ficha del proyecto</v-btn>`;

      this.infoWindow.template += `</div> <!--end:: row-->
                    </div> <!--end:: container-->
                    `;

      this.infoWindow.open[index] = true;

      this.$refs.btnFicha.click();
    },

    //Agregar la referencia de la fuente cooperante del sigeaci si se selecciona
    agregarReferenciaFC() {
      this.datosEjecucion.descripcion = this.referenciaSubdivsionesFCSeleccionada.descripcionSubdivision;

      this.datosEjecucion.aporteFuenteCooperante = this.referenciaSubdivsionesFCSeleccionada.montoAsignadoCoop.toString();

      if (this.referenciaSubdivsionesFCSeleccionada)
        this.aportaContrapartida = 2;
    },

    //Cargar el formulario para actualizar información
    cargarFormSubdivisionFC(datos) {
      this.datosEjecucion.id = datos.id;
      this.datosEjecucion.actividadesEjecucionId = datos.actividadesEjecucionId;
      this.datosEjecucion.descripcion = datos.descripcion;
      this.datosEjecucion.aporteFuenteCooperante = datos.aporteFuenteCooperante.toString();

      this.montoSubdivisionFCAnterior = datos.aporteFuenteCooperante;

      if (datos.idProyeccion)
        this.referenciaSubdivsionesFCSeleccionada = this.referenciasSubdivisionesFC.find(
          (item) => item.idProyeccion === datos.idProyeccion
        );
      else this.referenciaSubdivsionesFCSeleccionada = {};

      //this.datosEjecucion.idProyeccion = datos.idProyeccion;
      this.datosEjecucion.estadosId = 1;
      this.accionSubdivicionFC = 2;

      this.aportaContrapartida = 2;
    },

    cargarFormSubdivisionCP(datos) {
      this.datosEjecucionContraPartida.id = datos.id;
      this.datosEjecucionContraPartida.actividadesEjecucionId =
        datos.actividadesEjecucionId;
      this.datosEjecucionContraPartida.descripcion = datos.descripcion;
      this.datosEjecucionContraPartida.monto = datos.monto.toString();
      this.datosEjecucionContraPartida.estaodsId = 1;

      this.montoSubdivisionCPAnterior = datos.monto;

      if (datos.idProyeccion)
        this.referenciaSubdivsionesCPSeleccionada = this.referenciasSubdivisionesCP.find(
          (item) => item.idProyeccion === datos.idProyeccion
        );
      else this.referenciaSubdivsionesCPSeleccionada = {};

      this.accionSubdivicionCP = 2;
    },

    //Agregar la referencia de la contrapartida del sigeaci si se selecciona
    agregarReferenciaCP() {
      this.datosEjecucionContraPartida.descripcion = this.referenciaSubdivsionesCPSeleccionada.descripcionSubdivision;

      this.datosEjecucionContraPartida.monto = this.referenciaSubdivsionesCPSeleccionada.montoAsignadoContra.toString();
    },

    //Agregar proyeccion de la ejecución por componente, objetivo, ind, res, meta
    async agregarProyeccionSubDivision() {
      this.datosEjecucion.proyectosCNSId = this.id;

      this.aportaContrapartida === 1
        ? (this.datosEjecucion.contraPartida = 1)
        : (this.datosEjecucion.contraPartida = 0);

      /*console.log(parseFloat(this.totalSubDivisionCooperante).toFixed(2));
            console.log(parseFloat(this.datosEjecucion.aporteFuenteCooperante));
            console.log(this.totalDatosFinancierosFC);

            let suma =  parseFloat(this.totalSubDivisionCooperante).toFixed(2) + parseFloat(this.datosEjecucion.aporteFuenteCooperante).toFixed(2);

            console.log(this.totalDatosFinancierosFC);
            console.log(suma);
            */

      /*console.log(parseFloat(this.totalSubDivisionCooperante).toFixed(2));
            console.log(parseFloat(this.datosEjecucion.aporteFuenteCooperante).toFixed(2));*/
      //console.log(parseFloat(this.totalDatosFinancierosFC).toFixed(2));

      let suma = 0;

      if (this.accionSubdivicionFC === 1)
        suma =
          parseFloat(this.totalSubDivisionCooperante.toFixed(2)) +
          parseFloat(
            Number(this.datosEjecucion.aporteFuenteCooperante).toFixed(2)
          );
      else
        suma =
          parseFloat(this.totalSubDivisionCooperante.toFixed(2)) -
          parseFloat(this.montoSubdivisionFCAnterior.toFixed(2)) +
          parseFloat(
            Number(this.datosEjecucion.aporteFuenteCooperante).toFixed(2)
          );

      //console.log(suma);

      /*  console.log(typeof parseFloat(Number(this.datosEjecucion.aporteFuenteCooperante).toFixed(2)));
            console.log(parseFloat(Number(this.datosEjecucion.aporteFuenteCooperante).toFixed(2)));
            console.log(typeof suma);
            
            console.log(typeof parseFloat(this.totalDatosFinancierosFC.toFixed(2)))
            console.log(parseFloat(this.totalDatosFinancierosFC.toFixed(2))) */
      //Validar que la cantidad que se está registrando no sobrepase lo suscrito
      if (suma > parseFloat(this.totalDatosFinancierosFC.toFixed(2))) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que está registrando, sobrepasa el monto suscrito para la Fuente Cooperante (${parseFloat(
            this.totalDatosFinancierosFC
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ",
            maximumFractionDigits: this.maxDigits,
          })})`
        );
        return;
      }

      //Agregar la referencia del sigeaci al registro
      if (this.referenciaSubdivsionesFCSeleccionada)
        this.datosEjecucion.idProyeccion = this.referenciaSubdivsionesFCSeleccionada.idProyeccion;
      else delete this.datosEjecucion.idProyeccion;

      let dispatch = REGISTRAR_EJECUCION_ACTIVIDAD_CNS;

      if (this.accionSubdivicionFC === 2)
        dispatch = ACTUALIZAR_EJECUCION_ACTIVIDAD_CNS;

      this.datosEjecucion.aporteFuenteCooperante = parseFloat(
        Number(this.datosEjecucion.aporteFuenteCooperante).toFixed(2)
      );
      this.btnRegistroEjecucionActividadLoading = true;
      await this.$store
        .dispatch(dispatch, { datos: this.datosEjecucion })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            // let idEjecucionRegistrado=res.data;
            //Validar que se haya seleccionado si tampien tiene aporte a contrapartida
            if (
              this.aportaContrapartida === 1 &&
              this.contraPartidaRegistrada
            ) {
              this.agregarProyeccionSubDivisionContrapartida(1);
              this.resetItemsSubdivision();
            } else {
              this.btnRegistroEjecucionActividadLoading = false;
              this.obtenerEjecucionesPorActividad(this.id);
              this.resetItemsSubdivision();
            }
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.btnRegistroEjecucionActividadLoading = false;
          }
        })
        .catch((error) => {
          this.btnRegistroEjecucionActividadLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });

      /*
            this.proyeccionesSubDivision.push({
                nombreSubdivision: this.subDivision.nombreSubDivision,
                descripcionSubdivision: this.descripcionSubdivision,
                aporteFuenteCooperante: this.aporteFuenteCooperante
            });


            //Si aporta a contrapartida, registrarla
            if(this.aportaContrapartida===1){
                this.proyeccionesSubDivisionContrapartida.push({
                    nombreSubdivision: this.subDivision.nombreSubDivision,
                    descripcionSubdivision: this.descripcionSubdivision,
                    aporteFuenteCooperante: this.aporteContrapartida
                });
            }


            this.subDivision = {};
            this.descripcionSubdivision = '';
            this.aporteFuenteCooperante = '';
            this.aporteContrapartida = '';
            this.aportaContrapartida=2;*/
    },

    //eliminar distribucion fuente cooperante
    async eliminarDistribucionCooperante(id) {
      this.bntEliminarDistribucionCooperanteDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_EJECUCION_ACTIVIDAD_CNS, id)
        .then((res) => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesPorActividad(this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.bntEliminarDistribucionCooperanteDisabled = false;
        })
        .catch((error) => {
          this.bntEliminarDistribucionCooperanteDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //eliminar distribucion fuente cooperante
    async eliminarDistribucionContrapartida(id) {
      this.btnEliminarDistribucionContrapartidaDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_EJECUCION_CONTRAPARTIDA_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesPorActividadContrapartida(this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarDistribucionContrapartidaDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarDistribucionContrapartidaDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Agregar proyeccion subdivision contrapartida
    async agregarProyeccionSubDivisionContrapartida(registroFuenteCooperante) {
      this.datosEjecucionContraPartida.proyectosCNSId = this.id;
      let datosRegistroEjecucionContrapartida = {};
      if (registroFuenteCooperante === 1) {
        //let suma = parseFloat(this.totalSubDivisionContrapartida) + parseFloat(datosRegistroEjecucionContrapartida.monto);
        let suma =
          parseFloat(this.totalSubDivisionContrapartida.toFixed(2)) +
          parseFloat(
            Number(this.datosEjecucionContraPartidaCooperante.monto).toFixed(2)
          );

        /* console.log(typeof parseFloat(Number(this.datosEjecucionContraPartidaCooperante.monto).toFixed(2)));
                console.log(parseFloat(Number(this.datosEjecucionContraPartidaCooperante.monto).toFixed(2)));
                console.log(typeof suma);
                console.log(suma);
                console.log(typeof parseFloat(this.totalDatosFinancierosCP.toFixed(2)))
                console.log(parseFloat(this.totalDatosFinancierosCP.toFixed(2)))  */

        //Validar que la cantidad que se está registrando no sobrepase lo suscrito
        if (suma > parseFloat(this.totalDatosFinancierosCP.toFixed(2))) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `La cantidad que está registrando, sobrepasa el monto suscrito para la contrapartida (${this.totalDatosFinancierosCP})`
          );
          return;
        }
        datosRegistroEjecucionContrapartida.actividadesEjecucionId = this.datosEjecucion.actividadesEjecucionId;
        datosRegistroEjecucionContrapartida.descripcion = this.datosEjecucion.descripcion;
        datosRegistroEjecucionContrapartida.proyectosCNSId = this.id;
        datosRegistroEjecucionContrapartida.usuarioCreacion = "Admin";
        datosRegistroEjecucionContrapartida.estadosId = 1;
        datosRegistroEjecucionContrapartida.monto = parseFloat(
          Number(this.datosEjecucionContraPartidaCooperante.monto).toFixed(2)
        );
      } else {
        // let suma = parseFloat(this.totalSubDivisionContrapartida) + parseFloat(this.datosEjecucionContraPartida.monto);
        // let suma = parseFloat(this.totalSubDivisionContrapartida.toFixed(2)) + parseFloat(Number(this.datosEjecucionContraPartida.monto).toFixed(2));

        let suma = 0;

        if (this.accionSubdivicionCP === 1)
          suma =
            parseFloat(this.totalSubDivisionContrapartida.toFixed(2)) +
            parseFloat(
              Number(this.datosEjecucionContraPartida.monto).toFixed(2)
            );
        else
          suma =
            parseFloat(this.totalSubDivisionContrapartida.toFixed(2)) -
            parseFloat(this.montoSubdivisionCPAnterior.toFixed(2)) +
            parseFloat(
              Number(this.datosEjecucionContraPartida.monto).toFixed(2)
            );

        //console.log(suma);

        /* console.log(typeof parseFloat(Number(this.datosEjecucionContraPartida.monto).toFixed(2)));
                console.log(parseFloat(Number(this.datosEjecucionContraPartida.monto).toFixed(2)));
                console.log(typeof suma);
                console.log(suma);
                console.log(typeof parseFloat(this.totalDatosFinancierosCP.toFixed(2)))
                console.log(parseFloat(this.totalDatosFinancierosCP.toFixed(2)))  */

        //Validar que la cantidad que se está registrando no sobrepase lo suscrito
        if (suma > parseFloat(this.totalDatosFinancierosCP.toFixed(2))) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `La cantidad que está registrando, sobrepasa el monto suscrito para la contrapartida (${parseFloat(
              this.totalDatosFinancierosCP
            ).toLocaleString("es-GT", {
              style: "currency",
              currency: "GTQ",
              maximumFractionDigits: this.maxDigits,
            })})`
          );
          return;
        }
        datosRegistroEjecucionContrapartida = this.datosEjecucionContraPartida;
        this.datosEjecucionContraPartida.monto = parseFloat(
          Number(this.datosEjecucionContraPartida.monto).toFixed(2)
        );

        datosRegistroEjecucionContrapartida.monto = parseFloat(
          Number(this.datosEjecucionContraPartida.monto).toFixed(2)
        );

        //Agregar la referencia del sigeaci si es seleccionada
        if (this.referenciaSubdivsionesCPSeleccionada)
          datosRegistroEjecucionContrapartida.idProyeccion = this.referenciaSubdivsionesCPSeleccionada.idProyeccion;
        else delete datosRegistroEjecucionContrapartida.idProyeccion;
      }

      this.btnRegistroEjecucionActividadContraPartidaLoading = true;

      let dispatch = REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS;

      if (this.accionSubdivicionCP === 2)
        dispatch = ACTUALIZAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS;

      await this.$store
        .dispatch(dispatch, { datos: datosRegistroEjecucionContrapartida })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //Validar si el registro viene desde fuente cooperante para obtener las proyecciones de FC
            if (registroFuenteCooperante === 1) {
              this.obtenerEjecucionesPorActividad(this.id);
            }
            this.obtenerEjecucionesPorActividadContrapartida(this.id);
            //this.resetItemsEjecucion();
            this.resetItemsEjecucionContrapartida();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.btnRegistroEjecucionActividadLoading = false;
          }
          this.btnRegistroEjecucionActividadLoading = false;
          this.btnRegistroEjecucionActividadContraPartidaLoading = false;
        })
        .catch((error) => {
          this.btnRegistroEjecucionActividadLoading = false;
          this.btnRegistroEjecucionActividadContraPartidaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    ///Agregar monto suscrito por departamento, municipio y actividad (Subdivisión)
    async agregarMontoSubdivisionDeptoMuni() {
      // console.log(this.datosSubdivisionTerritorio);
      this.btnMontoSubdivisionLoading = true;

      this.datosSubdivisionTerritorio.proyectosCNSId = this.id;

      this.datosSubdivisionTerritorio.ejecucionesPorActividadCNSId = this.ejecucionActividadTerritorioSeleccionada.id;

      //console.log(this.montosDepartamento);

      //console.log(this.datosSubdivisionTerritorio.departamentosId)
      //Validar que el departamento y municipio se encuentren en los datos de territorio
      var buscarDepto = this.montosDepartamento.find(
        (item) =>
          item.departamentosId ===
          this.datosSubdivisionTerritorio.departamentosId
      );

      if (buscarDepto == null) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El departamento que intenta registrar no se encuentra en la distribución territorial1.`
        );
        this.btnMontoSubdivisionLoading = false;
        return;
      }

      let suma =
        parseFloat(this.totalEjecucionActividadTerritorio.toFixed(2)) +
        parseFloat(
          Number(this.datosSubdivisionTerritorio.montoQuetzales).toFixed(2)
        );

      //Validar que la cantidad que se está registrando no sobrepase lo suscrito en subdivisión cooperante
      if (suma > parseFloat(this.totalSubDivisionCooperante.toFixed(2))) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que está registrando, sobrepasa el monto suscrito de subdivisión de la fuente cooperante (${this.totalSubDivisionCooperante})`
        );
        this.btnMontoSubdivisionLoading = false;
        return;
      }

      //Verificar municipiosid
      if (
        this.datosSubdivisionTerritorio.municipiosId === null ||
        this.datosSubdivisionTerritorio.municipiosId === 0
      ) {
        delete this.datosSubdivisionTerritorio.municipiosId;

        //Validar que el departamento y municipio se encuentren en los datos de territorio
        var buscarSoloDepto = this.montosDepartamento.filter(
          (item) =>
            item.departamentosId ===
              this.datosSubdivisionTerritorio.departamentosId &&
            (!item.municipiosId || item.municipiosId == null)
        );

        if (buscarSoloDepto == null) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El departamento que intenta registrar no se encuentra en la distribución territorial2.`
          );
          this.btnMontoSubdivisionLoading = false;
          return;
        }
      } else {
        //Validar que el municipio se encuentre en territorio
        //Validar que el departamento y municipio se encuentren en los datos de territorio
        var buscarMuni = this.montosDepartamento.find(
          (item) =>
            item.municipiosId === this.datosSubdivisionTerritorio.municipiosId
        );

        if (buscarMuni == null) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El municipio que intenta registrar no se encuentra en la distribución territorial3.`
          );
          this.btnMontoSubdivisionLoading = false;
          return;
        }
      }

      //this.datosSubdivisionTerritorio.monto = parseFloat(Number(this.datosSubdivisionTerritorio.montoQuetzales).toFixed(2));

      let registroDatosSubdivisionTerritorio = this.datosSubdivisionTerritorio;
      registroDatosSubdivisionTerritorio.montoQuetzales = parseFloat(
        Number(this.datosSubdivisionTerritorio.montoQuetzales).toFixed(2)
      );

      await this.$store
        .dispatch(REGISTRAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS, {
          datos: registroDatosSubdivisionTerritorio,
        })
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.resetItemsActividadTerritorio();
            registroDatosSubdivisionTerritorio = {};
            this.obtenerEjecucionesPorActividadDeptoMuni(this.id);
            this.$emit("actualizar-ejecucion-subdivsion-cobertura");
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnMontoSubdivisionLoading = false;
        })
        .catch((error) => {
          this.btnMontoSubdivisionLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //eliminar ejecución por subdivisión de departamento y municipio
    async eliminarSubdivisionTerritorio(id) {
      this.btnEliminarSubdivisionTerritorioDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS, id)
        .then((res) => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesPorActividadDeptoMuni(this.id);
            //this.resetItemsDesembolsos();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarSubdivisionTerritorioDisabled = false;
        })
        .catch((error) => {
          this.btnEliminarSubdivisionTerritorioDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.montoLatitud = e.latLng.lat().toString();
      this.montoLongitud = e.latLng.lng().toString();
      //  console.log(e);
    },
  },

  watch: {
    montoMonedaOriginal: function() {
      this.datosFinancieros.montoTotalQuetzales = parseFloat(
        this.montoMonedaOriginal * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    },

    tipoCambio: function() {
      this.datosFinancieros.montoTotalQuetzales = parseFloat(
        this.montoMonedaOriginal * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    },

    contraPartidaRegistrada: function() {
      !this.contraPartidaRegistrada
        ? (this.aportaContrapartida = 2)
        : (this.aportaContrapartida = 2);
    },

    totalDistribucionMontos: function() {
      parseFloat(this.totalDistribucionMontos.toFixed(2)) >
      parseFloat(this.totalDatosFinancierosFC.toFixed(2))
        ? (this.alertaTotalDistribucion = true)
        : (this.alertaTotalDistribucion = false);
    },

    totalDatosFinancierosFC: function() {
      parseFloat(this.totalDistribucionMontos.toFixed(2)) >
      parseFloat(this.totalDatosFinancierosFC.toFixed(2))
        ? (this.alertaTotalDistribucion = true)
        : (this.alertaTotalDistribucion = false);
    },
  },

  created() {
    this.resetItems(1, this.id);
  },

  computed: {
    headersMecanismosEjecucion() {
      return [
        {
          text: "Mecanismo",
          align: "start",
          sortable: false,
          value: "nombreMecanismo",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "",
        },
      ];
    },

    headersDatosFinancieros() {
      return [
        {
          text: "Datos Financieros",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Moneda",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Monto Original",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Monto total en quetzales",
          align: "start",
          sortable: true,
          value: "montoTotalQuetzales",
        },
        {
          text: "Acción",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersMontoSuscrito() {
      return [
        {
          text: "Datos Financieros",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Monto Moneda original",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersProyeccionDesembolsos() {
      return [
        {
          text: "Año",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Tipo de desembolso",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Monto Moneda original",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: true,
          value: "tipoCambio",
        },
        {
          text: "Monto Total Quetzales",
          align: "start",
          sortable: true,
          value: "montoTotalQuetzales",
        },
        /* {
                    text: "Forma de operar",
                    align: "start",
                    sortable: true,
                    value: "nombreMoneda"
                },*/
        {
          text: "Accion",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersDistribucion() {
      return [
        {
          text: "Datos financieros",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Monto Moneda original",
          align: "start",
          sortable: true,
          value: "montoTotalMonedaOriginal",
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Monto total Quetzales",
          align: "start",
          sortable: false,
          value: "montoTotalQuetzales",
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersMontoDepartamento() {
      return [
        {
          text: "Ubicación",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Latitud y Longitud",
          align: "start",
          sortable: false,
          value: "latitud",
        },
        {
          text: "Monto GTQ",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        /* {
                    text: "Tipo de Cambio",
                    align: "start",
                    sortable: true,
                    value: "tipoCambio"
                },
                {
                    text: "Monto Total Quetzales",
                    align: "start",
                    sortable: true,
                    value: "montoTotalQuetzales"
                },*/
        {
          text: "Accion",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersSubDivisionesCooperante() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
        {
          text: "Monto",
          align: "center",
          sortable: true,
          value: "monto",
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersSubDivisionesContrapartida() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          //divider: true,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Descripcion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Monto",
          align: "center",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
      ];
    },

    headersEjecucionSubdivisionTerritorio() {
      return [
        {
          text: "Actividad",
          align: "start",
          sortable: false,
          //divider: true,
          value: "nombreTipoDatoFinanciero",
        },
        {
          text: "Municipio y Departamento",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Monto Quetzales",
          align: "center",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
        {
          text: "Accion",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda",
        },
      ];
    },
  },
};
</script>
<style lang=""></style>