<template>
<div>
  <!--begin:: dialog registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1200px"
      transition="scroll-y-transition"
      scrollable
      persistent
    >
      <v-card tile>
        <v-card-title>
          {{accion===1 ? `Registro de proyecto` : `Actualización de información | ${proyectoCodigo}`}}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false; resetForm()"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
            touchless
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Datos principales
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <!-- <v-tab href="#tab-2" :disabled="accion===1">
              Información Adicional
              <v-icon>mdi-plus</v-icon>
            </v-tab> -->

            <!--<v-tab href="#tab-3" >
              Cobertura
              <v-icon>mdi-map-outline</v-icon>
            </v-tab> -->
          </v-tabs>
          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-tabs-items v-model="tab" touchless>
              <v-tab-item :value="'tab-1'">
                  <div v-if="skeletonLoading">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-skeleton-loader
                            min-height="600px"
                            type="article, article, article, actions"
                            >
                            </v-skeleton-loader>
                    </v-col>
                    </v-row>
                </div>

                <div v-if="!skeletonLoading">

                  <v-card flat>

                    <v-card-title>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <span class="card-label text-h5 font-weight-bolder text-dark">
                                  Registro de datos Principales del proyecto  </span
                                ><!--<br />
                                <span class="text-muted mt-3 font-weight-bold font-size-md">
                                    *El aporte nacional será ejecutado en su totalidad por la Unidad Ejecutora
                                </span>-->
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="d-none">
                                <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-form 
                          ref="formProyeccionDesembolsos"
                          v-on:submit.prevent="registrarDatosGenerales"
                          v-model="validFormDatosGenerales"
                          >
                          
                            <v-row>


                              <v-col cols="12" md="6" sm="12" class="pt-4 pb-0">
                                <v-text-field
                                  dense
                                  filled
                                  autocomplete="off"
                                  class=""
                                  color="blue-grey lighten-2"
                                  v-model="datosItem.refSigeaci"
                                  label="Código SIGEACI"
                                  :rules="[
                                    maxLength('Código SIGEACI', 20)
                                  ]"
                                  maxlength="20"
                                  hint="Completar si el proyecto se encuentra registrado en SIGEACI"
                                ></v-text-field>
                              </v-col>


                              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                <v-textarea
                                  dense
                                  filled
                                  rows="3"
                                  autocomplete="off"
                                  class="required"
                                  color="blue-grey lighten-2"
                                  v-model="datosItem.nombreProyecto"
                                  placeholder="Describa el nombre del proyecto"
                                  label="Nombre del proyecto"
                                  :rules="[
                                    required('nombre del proyecto'),
                                    minLength('nombre del proyecto', 5),
                                    maxLength('nombre del proyecto', 500)
                                  
                                  ]"
                                  maxlength="500"
                                ></v-textarea>
                              </v-col>

                              


                              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                <v-menu
                                  ref="menuFechaOt"
                                  v-model="menuFechaOt"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      dense
                                      filled
                                      color="blue-grey lighten-2"
                                      v-model="computedDateFormatted"
                                      label="Fecha de solicitud de la opinión técnica"
                                      hint="DD/MM/AAAA"
                                      persistent-hint
                                      append-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="[
                                        required('Fecha de solicitud de la opinión técnica'),
                                      ]"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    no-title
                                    @input="menuFechaOt = false"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    locale="es"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>


                            </v-row>

                             <v-card class="mt-6" outlined>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                                            <v-subheader class="text-h6 black--text">
                                                Datos de la institución
                                            </v-subheader>
                                        </v-col>
                                    </v-row>

                                <v-row>

                                  <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                      <v-select
                                          v-model="tipoInstitucionSeleccionada"
                                          :items="tiposInstitucion"
                                          :loading="ddTiposInstitucionLoading"
                                          dense
                                          filled
                                          class="required"
                                          label="Tipo de institución"
                                          item-text="nombreTipo"
                                          item-value="id"
                                          :no-data-text="
                                              tiposInstitucion != null
                                              ? 'Selecciona un tipo de institución'
                                              : 'No se han encontrado tipos de institución'
                                          "
                                          menu-props="auto"
                                          @change="obtenerInstituciones(tipoInstitucionSeleccionada)"
                                          >
                                      </v-select>
                                  </v-col> -->

                                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                    <v-autocomplete
                                      v-model="institucionSeleccionada"
                                      :items="instituciones"
                                      :loading="ddInstitucionesLoading"
                                      dense
                                      filled
                                      class="required"
                                      label="Institución"
                                      :item-text="item => item.sigla ? `${item.nombreInstitucion} (${item.sigla})` : item.nombreInstitucion"
                                      item-value="id"
                                      return-object
                                      :no-data-text="
                                        instituciones != null
                                          ? 'Selecciona una institución'
                                          : 'No se han encontrado instituciones'
                                      "
                                      @change="obtenerUnidadesEjecutorasPorInstitucion(institucionSeleccionada.id);"
                                      menu-props="auto"
                                      :rules="[ selectRequired('institución') ]"
                                    ></v-autocomplete>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0"> <!--v-if="mostrarUnidadesEjecutoras"-->
                                    <v-select
                                      v-model="unidadEjecutoraSeleccionada"
                                      :items="unidadesEjecutoras"
                                      :loading="unidadesEjecutorasLoading"
                                      dense
                                      filled
                                      class="required"
                                      label="Unidad Ejecutora"
                                      item-text="unidadEjecutora"
                                      item-value="id"
                                      return-object
                                      :rules="[
                                        selectRequired('unidad ejecutora'),
                                      ]"
                                      :no-data-text="
                                        unidadesEjecutoras.length > 0
                                          ? 'Selecciona una unidad ejecutora'
                                          : 'No se han encontrado unidades ejecutoras'
                                      "
                                      menu-props="auto"
                                    ></v-select>
                                  </v-col>

                                </v-row>
                                </v-card-text>
                             </v-card>


                             <v-card class="mt-6" outlined>
                              <v-card-text>
                                <v-row>
                                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                    <v-subheader class="text-h6 font-weight-bolder text-dark">
                                      Fuente cooperante
                                    </v-subheader>
                                    <v-divider class="mt-0 pt-0"></v-divider>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                    <v-select
                                      v-model="tipoFuenteCooperanteSeleccionada"
                                      :items="tiposFuenteCooperante"
                                      :loading="false"
                                      dense
                                      filled
                                      class="required"
                                      label="Tipo"
                                      item-text="nombreTipoFuente"
                                      item-value="id"
                                      return-object
                                      :rules="[selectRequired('nombre del tipo fuente cooperante')]"
                                      :no-data-text="
                                        tiposFuenteCooperante != null
                                          ? 'Selecciona un tipo de fuente cooperante'
                                          : 'No se han encontrado tipos de fuente cooperante'
                                      "
                                      @change="configurarFuentes()"
                                      menu-props="auto"
                                    ></v-select>
                                  </v-col>
                                </v-row>

                                <v-row>

                                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 1">
                                    <v-select
                                      v-model="fuenteBilateralSeleccionada"
                                      :items="fuentesBilaterales"
                                      :loading="false"
                                      :required="tipoFuenteCooperanteSeleccionada.id == 1"
                                      dense
                                      :class="tipoFuenteCooperanteSeleccionada.id == 1 ? 'required' : ''"
                                      filled
                                      label="País Fuente Bilateral"
                                      item-text="nombre"
                                      item-value="id"
                                      return-object
                                      :rules="[
                                        tipoFuenteCooperanteSeleccionada.id === 1 ? selectRequired('país bilateral') : true,
                                      ]"
                                      :no-data-text="
                                        fuentesBilaterales != null
                                          ? 'Selecciona una institución ejecutora'
                                          : 'No se han encontrado unidades ejecutoras'
                                      "
                                      @change="obtenerAgenciasPorFuenteBilateral()"
                                      menu-props="auto"
                                    >                                    
                                    </v-select>

                                  </v-col>

                                  <!-- inicio:: agencias -->
                                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 1">
                                    <v-select
                                      v-model="agenciaSeleccionada"
                                      :items="agencias"
                                      :loading="ddAgenciasLoading"
                                      dense
                                      filled
                                      label="Seleccione Agencia"
                                      item-text="nombreAgencia"
                                      item-value="id"
                                      return-object
                                      :no-data-text="
                                        agencias > 0
                                          ? 'Selecciona una agencia'
                                          : 'No se han encontrado agencias'
                                      "
                                      menu-props="auto"
                                    ></v-select>
                                  </v-col>
                                  <!-- fin:: agencias -->

                                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 2">
                                    <v-select
                                      v-model="fuenteMultilateralSeleccionada"
                                      :items="fuentesMultilateral"
                                      :loading="false"
                                      dense
                                      filled
                                      label="Seleccione fuente multilateral"
                                      item-text="nombreFuenteMultilateral"
                                      item-value="id"
                                      return-object
                                      :no-data-text="
                                        fuentesMultilateral != null
                                          ? 'Seleccione una fuente multilateral'
                                          : 'No se han encontrado fuentes multilaterales'
                                      "
                                      menu-props="auto"
                                      @change="obtenerAgenciasMultilaterales(fuenteMultilateralSeleccionada.id)"
                                    ></v-select>
                                  </v-col>

                                  <!-- inicio:: agencias -->
                                  <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteCooperanteSeleccionada.id == 2">
                                    <v-select
                                      v-model="agenciaMultilateralSeleccionada"
                                      :items="agenciasMultilaterales"
                                      :loading="ddAgenciasMultiLoading"
                                      dense
                                      filled
                                      label="Seleccione Agencia Multilateral"
                                      item-text="agencia"
                                      item-value="id"
                                      return-object
                                      :no-data-text="
                                        agenciasMultilaterales > 0
                                          ? 'Selecciona una agencia'
                                          : 'No se han encontrado agencias para la fuente seleccionada'
                                      "
                                      menu-props="auto"
                                    ></v-select>
                                  </v-col>
                                  <!-- fin:: agencias -->
                                </v-row>
                              </v-card-text>
                            </v-card>

                            <v-row class="mt-6">

                              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                <v-select
                                  v-model="instrumentoSeleccionado"
                                  :items="instrumentos"
                                  :loading="false"
                                  dense
                                  filled
                                  class="required"
                                  label="Instrumento"
                                  item-text="instrumento"
                                  item-value="id"
                                  return-object
                                  @change="obtenerTiposInstrumentos"
                                  :no-data-text="
                                    instrumentos != null
                                      ? 'Selecciona un instrumento'
                                      : 'No se han encontrado instrumentos'
                                  "
                                  menu-props="auto"
                                ></v-select>
                              </v-col>

                              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="!instrumentoSeleccionado.id || (instrumentoSeleccionado && instrumentoSeleccionado.id !=1)">
                                <v-select
                                  v-model="tipoInstrumentoSeleccionado"
                                  :items="tiposInstrumentos"
                                  :loading="ddTiposInstrumentoLoading"
                                  dense
                                  filled
                                  :class="instrumentoSeleccionado.id!=1 ? `required` : ``"
                                  label="Tipo de Instrumento"
                                  item-text="nombreTipoInstrumento"
                                  item-value="id"
                                  return-object
                                  :no-data-text="
                                    tiposInstrumentos != null
                                      ? 'Selecciona un tipo de instrumento'
                                      : 'No se han encontrado tipos instrumentos'
                                  "
                                  menu-props="auto"
                                ></v-select>
                              </v-col>

                            </v-row>

                            <v-row>

                              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                <v-select
                                  v-model="modalidadEjecucionSeleccionada"
                                  :items="modalidadesEjecucion"
                                  :loading="false"
                                  dense
                                  filled
                                  class="required"
                                  label="Modalidad de ejecución"
                                  item-text="nombreModalidad"
                                  item-value="id"
                                  return-object
                                  :no-data-text="
                                    modalidadesEjecucion != null
                                      ? 'Seleccione una modalidad'
                                      : 'No se han encontrado modalidades de ejecución'
                                  "
                                  menu-props="auto"
                                ></v-select>
                              </v-col>

                              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                  <v-select
                                    v-model="tipoCooperacionSeleccionada"
                                    :items="tiposCooperacion"
                                    :loading="false"
                                    dense
                                    filled
                                    class="required"
                                    label="Tipo de cooperación"
                                    item-text="nombreTipoCooperacion"
                                    item-value="id"
                                    return-object
                                    :no-data-text="
                                      tiposCooperacion != null
                                        ? 'Seleccione un tipo de cooperación'
                                        : 'No se han encontrado tipos de cooperación'
                                    "
                                    menu-props="auto"
                                  ></v-select>
                                </v-col>
                            </v-row>

                          

                           

                            <v-card class="mt-6" outlined>
                              <v-card-text>

                                <v-row>
                                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                    <v-subheader class="text-h6 font-weight-bolder text-dark">
                                      Sector que beneficia
                                    </v-subheader>
                                  </v-col>

                                  <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mt-2">
                                    <v-select
                                      v-model="sectoresBeneficiadosSeleccionado"
                                      :items="sectoresBeneficiados"
                                      :loading="false"
                                      dense
                                      class="required"
                                      filled
                                      label="Seleccione el sector principal que beneficia"
                                      item-text="sector"
                                      item-value="id"
                                      return-object
                                      :no-data-text="
                                        sectoresBeneficiados != null
                                          ? 'Seleccione un sector beneficiado'
                                          : 'No se han encontrado sectores beneficiado'
                                      "
                                      menu-props="auto"
                                    ></v-select>
                                  </v-col>
                                </v-row>

                              </v-card-text>
                            </v-card>
                          
                            

                          
                            <v-card class="mt-6 elevation-0" outlined>
                                <v-card-text>

                                  <v-row>
                                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                      <v-subheader class="text-h6 font-weight-bolder text-dark">
                                        Documento de opinión técnica/justificación de proyecto
                                      </v-subheader>
                                    </v-col>
                                  </v-row>

                                  <v-row>

                                    <v-col cols="12" md="6" sm="12"  class="pt-2 pb-0">
                                        <v-select
                                            v-model="cuentaOpinionTecnica"
                                            :items="cuentaOpinionTecniaItems"
                                            :loading="false"
                                            dense
                                            filled
                                            class="required"
                                            return-object   
                                            label="¿El proyecto cuenta con opinión técnica?"
                                            item-text="text"
                                            item-value="id"
                                            menu-props="auto"
                                        ></v-select>
                                    </v-col>
                                 
                                  
                                    <v-col cols="12" md="6" sm="12" xs="12" class="pt-2 pb-0">
                                      <template>
                                        <v-file-input
                                          id="respaldoFile"
                                          dense
                                          filled
                                          :placeholder="cuentaOpinionTecnica.id === 1 ? `Seleccione pdf que avala la opinión técnica` : cuentaOpinionTecnica.id === 2 ? `Seleccione el documento de justificación del proyecto` : `Seleccione un tipo de documento de respaldo del proyecto`"
                                          :label="cuentaOpinionTecnica.id === 1 ? `Documento que avala la opinión técnica` : cuentaOpinionTecnica.id === 2 ? `Documento de justificación del proyecto` : `Seleccione un tipo de documento de respaldo.`"
                                          append-icon="mdi-file-pdf-outline"
                                          prepend-icon=""
                                          accept="application/pdf,image/jpeg"
                                          @change="onFileChange"
                                          :rules="[
                                            
                                            accion===1 ? fileRequired('Documento de respaldo') : true
                                            
                                            ]"
                                          :show-size="1000"
                                          ref="respaldoFile"
                                          :class="accion===1 ? `required` : ``"
                                          :disabled="cuentaOpinionTecnica.id === 0"
                                        >
                                          <template v-slot:selection="{ index, text }">
                                            <v-chip
                                              v-if="index < 2"
                                              small
                                              label
                                              color="primary"
                                            >
                                              {{ text }}
                                            </v-chip>
                                            <span
                                              v-else-if="index === 2"
                                              class="
                                                text-overline
                                                grey--text
                                                text--darken-3
                                                mx-2
                                              "
                                            >
                                              +{{ files.length - 2 }} archivo(s)
                                            </span>
                                          </template>
                                        </v-file-input>
                                      </template>
                                    </v-col>

                                    <v-col cols="12" md="4" sm="5" xs="12" class="pt-6 pb-6" v-if="documentoOpinionTecnicaRegistrado==true && accion===2">
                                    <v-btn
                                      color="light-blue-502"
                                      class="mb-1 float-right white--text"
                                      depressed
                                      @click="descargarArchivo(datosItem.pathDocumentoOpinionTecnica)"
                                      >
                                      <v-icon left>mdi-download</v-icon> Descargar
                                    </v-btn>
                                                 
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                            </v-card>


                            <v-card class="mt-6" outlined>

                              <v-card-text>
                                  <v-row>
                                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                      <v-subheader class="text-h6 font-weight-bolder text-dark">
                                          Datos de quien solicita
                                      </v-subheader>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                      <v-text-field
                                        dense
                                        filled
                                        autocomplete="off"
                                        class="required"
                                        color="blue-grey lighten-2"
                                        v-model="datosItem.nombreSolicitante"
                                        label="Nombre de quien solicita"
                                        :rules="[
                                          required('nombre de quien solicita'),
                                          minLength('Nombre de quien solicita',5),
                                          maxLength('nombre de quien solicita',100)
                                        ]"
                                        maxlength="100"
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                      <v-text-field
                                        dense
                                        filled
                                        autocomplete="off"
                                        class="required"
                                        color="blue-grey lighten-2"
                                        v-model="datosItem.correoElectronico"
                                        label="Correo electrónico del solicitante"
                                        :rules="[
                                          required('correo electrónico del solicitante'),
                                          minLength('correo electrónico del solicitante',5),
                                          maxLength('correo electrónico del solicitante',150),
                                          validEmail('correo electrónico del solicitante')
                                        ]"
                                        maxlength="150"
                                      ></v-text-field>
                                    </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>

                            
                            <v-row class="mt-4">
                                  <v-col cols="12">
                                    <v-btn
                                          color="light-blue-502"
                                          class="mb-1 float-right white--text"
                                          type="submit"
                                          :elevation="0"
                                          :disabled="!validFormDatosGenerales"
                                          :loading="btnDatosGeneralesLoading"
                                      >
                                          {{btnDatosPrincipalesText}}
                                      </v-btn>
                                  </v-col>
                                </v-row>
                          
                        </v-form>
                      </v-card-text>
                    </v-card>
                </div>
              </v-tab-item>

              <v-tab-item :value="'tab-2'" >
                <v-card flat>
                  <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <span class="card-label text-h5 font-weight-bolder text-dark">
                              Información adicional del proyecto
                            </span>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-divider class="mt-0 pt-3 pb-4"></v-divider>
                        </v-col>
                    </v-row>
                </v-card-title>
                  <v-card-text>
                  <v-form 
                    ref="formProyeccionDesembolsos"
                    v-on:submit.prevent="registrarInformacionGeneral"
                    v-model="validFormInformacionAdicional"
                    >
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                              <v-textarea
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosItemInformacionGeneral.descripcionProyecto"
                                label="Descripción del proyecto"
                                :rules="[
                                  required('descripción del proyecto'),
                                  minLength('descripción del proyecto',5),
                                  maxLength('descripción del proyecto',150),
                                ]"
                                rows="4"
                                maxlength="150"
                              ></v-textarea>
                            </v-col>

                            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                              <v-textarea
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                v-model="datosItemInformacionGeneral.objetivoGeneral"
                                label="Objetivo general del proyecto"
                                rows="6"
                                maxlength="150"
                              ></v-textarea>
                            </v-col>

                            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                              <v-textarea
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                v-model="datosItemInformacionGeneral.objetivoEspecifico1"
                                label="Objetivo específico 1"
                                rows="4"
                                maxlength="150"
                              ></v-textarea>
                            </v-col>

                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                              <v-textarea
                                dense
                                filled
                                autocomplete="off"
                                v-model="datosItemInformacionGeneral.objetivoEspecifico2"
                                label="Objetivo Específico 2"
                                rows="4"
                                maxlength="150"
                              ></v-textarea>
                            </v-col>
                          
                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                              <v-textarea
                                dense
                                filled
                                autocomplete="off"
                                v-model="datosItemInformacionGeneral.objetivoEspecifico3"
                                label="Objetivo Específico 3"
                                rows="4"
                                maxlength="150"
                              ></v-textarea>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12">
                              <v-btn
                                    color="light-blue-502"
                                    class="white--text mb-2 float-right mt-2"
                                    type="submit"
                                    :elevation="0"
                                    :disabled="!validFormInformacionAdicional"
                                    :loading="btnInformacionAdicionalLoading"
                                >
                                    Guardar información adicional
                                </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item :value="'tab-3'" class="d-none">
                <v-card flat>
                  <v-card-text>
                    <v-container>
                      <v-card class="mt-6" outlined>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                              <v-subheader class="text-h6 text-uppercase">
                                Cobertura territorial
                              </v-subheader>
                              <v-divider class="mt-0 pt-0"></v-divider>
                            </v-col>
                       

                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                              <v-select
                                v-model="departamentoSeleccionado"
                                :items="departamentos"
                                :loading="false"
                                dense
                                filled
                                label="Departamento"
                                item-text="nombre"
                                item-value="id"
                                return-object
                                @change="obtenerMunicipiosPorDepartamento()"
                                :no-data-text="
                                  unidadesEjecutoras != null
                                    ? 'Selecciona una unidad ejecutora'
                                    : 'No se han encontrado unidades ejecutoras'
                                "
                                menu-props="auto"
                              ></v-select>
                            </v-col>

                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                              <v-select
                                v-model="municipioSeleccionado"
                                :items="municipios"
                                :loading="ddMunicipiosLoading"
                                dense
                                filled
                                label="Municipio"
                                item-text="nombre"
                                item-value="id"
                                return-object
                                :no-data-text="
                                  unidadesEjecutoras != null
                                    ? 'Selecciona una unidad ejecutora'
                                    : 'No se han encontrado unidades ejecutoras'
                                "
                                menu-props="auto"
                              ></v-select>
                            </v-col>

                            <v-col sm="12">
                              <v-btn
                                class="mb-2 float-right blue-grey darken-2"
                                medium
                                dark
                                dense
                                filled
                                @click="agregarCobertura"
                                @disabled="!departamentoSeleccionado.id"
                              >
                                <v-icon left>mdi-plus</v-icon> Agregar
                              </v-btn>
                            </v-col>

                            <v-col sm="12">
                              <v-data-table
                                class="elevation-1"
                                :headers="headersCobertura"
                                :items="cobertura"
                                hide-default-footer
                                :footer-props="{
                                  showFirstLastPage: true,
                                  firstIcon: 'mdi-page-first',
                                  lastIcon: 'mdi-page-last',
                                  prevIcon: 'mdi-chevron-left',
                                  nextIcon: 'mdi-chevron-right',
                                  'items-per-page-text': 'Registros por página',
                                  pageText: '{0}-{1} de {2}'
                                }"
                              >
                                <template v-slot:item="{ item }">
                                  <tr>
                                    <td>{{ item.departamento }}</td>
                                    <td>{{ item.municipio }}</td>

                                    <td>
                                      <v-btn
                                        class="
                                          ma-2
                                          btn-bg-light
                                          blue-grey--text
                                          lighten-2--text
                                          font-weight-medium
                                          text-capitalize
                                        "
                                        small
                                        depressed
                                        color="blue-grey lighten-5"
                                        @click="eliminarCobertura(item.municipio)"
                                        >
                                        <v-icon left>mdi-delete</v-icon> Eliminar
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>
        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>
    <!--end:: dialog registro-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>

//import { OBTENER_INSTITUCIONES, OBTENER_INSTITUCION } from "@/core/services/store/instituciones/institucion.module";
import { OBTENER_INSTITUCION } from "@/core/services/store/instituciones/institucion.module";
import { OBTENER_UNIDADES_EJECUTORAS_ENTIDAD } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
//import { OBTENER_UNIDADES_EJECUTORAS_INSTITUCION } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import { OBTENER_ITEMS_CATALOGO, OBTENER_SUBITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import { REGISTRAR_PROYECTO_CNS, ACTUALIZAR_PROYECTO_CNS, OBTENER_DATOS_SECCIONES_PROYECTO, REGISTRAR_PROYECTO_CNS_EXTERNO } from "@/core/services/store/proyectoscns/proyectocns.module";
import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DownloadFile from "@/core/untils/downloadfile.js";

import moment from "moment";
import validations from "@/core/untils/validations.js";

export default {
  name: "ModalRegistroProyectoSinOT",
  components: {
    SnackAlert
    // DialogLoader
  },
  props: ['proyectoId', 'proyectoCodigo', 'accion', 'titulo'],
  data() {
    return {
      fechaActual: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechaOtFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      skeletonLoading: false,
      dialogRegistro: false,
      tab: null,
      tabInformacionAdicional: true,
      validForm: false,
      btnDatosPrincipalesText: "Guardar datos principales",
      validFormDatosGenerales: false,
      validFormInformacionAdicional: false,
      btnDatosGeneralesLoading: false,
      btnInformacionAdicionalLoading: false,
      unidadesEjecutorasLoading: false,
      btnRegistroLoading: false,
      ddMunicipiosLoading: false,
      unidadesEjecutoras: [],
      instituciones: [],
      instrumentos: [],
      instrumentoSeleccionado: {},
      ddTiposInstrumentoLoading: false,
      tiposInstrumentos: [],
      tipoInstrumentoSeleccionado: {},      
      tiposFuenteCooperante: [],
      tipoFuenteCooperanteSeleccionada: {},
      tiposCooperacion: [],
      tipoCooperacionSeleccionada: {},
      fuentesBilaterales: [],
      fuenteBilateralSeleccionada: {},
      ddAgenciasLoading: false,
      agencias: [],
      agenciaSeleccionada: {},      
      agenciasMultilaterales: [],
      agenciaMultilateralSeleccionada:{},
      ddAgenciasMultiLoading: false,
      fuenteMultilateralSeleccionada: {},
      tiposFuenteCooperanteSel: [],
      modalidadEjecucionSeleccionada: [],
      sectoresBeneficiadosSeleccionado: {},
      sectoresBeneficiados: [],
      politicasPublicasSel: [],
      politicasPublicas: [],
      proyectoRegistrado: false,
      fechaSolicitudOpinionTecnica:'',
      institucionSeleccionada:{},
      unidadEjecutoraSeleccionada:{},
      ddInstitucionesLoading: false,
      //fechaOtFormatted: 
      menuFechaOt: false,
      datosItem: {
        refSigeaci: "",
        nombreProyecto: "",
        fechaSolicitudOpinionTecnica: "", //Date
        entidadId: 0,
        unidadEjecutoraId: 0,
        tiposInstrumentoId: 0,
        modalidadEjecucionId: 0,
        tiposCooperacionId: 0,
        fuentesBilateralesId: null,
        tiposFuenteCooperanteId:0,
        agenciasId: null,
        fuentesMultilateralesId: null,
        nombreSolicitante: "",
        correoElectronico: "",
        pathDocumentoOpinionTecnica: "",
        sectoresId: 0,
        estadosId: 1,
        tipoProyectoCNSId: 2, //Sin opinión técnica
      },
      documentoOpinionTecnicaRegistrado:false,
      documentoOtReemplazado:false,
      datosItemInformacionGeneral : {
        id: 0, //Id del proyecto
        descripcionProyecto: '',
        objetivoGeneral: "",
        objetivoEspecifico1: "",
        objetivoEspecifico2: "",
        objetivoEspecifico3: "",
      },
      documentoOpinionTecnica: [],
      municipioSeleccionado: [],
      departamentoSeleccionado: [],
      tiposInstitucion:[],
      ddTiposInstitucionLoading: false,
      mostrarUnidadesEjecutoras: false,
      tipoInstitucionSeleccionada:0,
      cuentaOpinionTecniaItems: [{id: 0, text: 'Seleccione'}, {id: 1, text: 'Si'}, {id: 2, text: 'No'}],
      cuentaOpinionTecnica: {id: 0, text:"Seleccione"},
      ...validations,
      cbxopt: [1, 2],
      radioGroup: 1,
      modalidadesEjecucion: [],
      departamentos: [],
      municipios: [],
      fuentesMultilateral: [],
      cobertura: [],
      archivoExiste: false,
      codigoProyectoSeleccionado: "",
      datosInstitucion:{}
    };
  },
  methods: {

    resetItems(tipo){

      //let today = new Date();
      //var now_date = (today.getDate() + '/' +  (today.getMonth()+1) +  '/' + today.getFullYear());


      var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      var partesFecha = currentDateWithFormat.split('/');
      this.fechaActual = partesFecha[2] + '/' + partesFecha[1] + '/' + partesFecha[0];

     // this.fechaActual = now_date;

      if(tipo===1){
        this.skeletonLoading = true;
      }

      this.cuentaOpinionTecnica= {id: 0, text:"Seleccione"};
      
      //console.log(currentDateWithFormat)

      this.mostrarUnidadesEjecutoras = false;
      this.institucionSeleccionada=null;
      this.unidadEjecutoraSeleccionada=null;
      this.btnDatosPrincipalesText = "Guardar datos principales";
      this.instrumentoSeleccionado= {};
      this.fechaSolicitudOpinionTecnica= "";
      this.modalidadEjecucionSeleccionada= {};
      this.tipoCooperacionSeleccionada = {};
      this.sectoresBeneficiadosSeleccionado= {};
      this.tipoInstrumentoSeleccionado= {};
      this.tipoFuenteCooperanteSeleccionada= {};
      this.fuenteBilateralSeleccionada= {};
      this.agenciaSeleccionada= {};
      this.tipoFuenteCooperanteSeleccionada= {};

      this.unidadesEjecutoras=[];
      this.tiposInstrumentos=[];

      /* this.institucionSeleccionada={};
      this.unidadEjecutoraSeleccionada = {}; */

      this.datosItem= {
        refSigeaci: "",
        nombreProyecto: "",
        fechaSolicitudOpinionTecnica: "", //Date
        unidadEjecutoraId: 0,
        entidadId: 0,
        tiposInstrumentoId: 0,
        modalidadEjecucionId: 0,
        tiposCooperacionId: 0,
        fuentesBilateralesId: null,
        tiposFuenteCooperanteId:0,
        agenciasId: null,
        fuentesMultilateralesId: null,
        nombreSolicitante: "",
        correoElectronico: "",
        pathDocumentoOpinionTecnica: "",
        sectoresId: 0,
        estadosId: 1,
        tipoProyectoCNSId: 2, //sin opinión técnica
      };

      this.datosItemInformacionGeneral={
        descripcionProyecto:"",
        objetivoGeneral:"",
        objetivoEspecifico1:"",
        objetivoEspecifico2:"",
        objetivoEspecifico3:"",
      };

      this.documentoOpinionTecnica=[];
      if(this.$refs.respaldoFile){
        document.querySelector("#respaldoFile").value = "";
        this.$refs.respaldoFile.reset();
      }
    },


    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

    //!Definir la fecha máxima que se puede seleccionar en el datepicker
    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return date.toISOString().substr(0, 10);
    },

    //!Definir la fecha minima que se puede seleccionar en el datepicker
    minDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear());
      return date.toISOString().substr(0, 10);
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow("warning", "Alerta",`El tamaño del archivo excede el límite permitido (10Mb)`);
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        this.documentoOtReemplazado = true;
        this.documentoOpinionTecnica = e;
      }
    },

    async obtenerUnidadesEjecutorasPorInstitucion(institucionesId) {
     // this.datosItem.unidadEjecutoraId=0;
      this.unidadesEjecutorasLoading = true;
      this.unidadesEjecutoras = [];
    

      await this.$store
        .dispatch(OBTENER_UNIDADES_EJECUTORAS_ENTIDAD, institucionesId)
        .then(res => {
          if(res.status===200){
            this.unidadesEjecutoras = res.data;

          }
          this.unidadesEjecutorasLoading = false;
        })
        .catch(() => {
          this.unidadesEjecutorasLoading = false;
        });
    },

    


     async obtenerTiposInstitucion() {
        this.ddTiposInstitucionLoading=true;
        this.tiposInstitucion = [];
        await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposInstitucion/all/1'})
        .then(res => {  
        
            if (res.status === 200) {
            
                this.tiposInstitucion = res.data;
            }
            this.ddTiposInstitucionLoading=false;
            this.skeletonLoading = false;
        })
        .catch(() => {
            this.tiposInstitucion = [];
            this.ddTiposInstitucionLoading=false;
            this.skeletonLoading = false;
        });

    },


     /// Obtener los items
    async obtenerInstituciones(tipoInstitucionId) {
      //this.datosItem.unidadEjecutoraId =0;
      if(tipoInstitucionId===1){
          this.mostrarUnidadesEjecutoras=true;
      } else {
          this.mostrarUnidadesEjecutoras=false;
      }
      this.ddInstitucionesLoading=true;
      
      this.instituciones = [];
      await this.$store                                                       
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Entidades/all/1`})
        .then(res => {
          if(res.status==200){

            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading=false;
          
        })
        .catch(() => {
          this.tableLoading = false;
          this.ddInstitucionesLoading=false;
           this.instituciones=[];
        });
    },

    async obtenerFuentesMultilateral() {
      this.fuentesMultilateral = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuenteMultiLateral/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilateral = res.data;
          }
        })
        .catch(() => {
            this.fuentesMultilateral = [];
        });
    },

    //Obtener las modalidades de ejecución
    async obtenerModalidadesEjecucion() {
       this.modalidadesEjecucion = [];
        await this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'ModalidadEjecucionCNS/all/1'})
          .then(res => {
            if (res.status === 200) {
              this.modalidadesEjecucion = res.data;
            }
          })
          .catch(() => {
              this.modalidadesEjecucion = [];
          });
    },


    async obtenerInstrumentos() {
        this.instrumentos = [];
        await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Instrumentos/all/1'})
        .then(res => {  
         
            if (res.status === 200) {
               
                this.instrumentos = res.data;
            }
        })
        .catch(() => {
            this.instrumentos = [];
        });

    },


    async obtenerTiposInstrumentos() {
        this.ddTiposInstrumentoLoading = true;
        this.tiposInstrumentos = [];
        ///console.log(this.instrumentoSeleccionado.id)
        await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'TiposInstrumento/all/1', id: this.instrumentoSeleccionado.id})
        .then(res => {
          //console.log(res)
            if (res.status === 200) {
                this.tiposInstrumentos = res.data;
            }
            this.ddTiposInstrumentoLoading = false;
        })
        .catch(() => {
            this.tiposInstrumentos = [];
            this.ddTiposInstrumentoLoading = false;
        });

    },

    

    obtenerTiposFuenteCooperante() {
      this.tiposFuenteCooperante = [];

       this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposFuenteCooperante/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.tiposFuenteCooperante = res.data;
          }
        })
        .catch(() => {
            this.tiposFuenteCooperante = [];
        });
    },

    obtenerTiposCooperacion() {
      this.tiposCooperacion = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposCooperacion/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.tiposCooperacion = res.data;
          }
        })
        .catch(() => {
            this.tiposCooperacion = [];
        });
    },

    obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuentesBilaterales/all/1/0'})
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
          }
        })
        .catch(() => {
           this.fuentesBilaterales = [];
        });
    },



    async obtenerAgenciasPorFuenteBilateral() {
      this.agencias = [];
      this.ddAgenciasLoading=true;
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'Agencias/all/1', id: this.fuenteBilateralSeleccionada.id})
        .then(res => {
          if (res.status === 200) {
            
            this.agencias = res.data;
          }
          this.ddAgenciasLoading=false;
        })
        .catch(() => {
          this.ddAgenciasLoading=false;
           this.agencias = [];
        });
    },


    // Obtener agencias Multilaterales
    async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
      this.agenciasMultilaterales = [];
      this.ddAgenciasMultiLoading = true;

      await this.$store
        .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {estadoId:1, fuentesMultilateralesId: fuenteMultilateralId})
        .then(res => {
            if(res.status===200){

                this.agenciasMultilaterales = res.data;
            }
          this.ddAgenciasMultiLoading = false;
        })
        .catch(error => {
            console.log(error)
          this.ddAgenciasMultiLoading = false;
        });
    },


    //Sectores beneficiados
    async obtenerSectoresBeneficiados() {
      this.sectoresBeneficiados = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Sectores/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.sectoresBeneficiados = res.data;
          }
        })
        .catch(() => {
           this.sectoresBeneficiados = [];
        });
    
    },

    obtenerPoliticasPublicas() {
      this.politicasPublicas = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'PoliticasPublicas'})
        .then(res => {
          if (res.status === 200) {
            this.politicasPublicas = res.data;
          }
        })
        .catch(() => {
           this.politicasPublicas = [];
        });
    },

    //Departamentos
    obtenerDepartamentos() {
      this.departamentos = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Departamentos/all/1/94'})
        .then(res => {
          if (res.status === 200) {
            this.departamentos = res.data;
          }
        })
        .catch(() => {
           this.departamentos = [];
        });
    },

    //Municipios por departamento
    async obtenerMunicipiosPorDepartamento() {
      this.municipios = [];
      this.ddMunicipiosLoading = true;
      this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'Municipios', id: this.departamentoSeleccionado.id})
        .then(res => {
          if (res.status === 200) {
            this.municipios = res.data;
          }
          this.ddMunicipiosLoading = false;
        })
        .catch(() => {
           this.municipios = [];
           this.ddMunicipiosLoading = false;
        });
    },

    mostrarModal() {
      this.dialogRegistro = true;
    },
    ocultarModal() {
      //console.log("ocultando")
      this.dialogRegistro = false;
    },



    //Registrar la informacion general del proyecto
    async registrarDatosGenerales(){
      this.btnDatosGeneralesLoading = true;
      


        if(this.documentoOtReemplazado){
          //Cargar Archivo 
          this.cargarArchivo()
          .then(() => {
          
              //?Validar que el archivo fue cargado
              if(this.archivoCargado){
                  this.registrarDatosGeneralesProyecto();
              } else {
                  this.btnDatosGeneralesLoading=false;
              }
          })
          .catch(() => {
              this.btnDatosGeneralesLoading=false;
          }); 
      } else {
          this.registrarDatosGeneralesProyecto();
      }
    },

    async cargarArchivo(){
      const file = this.documentoOpinionTecnica;

      let pathFile = '\\ArchivosSigeaci\\cns\\documentosot\\';
      //Validar si es opinión técnica o justificacion
      if(this.cuentaOpinionTecnica.id === 2){
        pathFile = '\\ArchivosSigeaci\\cns\\documentos\\justificaciones\\';
      }
      if(this.cuentaOpinionTecnica.id === 0){
        this.$refs.snackalert.SnackbarShow('warning', 'Alerta', `Debe seleccionar un tipo de respaldo para el proyecto (documento de opinión técnica o justificación)`);
        return;
      }

      await 
          this.$store.dispatch(CARGAR_ARCHIVO, {file:file, path: pathFile })
          .then(res => {
              //console.log(res)
              if(res.status===200){
                  this.archivoCargado = true;
                  this.datosItem.pathDocumentoOpinionTecnica = res.pathArchivo;
                  return true;
              }
              else {
                  this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                  this.archivoCargado = false;
                  return false;
              }
          })
          .catch(error => {
            console.log(error)
              return false;
              
          });
    },


    configurarFuentes(){
      if(this.tipoFuenteCooperanteSeleccionada.id === 1){
        this.fuenteMultilateralSeleccionada={}
      } else if(this.tipoFuenteCooperanteSeleccionada.id === 2){
        this.fuenteBilateralSeleccionada ={};
      }
      else if(this.tipoFuenteCooperanteSeleccionada.id == 3){
        this.fuenteBilateralSeleccionada= {};
        this.fuenteMultilateralSeleccionada={};
      }
    },

    async registrarDatosGeneralesProyecto(){
      
      //this.datosItem.institucionesId = this.institucionSeleccionada.id;

      this.datosItem.entidadId = this.institucionSeleccionada.id;
      this.datosItem.unidadEjecutoraId = this.unidadEjecutoraSeleccionada.id;

      /* if(this.institucionSeleccionada.tiposInstitucionId != 1)
      {
        delete this.datosItem.unidadEjecutoraId;
      }
 */

      this.datosItem.agenciasId = this.agenciaSeleccionada.id;
      this.datosItem.agenciaMultilateralId = this.agenciaMultilateralSeleccionada.id;
      
      this.datosItem.fuentesBilateralesId = this.fuenteBilateralSeleccionada.id;
      this.datosItem.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;
      
      if(this.instrumentoSeleccionado.id != 1){

        this.datosItem.tiposInstrumentoId = this.tipoInstrumentoSeleccionado.id;
      } else {
        this.datosItem.tiposInstrumentoId=1;
      }


      this.datosItem.tiposFuenteCooperanteId = this.tipoFuenteCooperanteSeleccionada.id;
      this.datosItem.tiposCooperacionId = this.tipoCooperacionSeleccionada.id;
      this.datosItem.sectoresId = this.sectoresBeneficiadosSeleccionado.id;
      this.datosItem.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;
      this.datosItem.modalidadEjecucionId = this.modalidadEjecucionSeleccionada.id;
      this.datosItem.usuarioCreacion = "admin";


      if(this.agenciaSeleccionada.id === 0 || !this.agenciaSeleccionada){
        this.datosItem.agenciasId = null;
      }

      if(this.agenciaMultilateralSeleccionada.id === 0 || !this.agenciaMultilateralSeleccionada){
        this.datosItem.agenciaMultilateralId = null;
      }


      //Eliminar campos que no se utilizan en esta seccion
      delete this.datosItem.fechaAprobacionPlazos;
      delete this.datosItem.fechaFinalizacionFaseEjecucion;
      delete this.datosItem.fechaFinalizacionPlazos;
      delete this.datosItem.fechaInicioPlazos;
      delete this.datosItem.fechaFinalizacionFaseEjecucion;
      delete this.datosItem.fechaFinalizacionPlazos;
      delete this.datosItem.fechaInicioPlazos;
      delete this.datosItem.fechaSuscripcionPlazos;
      


      delete this.datosItem.codigoDelProyecto;
      delete this.datosItem.codigoFuenteCooperante;
      delete this.datosItem.descripcionProyecto;
      delete this.datosItem.fechaAprobacionPlazos;
      delete this.datosItem.codigoDelProyecto;
      delete this.datosItem.codigoFuenteCooperante;
      delete this.datosItem.descripcionProyecto;


      //Verficar si la fuente cooperante es bilateral
      if(this.tipoFuenteCooperanteSeleccionada.id ===1){
        //Si es bilateral, poner nulo el fuenteMultilateral
        this.datosItem.fuentesMultilateralesId = null;
        this.datosItem.agenciaMultilateralId = null;
      } else if(this.tipoFuenteCooperanteSeleccionada.id===2){
        //Si es multilateral poner nulos fuentesBilateralesId y agencias Id
        this.datosItem.fuentesBilateralesId = null;
        this.datosItem.agenciasId = null;
      } else {
        this.datosItem.fuentesMultilateralesId = null;
        this.datosItem.fuentesBilateralesId = null;
        this.datosItem.agenciasId = null;
        this.datosItem.agenciaMultilateralId = null;
      }


      this.datosItem.cuentaConOpinionTecnica = this.cuentaOpinionTecnica.id;

      //this.datosItem.fechaSolicitudOpinionTecnica = moment(this.fechaSolicitudOpinionTecnica, "DD/MM/YYYY").format("YYYY-MM-DD");

      this.datosItem.fechaSolicitudOpinionTecnica = moment(this.computedDateFormatted, "DD/MM/YYYY").format("YYYY-MM-DD");

      if(this.accion===1){
        this.datosItem.id = 0;
        await this.$store
            .dispatch(REGISTRAR_PROYECTO_CNS, { datos: this.datosItem, seccion: 'RegistroInicial'})
            .then(res => {
                //console.log(res)
                if(res.status===200){
                   // this.obtnenerItems();
                   // this.dialog=false;

                    this.documentoOpinionTecnica=[];
                    document.querySelector("#respaldoFile").value = "";
                    this.$refs.respaldoFile.reset();

                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                    this.tabInformacionAdicional=false;
                    this.accion =2;
                    this.datosItem.id = res.data.id;
                    this.codigoProyectoSeleccionado = res.data.codigo;
                    this.$emit('get-items');
                    this.$emit('actualizar-accion', 2);
                    //this.tab = 'tab-2';
                    this.btnDatosPrincipalesText ="Actualizar datos principales";
                    this.documentoOtReemplazado = false;
                    this.dialogRegistro=false;

                    


                    this.resetForm();
                  //  this.switchItemEstado = true;
                    this.archivoCargado=false;
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnDatosGeneralesLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnDatosGeneralesLoading=false;
            });

                

    } else {
      await this.$store
            .dispatch(ACTUALIZAR_PROYECTO_CNS, {datos: this.datosItem, endpoint: 'InformacionGeneral', id: this.datosItem.id})
            .then(res => {
                //console.log(res)
                if(res.status===200){
                   // this.obtnenerItems();
                   // this.dialog=false;
                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                    this.$emit('get-items');
                    //this.resetForm();
                    //this.switchItemEstado = true;
                    
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnDatosGeneralesLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnDatosGeneralesLoading=false;
            });
      }
    },



    ///Obtener datos de Datos Generales
    async  obtenerDatosGenerales(id) {
        //this.ddTiposInstrumentoLoading = true;
        this.institucionSeleccionada = null;
        this.unidadEjecutoraSeleccionada =null;
        
        this.datosItem = [];

        await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {Seccion: 'DatosGenerales', id: id})
        .then(res => {
            if (res.status === 200) {
                this.datosItem = res.data;
                this.btnDatosPrincipalesText="Actualizar datos principales";
                //this.obtenerDatosInstitucion(this.datosItem.institucionesId);
                //this.institucionSeleccionada = this.instituciones.find(item => item.id === this.datosItem.institucionesId);


                this.datosItem.antecedentes ? this.datosItem.antecedentes = this.datosItem.antecedentes: this.datosItem.antecedentes = '';
                this.datosItem.problematica ? this.datosItem.problematica = this.datosItem.problematica: this.datosItem.problematica = '';
                this.datosItem.justificacion ? this.datosItem.justificacion = this.datosItem.justificacion: this.datosItem.justificacion = '';
                this.datosItem.situacionSinProyecto ? this.datosItem.situacionSinProyecto = this.datosItem.situacionSinProyecto: this.datosItem.situacionSinProyecto = '';
                this.datosItem.situacionConProyecto ? this.datosItem.situacionConProyecto = this.datosItem.situacionConProyecto: this.datosItem.situacionConProyecto = '';
                this.datosItem.objetivoGeneral ? this.datosItem.objetivoGeneral = this.datosItem.objetivoGeneral: this.datosItem.objetivoGeneral = '';
                this.datosItem.objetivoEspecifico1 ? this.datosItem.objetivoEspecifico1 = this.datosItem.objetivoEspecifico1: this.datosItem.objetivoEspecifico1 = '';
                this.datosItem.objetivoEspecifico2 ? this.datosItem.objetivoEspecifico2 = this.datosItem.objetivoEspecifico2: this.datosItem.objetivoEspecifico2 = '';
                this.datosItem.objetivoEspecifico3 ? this.datosItem.objetivoEspecifico3 = this.datosItem.objetivoEspecifico3: this.datosItem.objetivoEspecifico3 = '';
                this.datosItem.descripcionProyecto ? this.datosItem.descripcionProyecto = this.datosItem.descripcionProyecto: this.datosItem.descripcionProyecto = '';
                this.datosItem.pathDocumentoOpinionTecnica ? this.documentoOpinionTecnicaRegistrado = true : this.documentoOpinionTecnicaRegistrado = false;
                this.instrumentoSeleccionado.id = this.datosItem.instrumentosId;
                this.fechaSolicitudOpinionTecnica = this.datosItem.fechaSolicitudOpinionTecnica;
                this.modalidadEjecucionSeleccionada.id = this.datosItem.modalidadEjecucionId;
                this.tipoCooperacionSeleccionada.id = this.datosItem.tiposCooperacionId;
                this.sectoresBeneficiadosSeleccionado.id = this.datosItem.sectoresId;
                
                //Institucion y unidad ejecutora seleccionada
                if(this.datosItem.institucionesId){
                  this.institucionSeleccionada = this.instituciones.find(item => item.id === this.datosItem.institucionesId); 


                  
                  this.obtenerUnidadesEjecutorasPorInstitucion(this.datosItem.institucionesId).then(()=> {
                    if(this.datosItem.unidadEjecutoraId && this.datosItem.unidadEjecutoraId != 0){

                      this.unidadEjecutoraSeleccionada = this.unidadesEjecutoras.find(item => {
                                                                                                if(item.id === this.datosItem.unidadEjecutoraId && item.entidadId === this.datosItem.institucionesId) return true;
                                                                                              }); 

                      //console.log(this.unidadEjecutoraSeleccionada)
                      this.datosItem.unidadEjecutoraId = res.data.unidadEjecutoraId;
                        }
                  });
                 
                }

                this.obtenerTiposInstrumentos().then(()=>{
                  //this.tipoInstrumentoSeleccionado.id = this.datosItem.tiposInstrumentoId;
                  this.tipoInstrumentoSeleccionado = this.tiposInstrumentos.find(item => item.id === this.datosItem.tiposInstrumentoId);
                });

                


                //Fuente cooperante bilateral
                if(this.datosItem.tiposFuenteCooperanteId===1){
                    // console.log("si")


                    this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id ===1);
                    this.fuenteBilateralSeleccionada =  this.fuentesBilaterales.find(item => item.id === this.datosItem.fuentesBilateralesId); 
                    this.obtenerAgenciasPorFuenteBilateral().then(()=>{
                        this.agenciaSeleccionada = this.agencias.find(item => item.id === this.datosItem.agenciasId);
                    });
                    

                    // console.log(this.datosItem.fuentesBilateralesId) //
                } else if(this.datosItem.tiposFuenteCooperanteId===2){

                    
                    //this.tipoFuenteCooperanteSeleccionada.id = 2;
                    this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id ===2);
                    this.fuenteMultilateralSeleccionada = this.fuentesMultilateral.find(item => item.id === this.datosItem.fuentesMultilateralesId); 

                    this.obtenerAgenciasMultilaterales(this.datosItem.fuentesMultilateralesId).then(()=>{
                      this.agenciaMultilateralSeleccionada = this.agenciasMultilaterales.find(item => item.id === this.datosItem.agenciaMultilateralId);
                    });
                   
                } else{
                    this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id ===3);
                }
            }

            //this.obtenerRiesgosProyecto();
            //this.obtenerIndicadoresProyecto();
            this.skeletonLoading=false;
        })
        .catch(error => {
            console.log(error)
              this.skeletonLoading=false;
            //this.datosItem = [];
        // this.ddTiposInstrumentoLoading = false;
        });

    },


     //Obtener datos institucion
        async obtenerDatosInstitucion(institucionId){
            this.datosInstitucion = {};
            //this.tableLoading = true;

            await this.$store
                .dispatch(OBTENER_INSTITUCION, institucionId)
                .then(res => {
                    if(res.status===200){
                        
                        this.datosInstitucion = res.data;
                        //console.log(this.datosInstitucion);

                        this.tipoInstitucionSeleccionada = this.datosInstitucion.tiposInstitucionId;
                        //this.obtenerInstituciones(this.tipoInstitucionSeleccionada);
                        if(this.tipoInstitucionSeleccionada===1){

                            this.mostrarUnidadesEjecutoras=true;
                        } else {
                            this.mostrarUnidadesEjecutoras=false;
                        }
                    }
                   
                })
                .catch(() => {
                    this.datosInstitucion = {};
                });
        },

    async registrarInformacionGeneral(){

      this.btnInformacionAdicionalLoading=true;
      this.datosItemInformacionGeneral.id = this.datosItem.id;
      this.datosItemInformacionGeneral.antecedentes = "...";
      this.datosItemInformacionGeneral.justificacion ="...";
      this.datosItemInformacionGeneral.problematica ="...";
      this.datosItemInformacionGeneral.situacionConProyecto ="...";
      this.datosItemInformacionGeneral.situacionSinProyecto ="...";

      await this.$store
          .dispatch(REGISTRAR_PROYECTO_CNS_EXTERNO, { datos: this.datosItemInformacionGeneral, seccion: 'Alcance', id: this.datosItem.id})
          .then(res => {
              //console.log(res)
              if(res.status===200){
                  // this.obtnenerItems();
                  // this.dialog=false;
                  this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                  this.$emit('get-items');
                //  this.switchItemEstado = true;
                  this.archivoCargado=false;
              } else {
                  this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
              }
              this.btnDatosGeneralesLoading=false;
              this.btnInformacionAdicionalLoading=false;
          })
          .catch(error => {
              //console.log(error)
              this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
              this.btnDatosGeneralesLoading=false;
              this.btnInformacionAdicionalLoading=false;
          });

                

     

      

    },

    agregarCobertura() {
      //Buscar el municipio que se quiere Ingresar

      //Validar que se haya seleccionado un departamento
      if (!this.departamentoSeleccionado.id) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `No se ha seleccionado departamento y/o municipio`
        );
        return;
      }

      var encontrado = this.cobertura.find(
        e => e.municipioId === this.municipioSeleccionado.id
      );

      //console.log(encontrado)

      if (encontrado) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El municipio que intenta agregar ya se encuentra registrado`
        );
        return;
      }

      this.cobertura.push({
        departamento: this.departamentoSeleccionado.nombre,
        municipio: this.municipioSeleccionado.nombre,
        departamentosId: this.municipioSeleccionado.id,
        municipiosId: this.municipioSeleccionado.id
      });
    },

    eliminarCobertura(municipio) {
      this.cobertura.splice(
        this.cobertura.findIndex(e => e.municipio == municipio),
        1
      );
    },

    registrarItem() {
      return;
      //console.log("registrando....")
    },

    resetForm() {
      this.accion = 1;
      this.datosItem.agenciasId = 0; 
      this.agenciaSeleccionada = {};
      this.datosItem.fuentesBilateralesId = 0;
      this.fuenteBilateralSeleccionada = {};
      this.datosItem.tiposInstrumentoId =  0;
      this.tipoInstrumentoSeleccionado = {};
      this.datosItem.tiposFuenteCooperanteId = 0;
      this.tipoFuenteCooperanteSeleccionada = {};
      this.datosItem.tiposCooperacionId = 0;
      this.tipoCooperacionSeleccionada = {};
      this.datosItem.sectoresId = 0;  
      this.sectoresBeneficiadosSeleccionado = {};
      this.datosItem.fuentesMultilateralesId = 0; 
      this.fuenteMultilateralSeleccionada = {};
      this.datosItem.modalidadEjecucionId = 0; 
      this.modalidadEjecucionSeleccionada = {};
      this.nombreProyecto = "";
      this.fechaSolicitudOpinionTecnica = "";
      this.datosItem.fechaSolicitudOpinionTecnica = "";
      this.datosItem.nombreSolicitante = "";
      this.datosItem.correoElectronico = "";
      this.datosItem.pathDocumentoOpinionTecnica = "";
      this.datosItem.usuarioCreacion ="admin";
      this.datosItem.estadosId = 1;
      this.tab = "tab-1";
      this.btnDatosPrincipalesText ="Guardar datos principales";
      this.datosItem.refSigeaci="";
      this.documentoOpinionTecnica=[];
      document.querySelector("#respaldoFile").value = "";
      this.$refs.respaldoFile.reset();

    },


    descargarArchivo(path){
      DownloadFile.download(path);
    }


  },



 /* watch: {
    computedDateFormatted () {
     // this.fechaOtFormatted = this.formatDate(this.date);
      this.datosItem.fechaSolicitudOpinionTecnica =this.formatDate(this.date);
    },
  },*/

  /*watch: {
    //Cambiar el formato de fechas a DD-MM-YYY para mostrar y a YYYY-MM-DD para registrar en datosItem.fechaApertura
    fechaSolicitudOpinionTecnica: function(val) {
        this.datosItem.fechaSolicitudOpinionTecnica = moment(val, "DD-MM-YYYY").format("YYYY-MM-DD");
      },
  },*/
  computed: {
    
    
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    
    headersCobertura(){
      return[
        {
          text: "Departamento",
          align: "start",
          sortable: false,
          value: "nombre"
        },
        {
          text: "Municipio",
          align: "start",
          sortable: true,
          value: "fecha"
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: "nombre"
        }
      ]
    }
  },
  created() {

    this.resetItems(1);
    this.obtenerInstituciones(1);
    //this.obtenerInstituciones();
    this.obtenerTiposInstitucion();
    this.obtenerInstrumentos();
    this.obtenerModalidadesEjecucion();
    this.obtenerTiposFuenteCooperante();
    this.obtenerFuentesBilaterales();
    this.obtenerFuentesMultilateral();
    this.obtenerTiposCooperacion();
    this.obtenerSectoresBeneficiados();
    //this.obtenerPoliticasPublicas();
    //this.obtenerDepartamentos();
     this.fechaSolicitudOpinionTecnica='';
  }
};
</script>
