var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Objetivos de Desarrollo Sostenible secundarios ")])],1)],1),_c('v-form',{ref:"form",staticClass:"pt-4 pl-2 pr-2",on:{"submit":function($event){$event.preventDefault();return _vm.asignarOdsSecundario.apply(null, arguments)}},model:{value:(_vm.validFormOdsSecundario),callback:function ($$v) {_vm.validFormOdsSecundario=$$v},expression:"validFormOdsSecundario"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.ods,"loading":_vm.ddOdsLoading,"clearable":true,"dense":"","filled":"","label":"Seleccionar Objetivo de Desarrollo Sostenible","item-text":"nombreODS","item-value":"id","no-data-text":_vm.ods.length > 0
                  ? 'Seleccione Objetivo de Desarrollo Sostenible secundario'
                  : 'No se han encontrado Objetivos de Desarrollo Sostenible secundario',"rules":[_vm.selectRequired('Ods')],"menu-props":"auto","disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas},model:{value:(_vm.datosOds.odsId),callback:function ($$v) {_vm.$set(_vm.datosOds, "odsId", $$v)},expression:"datosOds.odsId"}})],1)],1),(!_vm.seccionesBloqueadas || _vm.tipoUsuario == "int")?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right mt-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validFormOdsSecundario,"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar información ")])],1)],1):_vm._e()],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-subtitle-1 black---text"},[_vm._v(" ODS secundarios registrados ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersOds,"items":_vm.odsSecundarios,"loading":_vm.tableLoadingOdsSecundarios,"hide-default-footer":"","footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              'items-per-page-text': 'Registros por página',
              pageText: '{0}-{1} de {2}',
            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.nombreODS))]),_c('td',[(item.estadosId == 1 && !_vm.seccionesBloqueadas)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarOdsDisabled,"loading":_vm.btnEliminarOdsDisabled,"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarOdsSecunadario(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ODS ")],1):_vm._e()],1)])]}}])})],1)],1)],1)],1),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }