<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <v-form
        ref="form"
        v-on:submit.prevent="registrarItem"
        v-model="validForm"
      >
        <v-row class="mt-2">
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
            <v-subheader class="text-h5 black--text">
              Información general del proyecto
            </v-subheader>
          </v-col>
        </v-row>

        <v-row class="pb-3" v-if="!seccionesBloqueadas || tipoUsuario == `int`">
          <v-col cols="12" md="12" sm="12">
            <v-alert outlined color="#1488c2" border="left">
              <p class="mb-1">
                Los campos marcados con un asterisco (<span
                  color="red"
                  class="red--text"
                  >*</span
                >) son obligatorios
              </p>
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="12"
            class="pt-2 pb-0"
            v-if="tipoUsuario == `int`"
          >
            <v-text-field
              dense
              filled
              autocomplete="off"
              class=""
              color="blue-grey lighten-2"
              v-model="datosItem.refSigeaci"
              label="Código SIGEACI"
              :rules="[maxLength('Código SIGEACI', 20)]"
              maxlength="20"
              hint="Completar si el proyecto se encuentra registrado en SIGEACI"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-textarea
              dense
              filled
              autocomplete="off"
              class="required"
              color="blue-grey lighten-2"
              v-model="datosItem.nombreProyecto"
              label="Nombre del proyecto"
              :rules="[
                required('nombre del proyecto'),
                minLength('nombre del proyecto', 5),
                maxLength('nombre del proyecto', 500)
              ]"
              rows="3"
              maxlength="500"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <!-- <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-select
                    v-model="datosItem.cuentaConOpinionTecnica"
                    :items="cuentaOpinionTecnicaItems"
                    :loading="false"
                    dense
                    filled
                    class="required"
                    label="¿Cuenta con opinión técnica?"
                    item-text="text"
                    item-value="id"
                    menu-props="auto"
                    :rules="[
                        selectRequired('Cuenta con opinión técnica'),
                    ]"
                >
                </v-select>
            </v-col> -->

          <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
            <!--   <v-text-field
                dense
                filled
                autocomplete="off"
                class="required"
                color="blue-grey lighten-2"
                v-model="fechaSolicitudOpinionTecnica"
                label="Fecha de solicitud de la opinión técnica"
                :rules="[
                    required('fecha de solicitud de la opinión técnica'),
                    minLength('fecha de solicitud de la opinión técnica', 8),
                    dateFormat('fecha de solicitud de la opinión técnica'),
                    dateBeforeThan('fecha de solicitud de la opinión técnica', fechaActual)
                ]"
                maxlength="10"
                v-mask="'##/##/####'"
                ></v-text-field> -->

            <v-menu
              ref="menuFechaOt"
              v-model="menuFechaOt"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  color="blue-grey lighten-2"
                  v-model="computedDateFormatted"
                  readonly
                  label="“Fecha de aprobación de opinión técnica"
                  hint="DD/MM/AAAA"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    required('“Fecha de aprobación de opinión técnica'),
                    dateFormat('“Fecha de aprobación de opinión técnica')
                  ]"
                  v-mask="'##/##/####'"
                  maxlength="10"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="menuFechaOt = false"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                locale="es"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-card class="mt-6" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                <v-subheader class="text-h6 black--text">
                  Datos de la institución
                </v-subheader>
              </v-col>
            </v-row>

            <v-row>
              <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-select
                            v-model="tipoInstitucionSeleccionada"
                            :items="tiposInstitucion"
                            :loading="ddTiposInstitucionLoading"
                            dense
                            filled
                            class="required"
                            label="Tipo de institución"
                            item-text="nombreTipo"
                            item-value="id"
                            :no-data-text="
                                unidadesEjecutoras != null
                                ? 'Selecciona un tipo de institución'
                                : 'No se han encontrado tipos de institución'
                            "
                            menu-props="auto"
                            @change="obtenerInstituciones(tipoInstitucionSeleccionada)"
                            >
                        </v-select>
                    </v-col> -->

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-autocomplete
                  v-model="institucionSeleccionada"
                  :items="instituciones"
                  :loading="institucionesLoading"
                  dense
                  filled
                  class="required"
                  label="Institución"
                  item-text="nombreInstitucion"
                  item-value="id"
                  return-object
                  :rules="[selectRequired('institución')]"
                  :no-data-text="
                    instituciones != null
                      ? 'Selecciona una institución ejecutora'
                      : 'No se han encontrado unidades ejecutoras'
                  "
                  menu-props="auto"
                  @change="
                    obtenerUnidadesEjecutorasPorInstitucion(
                      institucionSeleccionada.id,
                      1
                    )
                  "
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <!-- v-if="mostrarUnidadesEjecutoras"-->
                <v-autocomplete
                  v-model="unidadEjecutoraSeleccionada"
                  :items="unidadesEjecutoras"
                  :loading="unidadesEjecutorasLoading"
                  dense
                  filled
                  class="required"
                  label="Dependencia"
                  item-text="unidadEjecutora"
                  item-value="id"
                  return-object
                  :rules="[selectRequired('Dependencia')]"
                  :no-data-text="
                    unidadesEjecutoras != null
                      ? 'Selecciona una Dependencia'
                      : 'No se han encontrado unidades ejecutoras'
                  "
                  menu-props="auto"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row class="mt-6">
          <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
            <v-select
              v-model="tipoCooperacionSeleccionada"
              :items="tiposCooperacion"
              :loading="false"
              dense
              filled
              class="required"
              label="Tipo de cooperación"
              item-text="nombreTipoCooperacion"
              item-value="id"
              return-object
              :no-data-text="
                tiposCooperacion != null
                  ? 'Seleccione un tipo de cooperación'
                  : 'No se han encontrado tipos de cooperación'
              "
              menu-props="auto"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
            <v-select
              v-model="modalidadEjecucionSeleccionada"
              :items="modalidadesEjecucion"
              :loading="false"
              dense
              filled
              class="required"
              label="Modalidad de ejecución"
              item-text="nombreModalidad"
              item-value="id"
              return-object
              :no-data-text="
                modalidadesEjecucion != null
                  ? 'Seleccione una modalidad'
                  : 'No se han encontrado modalidades de ejecución'
              "
              menu-props="auto"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            ></v-select>
          </v-col>
        </v-row>

        <!--         <v-row>    

            <v-col cols="12" md="12" class="pt-0">
                <v-divider></v-divider>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                    v-model="ingresaAPresupuestoSeleccionado"
                        :items="ingresaAPresupuestoItems"
                        :loading="false"
                        dense
                        class="required"
                        filled
                        return-object
                        :rules="[selectRequired('ingresa a presupuesto')]"
                        label="¿El proyecto ingresa a presupuesto?"
                        item-text="text"
                        item-value="id"
                        menu-props="auto"
                        @change="configIngresaPresupuesto"
                        :disabled="(tipoUsuario==`ext` && seccionesBloqueadas)"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="ingresaAPresupuestoSeleccionado.id===1">
                    <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        :class="ingresaAPresupuestoSeleccionado.id===1 ? 'required' : ''"
                        color="blue-grey lighten-2"
                        v-model="datosItem.codigoInstitucion"
                        label="Código de la institución"
                        :disabled="ingresaAPresupuestoSeleccionado.id===2 || (tipoUsuario==`ext` && seccionesBloqueadas)"
                        :rules="[
                            ingresaAPresupuestoSeleccionado.id === 1 ? required('código de la institución') : true,
                            ingresaAPresupuestoSeleccionado.id === 1 ? maxLength('código de la institución', 50) : true
                        ]"
                        maxlength="50"
                        
                        >
                    </v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="ingresaAPresupuestoSeleccionado.id===1">
                    <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        :class="ingresaAPresupuestoSeleccionado.id ==1 ? 'required' : ''"
                        color="blue-grey lighten-2"
                        v-model="datosItem.codigoFuenteCooperante"
                        label="Código de la fuente cooperante"
                        :disabled="ingresaAPresupuestoSeleccionado.id==2"
                        :rules="[
                            ingresaAPresupuestoSeleccionado.id == 1 ? required('Código de la fuente cooperante') : true,
                            ingresaAPresupuestoSeleccionado.id == 1 ? maxLength('Código de la fuente cooperante', 50) : true
                        ]"
                        maxlength="50"
                        >
                    </v-text-field>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0" v-if="ingresaAPresupuestoSeleccionado.id===1">
                    <v-text-field
                        dense
                        filled
                        autocomplete="off"
                        :class="ingresaAPresupuestoSeleccionado.id ==1 ? 'required' : ''"
                        color="blue-grey lighten-2"
                        v-model="datosItem.codigoDelProyecto"
                        label="Código del proyecto"
                        :disabled="ingresaAPresupuestoSeleccionado.id==2 || (tipoUsuario==`ext` && seccionesBloqueadas)"
                        :rules="[
                            ingresaAPresupuestoSeleccionado.id == 1 ? required('Código del proyecto') : true,
                            ingresaAPresupuestoSeleccionado.id == 1 ? maxLength('Código del proyecto', 50) : true
                        ]"
                        maxlength="50"
                        >
                    </v-text-field>
                </v-col>

                
        </v-row> -->

        <v-row>
          <v-col cols="12" md="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
            <v-select
              v-model="tipoAprobacionSeleccionada"
              :items="tiposAprobacionDonacion"
              :loading="false"
              dense
              filled
              class="required"
              label="Documento de aprobación de la donación"
              item-text="nombreTipoAprobacion"
              item-value="id"
              return-object
              :no-data-text="
                tiposAprobacionDonacion != null
                  ? 'Seleccione un documento de aprobación de donación'
                  : 'No se han encontrado tipo de aprobación de donación'
              "
              :rules="[
                selectRequired('Documento de aprobación de la donación')
              ]"
              menu-props="auto"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
            <v-text-field
              dense
              filled
              autocomplete="off"
              class="required"
              color="blue-grey lighten-2"
              v-model="datosItem.numeroAcuerdo"
              label="Número de acuerdo gubernativo/ministerial/resolución"
              :rules="[
                required(
                  'número de acuerdo gubernativo/ministerial/resolución'
                ),
                maxLength(
                  'número de acuerdo gubernativo/ministerial/resolución',
                  50
                )
              ]"
              maxlength="50"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 mb-0">
            <v-select
              v-model="cuentaNumeroDonacion"
              :items="cambiarFuenteCooperanteItems"
              :loading="false"
              dense
              filled
              class="required"
              label="¿Cuenta con número de donación?"
              item-text="text"
              item-value="id"
              menu-props="auto"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
            sm="6"
            xs="12"
            class="pb-0 mb-0"
            v-if="cuentaNumeroDonacion == 2"
          >
            <v-text-field
              dense
              filled
              autocomplete="off"
              class="required"
              color="blue-grey lighten-2"
              v-model="datosItem.numeroDonaciones"
              label="Número de donación"
              :rules="[
                required('número de donación'),
                maxLength('número de donación', 50)
              ]"
              maxlength="50"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-card class="mt-6" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                <v-subheader class="text-h6 black--text">
                  Fuente cooperante
                </v-subheader>
              </v-col>

              <v-col
                cols="12"
                md="6"
                sm="12"
                class="pb-0 mb-0"
                v-if="!seccionesBloqueadas || tipoUsuario == `int`"
              >
                <v-select
                  v-model="cambiarFuenteCooperante"
                  :items="cambiarFuenteCooperanteItems"
                  :loading="false"
                  dense
                  filled
                  class="required"
                  label="¿Desea cambiar fuente cooperante?"
                  item-text="text"
                  item-value="id"
                  menu-props="auto"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="12" class="pt-0 mt-0">
                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-select
                  v-model="tipoFuenteCooperanteSeleccionada"
                  :items="tiposFuenteCooperante"
                  :loading="false"
                  dense
                  filled
                  class="required"
                  :disabled="
                    cambiarFuenteCooperante == 1 ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  label="Tipo"
                  item-text="nombreTipoFuente"
                  item-value="id"
                  return-object
                  :rules="[selectRequired('nombre del tipo fuente cooperante')]"
                  :no-data-text="
                    tiposFuenteCooperante != null
                      ? 'Selecciona un tipo de fuente cooperante'
                      : 'No se han encontrado tipos de fuente cooperante'
                  "
                  @change="configurarFuentes()"
                  menu-props="auto"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id == 1"
              >
                <v-select
                  v-model="fuenteBilateralSeleccionada"
                  :items="fuentesBilaterales"
                  :loading="false"
                  :required="tipoFuenteCooperanteSeleccionada.id == 1"
                  dense
                  :class="
                    tipoFuenteCooperanteSeleccionada.id == 1 ? 'required' : ''
                  "
                  filled
                  :disabled="
                    cambiarFuenteCooperante == 1 ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  label="País Fuente Bilateral"
                  item-text="nombre"
                  item-value="id"
                  return-object
                  :rules="[
                    tipoFuenteCooperanteSeleccionada.id === 1
                      ? selectRequired('país bilateral')
                      : true
                  ]"
                  :no-data-text="
                    fuentesBilaterales != null
                      ? 'Selecciona un país'
                      : 'No se han encontrado países'
                  "
                  @change="
                    obtenerAgenciasPorFuenteBilateral();
                    agenciaSeleccionada = {};
                    obtenerEstrategias('fuente');
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>

              <!-- inicio:: agencias -->
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id == 1"
              >
                <v-select
                  v-model="agenciaSeleccionada"
                  :items="agencias"
                  :loading="false"
                  :clearable="true"
                  dense
                  filled
                  :disabled="
                    cambiarFuenteCooperante == 1 ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  label="Seleccione Agencia"
                  item-text="nombreAgencia"
                  item-value="id"
                  return-object
                  :no-data-text="
                    agencias > 0
                      ? 'Selecciona una agencia'
                      : 'No se han encontrado agencias'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>
              <!-- fin:: agencias -->

              <v-col
                cols="12"
                md="6"
                sm="12"
                xs="12"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id == 2"
              >
                <v-select
                  v-model="fuenteMultilateralSeleccionada"
                  :items="fuentesMultilateral"
                  :loading="false"
                  :class="
                    tipoFuenteCooperanteSeleccionada.id == 2 ? 'required' : ''
                  "
                  dense
                  filled
                  :disabled="
                    cambiarFuenteCooperante == 1 ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  label="Seleccione fuente multilateral"
                  item-text="nombreFuenteMultilateral"
                  item-value="id"
                  return-object
                  :no-data-text="
                    fuentesMultilateral > 0
                      ? 'Seleccione una fuente multilateral'
                      : 'No se han encontrado fuentes multilaterales'
                  "
                  :rules="[
                    tipoFuenteCooperanteSeleccionada.id === 2
                      ? selectRequired('fuente multilateral')
                      : true
                  ]"
                  @change="
                    obtenerAgenciasMultilaterales(
                      fuenteMultilateralSeleccionada.id
                    );
                    agenciaMultilateralSeleccionada = {};
                    obtenerEstrategias('fuente');
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>

              <!-- inicio:: agencias -->
              <v-col
                cols="12"
                md="6"
                sm="6"
                xs="12"
                class="pt-0 pb-0"
                v-if="tipoFuenteCooperanteSeleccionada.id == 2"
              >
                <v-select
                  v-model="agenciaMultilateralSeleccionada"
                  :items="agenciasMultilaterales"
                  :clearable="true"
                  :disabled="
                    cambiarFuenteCooperante == 1 ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  :loading="ddAgenciasMultiLoading"
                  dense
                  filled
                  label="Seleccione Agencia Multilateral"
                  item-text="agencia"
                  item-value="id"
                  return-object
                  :no-data-text="
                    agenciasMultilaterales > 0
                      ? 'Selecciona una agencia'
                      : 'No se han encontrado agencias para la fuente seleccionada'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>
              <!-- fin:: agencias -->

              <!-- habilitar si la fuente multi es snu-->

              <v-col
                cols="12"
                md="12"
                sm="12"
                xs="12"
                class="pt-0 pb-0"
                v-if="
                  tipoFuenteCooperanteSeleccionada.id == 2 &&
                    fuenteMultilateralSeleccionada.id === 1
                "
              >
                <v-textarea
                  dense
                  filled
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosItem.agenciasSecundarias"
                  label="Describa las agencias secundarias"
                  hint="- Agencia 1"
                  :rules="[
                    /*tipoFuenteCooperanteSeleccionada.id == 2 && fuenteMultilateralSeleccionada.id === 1 ? required('agencias secundarias') : true,*/
                    /*minLength('agencias secundarias', 2),*/
                    maxLength('agencias secundarias', 2000)
                  ]"
                  rows="3"
                  maxlength="200"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row class="mt-6">
          <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
            <v-select
              v-model="instrumentoSeleccionado"
              :items="instrumentos"
              :loading="false"
              dense
              filled
              class="required"
              label="Instrumento"
              item-text="instrumento"
              item-value="id"
              return-object
              @change="obtenerTiposInstrumentos()"
              :rules="[selectRequired('instrumento')]"
              :no-data-text="
                instrumentos != null
                  ? 'Selecciona un instrumento'
                  : 'No se han encontrado instrumentos'
              "
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              menu-props="auto"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
            <v-select
              v-model="tiposInstrumentoSeleccionado"
              :items="tiposInstrumentos"
              :loading="ddTiposInstrumentoLoading"
              dense
              filled
              class="required"
              label="Tipo de Instrumento"
              item-text="nombreTipoInstrumento"
              item-value="id"
              return-object
              :rules="[selectRequired('tipo de instrumento')]"
              :no-data-text="
                tiposInstrumentos != null
                  ? 'Selecciona un tipo instrumento'
                  : 'No se han encontrado tipos de instrumento'
              "
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              menu-props="auto"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="habilitarSeleccionEstrategia">
          <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
            <v-subheader class="text-h6 black--text" style="height: 25px;">
              Seleccionar estrategia
            </v-subheader>
          </v-col>
          <v-col cols="12" md="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-select
              :clearable="true"
              v-model="datosItem.estrategiaCNSCId"
              :items="estrategiasCooperante"
              :loading="ddEstrategiasLoading"
              dense
              filled
              class=""
              label="Seleccionar estrategia"
              item-text="estrategia"
              item-value="id"
              :no-data-text="
                estrategiasCooperante.length <= 0
                  ? 'Selecciona una estrategia'
                  : 'No se han encontrado estrategias para la fuente cooperante seleccionada'
              "
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              menu-props="auto"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="mt-2" v-if="!seccionesBloqueadas || tipoUsuario == `int`">
          <v-col cols="12">
            <v-btn
              color="light-blue darken-2"
              class="white--text mb-2 float-right"
              type="submit"
              :elevation="0"
              :disabled="
                !validForm || (tipoUsuario == `ext` && seccionesBloqueadas)
              "
              :loading="btnRegistroLoading"
            >
              Guardar información
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </div>
</template>

<script>
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

import moment from "moment";
import { OBTENER_INSTITUCION } from "@/core/services/store/instituciones/institucion.module";
import { OBTENER_UNIDADES_EJECUTORAS_ENTIDAD } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
//import { OBTENER_UNIDADES_EJECUTORAS_INSTITUCION } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import {
  OBTENER_ITEMS_CATALOGO,
  OBTENER_SUBITEMS_CATALOGO
} from "@/core/services/store/catalogos/catalogointerno.module";
import {
  REGISTRAR_PROYECTO_CNS_EXTERNO,
  OBTENER_DATOS_SECCIONES_PROYECTO
} from "@/core/services/store/proyectoscns/proyectocns.module";
import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";
import { OBTENER_ESTRATEGIAS_CNSC } from "@/core/services/store/cooperantes/estrategias/estrategia.module";

export default {
  name: "SeccionInformacionGeneralProyecto",
  components: {
    SnackAlert
  },
  props: ["id", "tipoUsuario", "confirmacion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuFechaOt: false,
      fechaActual: "",
      seccionesBloqueadas: false,
      skeletonLoading: false,
      validForm: false,
      btnRegistroLoading: false,
      instituciones: [],
      institucionesLoading: false,
      unidadesEjecutorasLoading: false,
      unidadesEjecutoras: [],
      unidadEjecutoraSeleccionada: {},
      instrumentos: [],
      instrumentoSeleccionado: {},
      tiposInstrumentos: [],
      tiposInstrumentoSeleccionado: {},
      ddTiposInstrumentoLoading: false,
      cambiarFuenteCooperante: 1,
      cuentaNumeroDonacion: 1,
      cuentaOpinionTecnicaItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      cambiarFuenteCooperanteItems: [
        { id: 1, text: "No" },
        { id: 2, text: "Si" }
      ],
      tiposFuenteCooperante: [],
      tipoFuenteCooperanteSeleccionada: {},
      paisesFuente: [],
      fuentesBilaterales: [],
      fuenteBilateralSeleccionada: {},
      fuentesMultilateral: [],
      fuenteMultilateralSeleccionada: {},
      agencias: [],
      agenciaSeleccionada: {},
      tiposCooperacion: [],
      tipoCooperacionSeleccionada: {},
      modalidadesEjecucion: [],
      modalidadEjecucionSeleccionada: {},
      tiposAprobacion: [],
      tipoAprobacionSeleccionada: {},

      modalidadesEjecucionSel: 0,
      ingresaAPresupuestoItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      ingresaAPresupuestoSeleccionado: {
        id: 2,
        text: "No"
      },
      fechaSolicitudOpinionTecnica: "",
      tiposInstitucion: [],
      ddTiposInstitucionLoading: false,
      tipoInstitucionSeleccionada: 0,
      mostrarUnidadesEjecutoras: false,
      tiposAprobacionDonacion: [],
      institucionSeleccionada: {},
      agenciaMultilateralSeleccionada: {},
      datosItem: {
        nombreProyecto: "",
        fechaSolicitudOpinionTecnica: "",
        unidadesEjecutorasId: 0,
        tiposInstrumentoId: 0,
        tiposCooperacionId: 0,
        modalidadEjecucionId: 0,
        cambiarFuenteCooperante: 1,
        tiposFuenteCooperanteId: 0,
        tiposAprobacionDonacionId: 0,
        numeroDonaciones: "",
        numeroAcuerdo: "",
        ingresaAPresupuesto: 0,
        codigoFuenteCooperante: "",
        codigoDelProyecto: "",
        codigoInstitucion: "",
        fuentesMultilateralesId: null,
        fuentesBilateralesId: null,
        agenciasId: null,
        estrategiaCNSCId: null,
        agenciasSecundarias: ""
      },
      agenciasMultilaterales: [],
      ddAgenciasMultiLoading: false,
      habilitarSeleccionEstrategia: false,
      ddEstrategiasLoading: false,
      estrategiasCooperante: [],
      ...validations
    };
  },

  methods: {
    resetItems(tipo, proyectoId) {
      this.id = proyectoId;
      let currentDate = new Date().toJSON().slice(0, 10);
      this.fechaActual = moment(currentDate, "YYYY-MM-DD").format("DD-MM-YYYY");

      if (tipo === 2) {
        this.skeletonLoading = true;
      }
      this.skeletonLoading = true;
      this.habilitarSeleccionEstrategia = false;
      this.cambiarFuenteCooperante = 1;
      this.mostrarUnidadesEjecutoras = false;
      this.tipoFuenteCooperanteSeleccionada = {};
      this.fuenteBilateralSeleccionada = {};
      this.agenciaSeleccionada = {};
      this.agenciaMultilateralSeleccionada = {};
      this.ingresaAPresupuestoSeleccionado = {
        id: 2,
        text: "No"
      };
      this.cuentaNumeroDonacion = 1;
      this.datosItem = {
        nombreProyecto: "",
        fechaSolicitudOpinionTecnica: "",
        unidadesEjecutorasId: 0,
        tiposInstrumentoId: 0,
        tiposCooperacionId: 0,
        modalidadEjecucionId: 0,
        cambiarFuenteCooperante: 1,
        tiposFuenteCooperanteId: 0,
        tiposAprobacionDonacionId: 0,
        numeroDonaciones: "",
        numeroAcuerdo: "",
        ingresaAPresupuesto: 0,
        codigoFuenteCooperante: "",
        codigoDelProyecto: "",
        codigoInstitucion: "",
        fuentesMultilateralesId: null,
        fuentesBilateralesId: null,
        agenciasId: null,
        estrategiaCNSCId: null,
        agenciasSecundarias: ""
      };

      //this.skeletonLoading=true;
      this.obtenerTiposInstitucion();
      this.obtenerInstituciones(1);
      this.obtenerTiposFuenteCooperante();
      this.obtenerFuentesMultilateral();
      this.obtenerFuentesBilaterales().then(() => { 
        this.obtenerAgenciasPorFuenteBilateral();
        this.obtenerTiposCooperacion().then(() => {
          this.obtenerModalidadesEjecucion().then(() => {
            this.obtenerTiposAprobacionDonacion().then(() => {
              this.obtenerInstrumentos().then(() => {
                this.obtenerDatosItem(this.id);
              });
            });
          });
        });
      });
    },

    configIngresaPresupuesto() {
      this.datosItem.codigoInstitucion = "";
      this.datosItem.codigoFuenteCooperante = "";
      this.datosItem.codigoDelProyecto = "";
    },

    async obtenerDatosItem(idProyecto) {
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto
        })
        .then(res => {
          if (res.status === 200) {
            this.datosItem = res.data;
            //console.log(this.datosItem)
            //console.log(this.datosItem)
            //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
            if (this.datosItem.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            //this.obtenerDatosInstitucion(this.datosItem.institucionesId);

            if (!this.datosItem.numeroDonaciones) {
              this.datosItem.numeroDonaciones = "";
            }

            if (!this.datosItem.numeroAcuerdo) {
              this.datosItem.numeroAcuerdo = "";
            }

            this.cuentaNumeroDonacion = 1;
            if (this.datosItem.numeroDonaciones) {
              this.cuentaNumeroDonacion = 2;
            }
            //this.fechaSolicitudOpinionTecnica = moment(this.datosItem.fechaSolicitudOpinionTecnica, "YYYY-MM-DD").format("DD-MM-YYYY");

            this.date = moment(
              this.datosItem.fechaSolicitudOpinionTecnica,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD"); //moment(this.datosItem.fechaSolicitudOpinionTecnica, "YYYY-MM-DD").format("DD/MM/YYYY");

            this.tipoCooperacionSeleccionada = this.tiposCooperacion.find(
              item => item.id === this.datosItem.tiposCooperacionId
            );
            this.modalidadEjecucionSeleccionada = this.modalidadesEjecucion.find(
              item => item.id === this.datosItem.modalidadEjecucionId
            );

            !this.datosItem.codigoFuenteCooperante
              ? (this.datosItem.codigoFuenteCooperante = "")
              : (this.datosItem.codigoFuenteCooperante = this.datosItem.codigoFuenteCooperante);
            !this.datosItem.codigoDelProyecto
              ? (this.datosItem.codigoDelProyecto = "")
              : (this.datosItem.codigoDelProyecto = this.datosItem.codigoDelProyecto);

            //Validar si viene el dato de ingresaAPresupuesto
            if (this.datosItem.ingresaAPresupuesto) {
              this.ingresaAPresupuestoSeleccionado = this.ingresaAPresupuestoItems.find(
                item => item.id === this.datosItem.ingresaAPresupuesto
              );
            }

            //Institucion y unidad ejecutora seleccionadas

            //Institucion y unidad ejecutora seleccionada
            if (this.datosItem.institucionesId) {
              this.institucionSeleccionada = this.instituciones.find(
                item => item.id === this.datosItem.institucionesId
              );

              this.obtenerUnidadesEjecutorasPorInstitucion(
                this.datosItem.institucionesId
              ).then(() => {
                if (
                  this.datosItem.unidadEjecutoraId &&
                  this.datosItem.unidadEjecutoraId != 0
                ) {
                  this.unidadEjecutoraSeleccionada = this.unidadesEjecutoras.find(
                    item => {
                      if (
                        item.id === this.datosItem.unidadEjecutoraId &&
                        item.entidadId === this.datosItem.institucionesId
                      )
                        return true;
                    }
                  );

                  //console.log(this.unidadEjecutoraSeleccionada)
                  this.datosItem.unidadEjecutoraId = res.data.unidadEjecutoraId;
                }
              });
            }

            /* this.institucionSeleccionada = this.instituciones.find(item => item.id === this.datosItem.institucionesId); 

                    this.obtenerUnidadesEjecutorasPorInstitucion(this.datosItem.institucionesId, 2).then(() =>{

                        this.unidadEjecutoraSeleccionada = this.unidadesEjecutoras.find(item => item.id === this.datosItem.unidadesEjecutorasId);
                    });
 */
            //Tipo de instrumento
            this.instrumentoSeleccionado = this.instrumentos.find(
              item => item.id === this.datosItem.instrumentosId
            );

            if (this.datosItem.tiposInstrumentoId) {
              this.obtenerTiposInstrumentos().then(() => {
                this.tiposInstrumentoSeleccionado = this.tiposInstrumentos.find(
                  item => item.id === this.datosItem.tiposInstrumentoId
                );
              });
            } else {
              this.datosItem.tiposInstrumentoId = 0;
            }

            //Tipo de aprobacion
            this.tipoAprobacionSeleccionada = this.tiposAprobacionDonacion.find(
              item => item.id === this.datosItem.tiposAprobacionDonacionId
            );

            //Fuente cooperante bilateral
            if (this.datosItem.tiposFuenteCooperanteId === 1) {
              // console.log("si")
              this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(
                item => item.id === 1
              );
              if (this.datosItem.fuentesBilateralesId) {
                this.fuenteBilateralSeleccionada = this.fuentesBilaterales.find(
                  item => item.id === this.datosItem.fuentesBilateralesId
                );
                this.obtenerEstrategias("fuente");
                this.obtenerAgenciasPorFuenteBilateral().then(() => {
                  this.agenciaSeleccionada = this.agencias.find(
                    item => item.id === this.datosItem.agenciasId
                  );
                });
              }

              // console.log(this.datosItem.fuentesBilateralesId) //
            } else if (this.datosItem.tiposFuenteCooperanteId === 2) {
              //console.log(this.datosItem.fuentesMultilateralesId)
              this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(
                item => item.id === 2
              );
              if (this.datosItem.fuentesMultilateralesId) {
                this.fuenteMultilateralSeleccionada = this.fuentesMultilateral.find(
                  item => item.id === this.datosItem.fuentesMultilateralesId
                );
                this.obtenerEstrategias("fuente");
                this.obtenerAgenciasMultilaterales(
                  this.datosItem.fuentesMultilateralesId
                ).then(() => {
                  if (this.agenciasMultilaterales.length > 0) {
                    this.agenciaMultilateralSeleccionada = this.agenciasMultilaterales.find(
                      item => item.id === this.datosItem.agenciaMultilateralId
                    );
                  }
                });
              }
            } else {
              this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(
                item => item.id === 3
              );
            }
          }
          //this.ddTiposInstrumentoLoading = false;
          setTimeout((this.skeletonLoading = false), 1500);
        })
        .catch(() => {
          this.skeletonLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async obtenerEstrategias(tipo) {
      let agenciaId = 0;
      if (tipo === "agencia") {
        agenciaId = this.agenciaSeleccionada.id;

        if (this.tipoFuenteCooperanteSeleccionada.id === 2)
          agenciaId = this.agenciaMultilateralSeleccionada.id;
      }
      /* let fuenteBilateralId = 0;
            let fuentesMultilateralId = 0;

            if(this.tipoFuenteCooperanteSeleccionada.id === 1){
                fuenteBilateralId = this.fuenteBilateralSeleccionada.id
            } else {
                fuentesMultilateralId: this.fuenteMultilateralSeleccionada.id;
            }
            */
      this.ddEstrategiasLoading = true;
      this.estrategiasCooperante = [];

      await this.$store
        .dispatch(OBTENER_ESTRATEGIAS_CNSC, {
          estadoId: 0,
          fuentesBilateralesId: this.fuenteBilateralSeleccionada.id
            ? this.fuenteBilateralSeleccionada.id
            : 0,
          agenciasId: agenciaId,
          fuentesMultilateralesId: this.fuenteMultilateralSeleccionada.id
            ? this.fuenteMultilateralSeleccionada.id
            : 0,
          cooperanteId: 0,
          tipoFuenteCooperante: this.tipoFuenteCooperanteSeleccionada.id
        })
        .then(res => {
          if (res.status === 200) {
            this.estrategiasCooperante = res.data;
          }
          this.ddEstrategiasLoading = false;
        })
        .catch(() => {
          this.estrategiasCooperante = [];
          this.ddEstrategiasLoading = false;
        });
    },

    //Obtener datos institucion
    async obtenerDatosInstitucion(institucionId) {
      this.datosInstitucion = {};
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_INSTITUCION, institucionId)
        .then(res => {
          if (res.status === 200) {
            this.datosInstitucion = res.data;

            this.tipoInstitucionSeleccionada = this.datosInstitucion.tiposInstitucionId;
            this.obtenerInstituciones(this.tipoInstitucionSeleccionada);
            if (this.tipoInstitucionSeleccionada === 1) {
              this.mostrarUnidadesEjecutoras = true;
            } else {
              this.mostrarUnidadesEjecutoras = false;
            }
          }
        })
        .catch(() => {
          this.datosInstitucion = {};
        });
    },

    async obtenerTiposInstitucion() {
      this.ddTiposInstitucionLoading = true;
      this.tiposInstitucion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposInstitucion/all/1"
        })
        .then((res) => {
          if (res.status === 200) {
            this.tiposInstitucion = res.data;
          }
          this.ddTiposInstitucionLoading = false;
        })
        .catch(() => {
          this.tiposInstitucion = [];
          this.ddTiposInstitucionLoading = false;
        });
    },

    //Obtener las instituciones
    async obtenerInstituciones(tipoInstitucionId) {
      if (tipoInstitucionId === 1) {
        this.mostrarUnidadesEjecutoras = true;
      } else {
        this.mostrarUnidadesEjecutoras = false;
      }
      this.ddInstitucionesLoading = true;

      this.instituciones = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then((res) => {
          if (res.status == 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
          this.ddInstitucionesLoading = false;
          this.instituciones = [];
        });
    },

    //Obtener las unidades ejecutoras
    async obtenerUnidadesEjecutorasPorInstitucion(institucionesId, tipo) {
      if (tipo === 1) {
        this.unidadEjecutoraSeleccionada = null;
        this.datosItem.unidadesEjecutorasId = 0;
      }
      this.unidadesEjecutorasLoading = true;
      this.unidadesEjecutoras = [];

      await this.$store
        .dispatch(OBTENER_UNIDADES_EJECUTORAS_ENTIDAD, institucionesId)
        .then((res) => {
          if (res.status === 200) {
            this.unidadesEjecutoras = res.data;
          }
          this.unidadesEjecutorasLoading = false;
        })
        .catch(() => {
          this.unidadesEjecutorasLoading = false;
        });
    },

    //Obtener los tipos de fuente cooperante
    obtenerTiposFuenteCooperante() {
      this.tiposFuenteCooperante = [];

      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposFuenteCooperante/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.tiposFuenteCooperante = res.data;
          }
        })
        .catch(() => {
          this.tiposFuenteCooperante = [];
        });
    },

    //Tipos de cooperacion
    async obtenerTiposCooperacion() {
      this.tiposCooperacion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposCooperacion/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.tiposCooperacion = res.data;
          }
        })
        .catch(() => {
          this.tiposCooperacion = [];
        });
    },

    async obtenerInstrumentos() {
      this.instrumentos = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Instrumentos/all/1" })
        .then((res) => {
          if (res.status === 200) {
            this.instrumentos = res.data;
          }
        })
        .catch(() => {
          this.instrumentos = [];
        });
    },

    async obtenerTiposInstrumentos() {
      //Validar que el instrumento sea una estrategia para habilitar la selección de la misma
      if (this.instrumentoSeleccionado.id === 2) {
        this.habilitarhabilitarSeleccionEstrategia(1);
      } else {
        this.habilitarhabilitarSeleccionEstrategia(2);
      }

      this.ddTiposInstrumentoLoading = true;
      this.tiposInstrumentos = [];
      // console.log(this.instrumentoSeleccionado.id)
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {
          endpoint: "TiposInstrumento/all/1",
          id: this.instrumentoSeleccionado.id,
        })
        .then((res) => {
          //  console.log(res)
          if (res.status === 200) {
            this.tiposInstrumentos = res.data;
          }
          this.ddTiposInstrumentoLoading = false;
        })
        .catch(() => {
          this.tiposInstrumentos = [];
          this.ddTiposInstrumentoLoading = false;
        });
    },

    //Habilitar la sección para seleccionar la estrategia
    habilitarhabilitarSeleccionEstrategia(accion) {
      if (accion === 1) {
        this.habilitarSeleccionEstrategia = true;
      } else {
        this.habilitarSeleccionEstrategia = false;
        this.datosItem.estrategiaCNSCId = null;
      }
    },

    //Obtener las modalidades de ejecución
    async obtenerModalidadesEjecucion() {
      this.modalidadesEjecucion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "ModalidadEjecucionCNS/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.modalidadesEjecucion = res.data;
          }
        })
        .catch(() => {
          this.modalidadesEjecucion = [];
        });
    },

    //Obtener las modalidades de ejecución
    async obtenerTiposAprobacionDonacion() {
      this.tiposAprobacionDonacion = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "TiposAprobacionDonacion/all/1",
        })
        .then((res) => {
          if (res.status === 200) {
            this.tiposAprobacionDonacion = res.data;
          }
        })
        .catch(() => {
          this.tiposAprobacionDonacion = [];
        });
    },

    async obtenerFuentesBilaterales() {
      this.fuentesBilaterales = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuentesBilaterales/all/1/0",
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesBilaterales = res.data;
          }
        })
        .catch(() => {
          this.fuentesBilaterales = [];
        });
    },

    async obtenerAgenciasPorFuenteBilateral() {
      this.agencias = [];
      this.ddAgenciasLoading = true;
      await this.$store
        .dispatch(OBTENER_SUBITEMS_CATALOGO, {
          endpoint: "Agencias/all/1",
          id: this.fuenteBilateralSeleccionada.id,
        })
        .then(res => {
          if (res.status === 200) {
            this.ddAgenciasLoading = false;
            this.agencias = res.data;
          }
        })
        .catch(() => {
          this.ddAgenciasLoading = false;
          this.agencias = [];
        });
    },

    // Obtener agencias Multilaterales
    async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
      this.agenciasMultilaterales = [];
      this.ddAgenciasMultiLoading = true;

      await this.$store
        .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {
          estadoId: 1,
          fuentesMultilateralesId: fuenteMultilateralId,
        })
        .then(res => {
          if (res.status === 200) {
            this.agenciasMultilaterales = res.data;
          }
          this.ddAgenciasMultiLoading = false;
        })
        .catch(() => {
          //console.log(error);
          this.ddAgenciasMultiLoading = false;
        });
    },

    async obtenerFuentesMultilateral() {
      this.fuentesMultilateral = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuenteMultiLateral/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesMultilateral = res.data;
          }
        })
        .catch(() => {
          this.fuentesMultilateral = [];
        });
    },

    configurarFuentes() {
      if (this.tipoFuenteCooperanteSeleccionada.id === 1) {
        this.fuenteMultilateralSeleccionada = {};
      } else if (this.tipoFuenteCooperanteSeleccionada.id === 2) {
        this.fuenteBilateralSeleccionada = {};
      } else if (this.tipoFuenteCooperanteSeleccionada.id == 3) {
        this.fuenteBilateralSeleccionada = {};
        this.fuenteMultilateralSeleccionada = {};
      }
    },

    //Registrar informacion general
    async registrarItem() {
      this.btnRegistroLoading = true;
      this.datosItem.tiposInstrumentoId = this.tiposInstrumentoSeleccionado.id;
      this.datosItem.institucionesId = this.institucionSeleccionada.id;
      this.datosItem.unidadesEjecutorasId = this.unidadEjecutoraSeleccionada.id;
      this.datosItem.tiposCooperacionId = this.tipoCooperacionSeleccionada.id;
      this.datosItem.modalidadesEjecucionId = this.modalidadEjecucionSeleccionada.id;
      this.datosItem.unidadesEjecutorasId = this.unidadEjecutoraSeleccionada.id;
      this.datosItem.unidadesEjecutorasId = this.unidadEjecutoraSeleccionada.id;
      this.datosItem.tiposAprobacionDonacionId = this.tipoAprobacionSeleccionada.id;
      this.datosItem.tiposFuenteCooperanteId = this.tipoFuenteCooperanteSeleccionada.id;

      /* if(!this.unidadEjecutoraSeleccionada.id){
                this.datosItem.unidadesEjecutorasId = null;
            } */

      this.datosItem.fuentesBilateralesId = this.fuenteBilateralSeleccionada.id;
      this.datosItem.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;
      this.datosItem.usuarioCreacion = "admin";
      this.datosItem.estadosId = 1;
      this.ingresaAPresupuestoSeleccionado.id === 2
        ? (this.datosItem.ingresaAPresupuesto = 2)
        : (this.datosItem.ingresaAPresupuesto = 1);

      if (this.instrumentoSeleccionado.id === 2) {
        if (
          !this.datosItem.estrategiaCNSCId ||
          this.datosItem.estrategiaCNSCId == 0 ||
          this.datosItem.estrategiaCNSCId == null
        ) {
          delete this.datosItem.estrategiaCNSCId;
        }
      } else {
        delete this.datosItem.estrategiaCNSCId;
      }

      //Eliminar unidadesEjecutorasId si el tipo de institución no es pública
      /* if(this.tipoInstitucionSeleccionada!=1){
                delete this.datosItem.unidadesEjecutorasId;
            } */

      //Verficar si la fuente cooperante es bilateral
      if (this.tipoFuenteCooperanteSeleccionada.id === 1) {
        //Si es bilateral, poner nulo el fuenteMultilateral
        this.datosItem.fuentesMultilateralesId = null;

        if (!this.agenciaSeleccionada || this.agenciaSeleccionada.id === 0) {
          //console.log("sdfas")
          this.datosItem.agenciasId = null;
        } else {
          this.datosItem.agenciasId = this.agenciaSeleccionada.id;
        }
      } else if (this.tipoFuenteCooperanteSeleccionada.id === 2) {
        //Si es multilateral poner nulos fuentesBilateralesId y agencias Id
        this.datosItem.fuentesBilateralesId = null;
        this.datosItem.agenciasId = null;

        if (
          !this.agenciaMultilateralSeleccionada ||
          this.agenciaMultilateralSeleccionada.id === 0
        ) {
          this.datosItem.agenciaMultilateralId = null;
        } else {
          this.datosItem.agenciaMultilateralId = this.agenciaMultilateralSeleccionada.id;
        }
      } else if (this.tipoFuenteCooperanteSeleccionada.id === 3) {
        this.datosItem.fuentesMultilateralesId = null;
        this.datosItem.fuentesBilateralesId = null;
        this.datosItem.agenciasId = null;
        this.datosItem.agenciaMultilateralId = null;
      }

      //this.datosItem.fechaSolicitudOpinionTecnica = moment(this.fechaSolicitudOpinionTecnica, "DD/MM/YYYY").format("YYYY-MM-DD");

      this.datosItem.fechaSolicitudOpinionTecnica = moment(
        this.computedDateFormatted,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      if (this.cuentaNumeroDonacion == 1) {
        this.datosItem.numeroDonaciones = "";
      }

      this.datosItem.id = 0;
      await this.$store
        .dispatch(REGISTRAR_PROYECTO_CNS_EXTERNO, {
          datos: this.datosItem,
          seccion: "InformacionGeneral",
          id: this.id,
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            //  this.switchItemEstado = true;
            this.btnRegistroLoading = false;
            //console.log( this.tipoCooperacionSeleccionada.id)
            this.$emit(
              "seccion-condiciones-desembolsos",
              this.tipoCooperacionSeleccionada.id
            );

            this.$emit("actualizar-avance-proyecto", this.id);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error, por favor, comuníquese con un administrador del sistema. REF: ${error}`
          );
          this.btnRegistroLoading = false;
        });
    },

    formatDate(date) {
      //console.log(date)
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },

  created() {
    this.resetItems(1, this.id);
  },

  computed: {
    computedDateFormatted: {
      get() {
        //console.log("asdf")
        return this.formatDate(this.date);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDate(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    }
  }
};
</script>
