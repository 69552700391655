var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Carga de documentos del proyecto ")])],1)],1),_c('v-row',{staticClass:"pb-3"},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-alert',{attrs:{"outlined":"","color":"#1488c2","border":"left"}},[_c('p',{staticClass:"mb-1"},[_vm._v(" Los campos marcados con un asterisco ( "),_c('span',{staticClass:"red--text",attrs:{"color":"red"}},[_vm._v("*")]),_vm._v(") son obligatorios ")])])],1)],1),_c('v-form',{ref:"formInstitucionesBeneficiarias",on:{"submit":function($event){$event.preventDefault();return _vm.actualizarCuentaOpinionTecnica.apply(null, arguments)}},model:{value:(_vm.validFormOpinionTecnica),callback:function ($$v) {_vm.validFormOpinionTecnica=$$v},expression:"validFormOpinionTecnica"}},[_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"7","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.cuentaOpinionTecniaItems,"loading":false,"disabled":_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas,"dense":"","filled":"","return-object":"","label":"¿El proyecto cuenta con opinión técnica?","item-text":"text","item-value":"id","menu-props":"auto"},model:{value:(_vm.cuentaOpinionTecnica),callback:function ($$v) {_vm.cuentaOpinionTecnica=$$v},expression:"cuentaOpinionTecnica"}})],1),(
            (_vm.tipoUsuario != "int" && !_vm.seccionesBloqueadas) ||
              _vm.tipoUsuario === "int"
          )?_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"5","sm":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","medium":"","elevation":0,"disabled":!_vm.validFormOpinionTecnica ||
                _vm.cuentaOpinionTecnica.id === 0 ||
                (_vm.tipoUsuario == "ext" && _vm.seccionesBloqueadas),"loading":_vm.btnRegistroCuentaOpinionTecnicaLoading}},[_vm._v(" Actualizar ")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[(
            (_vm.tipoUsuario === "ext" && !_vm.seccionesBloqueadas) ||
              _vm.tipoUsuario === "int"
          )?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.cargarArchivo.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.documentosCns,"loading":false,"dense":"","filled":"","return-object":"","label":"Seleccione el tipo de documento a cargar","item-text":"documento","item-value":"id","menu-props":"auto"},model:{value:(_vm.tipoDocumentoSeleccionado),callback:function ($$v) {_vm.tipoDocumentoSeleccionado=$$v},expression:"tipoDocumentoSeleccionado"}})],1),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[[_c('v-file-input',{ref:"docFile",staticClass:"required",attrs:{"id":"docFile","dense":"","filled":"","placeholder":"Seleccionar archivo .pdf","label":_vm.tipoDocumentoSeleccionado.documento
                      ? "Seleccionar documento para " +
                        _vm.tipoDocumentoSeleccionado.documento
                      : "Por favor, seleccione un tipo de documento","append-icon":"mdi-file-pdf-outline","prepend-icon":"","accept":"application/pdf,image/jpeg","rules":[_vm.fileRequired('documento')],"show-size":1000},on:{"change":_vm.onFileChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var index = ref.index;
                      var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"\n                                      text-overline\n                                      grey--text\n                                      text--darken-3\n                                      mx-2\n                                  "},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" archivo(s) ")]):_vm._e()]}}],null,false,1074909937)})]],2),_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" Agregar documento ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-alert',{attrs:{"outlined":"","type":"warning","color":"deep-orange lighten-1","dense":""}},[_vm._v(" El peso de los archivos no debe de superar los 20Mb ")])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-divider'),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text pb-0"},[_vm._v(" Documentos cargados ")])],1),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.documentosCargados,"loading":_vm.tableLoadingDocumentos,"hide-default-footer":"","footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}'
              }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.documento))]),_c('td',[_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":item.cargado === 0
                          ? 'deep-orange lighten-4'
                          : item.cargado > 0
                          ? 'teal lighten-4'
                          : 'pink lighten-4',"text-color":item.cargado === 0
                          ? 'deep-orange lighten-1'
                          : item.cargado > 0
                          ? 'teal lighten-1'
                          : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.cargado === 0 ? "Sin cargar" : "Cargado")+" ")])],1),_c('td',[(item.cargado > 0)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","disabled":_vm.btnEliminarLoading,"depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.descargarArchivo(item.path)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" Visualizar ")],1):_vm._e(),(
                        item.cargado > 0 &&
                          ((_vm.tipoUsuario != "int" && !_vm.seccionesBloqueadas) ||
                            _vm.tipoUsuario === "int")
                      )?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","disabled":_vm.btnEliminarLoading,"loading":_vm.btnEliminarLoading,"depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarArchivo(item.asignacionId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"text-h7"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" No existe información registrada de proyección de fuente cooperante ")],1)]},proxy:true}],null,false,1481147583)})],1)],1)],1)],1),_c('SnackAlert',{ref:"snackalert"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }