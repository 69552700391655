<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!skeletonLoading">
      <v-row>
        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
          <v-subheader class="text-h6 black--text">
            Contactos del proyecto
          </v-subheader>

          <v-divider class="mt-0 pt-0"></v-divider>
        </v-col>
      </v-row>

      <v-row class="pb-3">
        <v-col cols="12" md="12" sm="12">
          <v-alert outlined color="#1488c2" border="left">
            <p class="mb-1">
              Los campos marcados con un asterisco (<span
                color="red"
                class="red--text"
                >*</span
              >) son obligatorios
            </p>
          </v-alert>
        </v-col>
      </v-row>

      <v-card class="mt-2" outlined>
        <v-card-text>
          <v-form
            ref="formProyeccionDesembolsos"
            v-on:submit.prevent="registrarPosicion"
            v-model="validFormPosicion"
          >
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-subheader class="text-h6 black--text">
                  Información del proyecto
                </v-subheader>

                <v-divider class="mt-0 pt-0"></v-divider>
              </v-col>

              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosPosicion.direccionFisicaProyecto"
                  label="Dirección física del proyecto"
                  :rules="[
                    required('dirección física del proyecto'),
                    minLength('dirección física del proyecto', 5),
                    maxLength('dirección física del proyecto', 200)
                  ]"
                  maxlength="200"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosPosicion.telefonoDirectoProyecto"
                  label="Teléfono directo del proyecto"
                  :rules="[
                    required('teléfono'),
                    minLength('teléfono', 5),
                    maxLength('teléfono', 25)
                  ]"
                  maxlength="25"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row>
                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosPosicion.latitud"
                                label="Latitud"
                                :rules="[
                                    required('latitud'),
                                    minLength('latitud', 1),
                                    maxLength('latitud', 20),
                                    latitude('latitud')
                                ]"
                                maxlength="20"
                                ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosPosicion.longitud"
                                label="Longitud"
                                :rules="[
                                    required('longitud'),
                                    minLength('longitud', 1),
                                    maxLength('longitud', 20),
                                    longitude('latitud')
                                ]"
                                maxlength="20"
                                ></v-text-field>
                        </v-col>
                    </v-row> -->

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  large
                  :elevation="0"
                  :disabled="
                    !validFormPosicion ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  :loading="btnRegistroPosicionLoading"
                >
                  Guardar información
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <!--inicio:: condiciones de instituciones beneficiarias -->
      <v-card class="mt-6" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Contactos

                <v-spacer></v-spacer>

                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  small
                  :elevation="0"
                  @click="limpiarCamposContactos()"
                  v-if="
                    (tipoUsuario != `int` && !seccionesBloqueadas) ||
                      tipoUsuario === `int`
                  "
                >
                  Limpiar campos
                </v-btn>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formProyeccionDesembolsos"
            v-on:submit.prevent="registrarContactos"
            v-model="validForm"
            v-if="
              (tipoUsuario != `int` && !seccionesBloqueadas) ||
                tipoUsuario === `int`
            "
          >
            <v-row class="mt-6">
              <!--inicio:: campo -->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="tipoContactoSeleccionado"
                  :items="tiposContacto"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Seleccione el puesto del contacto"
                  item-text="tipo"
                  item-value="id"
                  :rules="[selectRequired('tipo de contacto')]"
                  :disabled="
                    accion === 2 ||
                      (tipoUsuario == `ext` && seccionesBloqueadas)
                  "
                  menu-props="auto"
                  return-object
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosContacto.nombre"
                  :label="
                    tipoContactoSeleccionado.id === 8
                      ? 'Nombre de la máxima autoridad'
                      : tipoContactoSeleccionado.id === 7
                      ? 'Nombre del gerente'
                      : 'Nombre'
                  "
                  :rules="[
                    required('nombre del contacto'),
                    minLength('nombre del contacto', 5),
                    maxLength('nombre del contacto', 100)
                  ]"
                  maxlength="100"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0 d-none">

                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class="required"
                                    color="blue-grey lighten-2"
                                    v-model="datosContacto.correoElectronicoPersonal"
                                    :label="tipoContactoSeleccionado.id === 8 ? 'Correo institucional de la máxima autoridad' : tipoContactoSeleccionado.id === 7 ? 'Correo institucional del gerente' : 'Correo institucional'"
                                    :rules="[
                                        required('correo electrónico'),
                                        minLength('correo electrónico', 5),
                                        maxLength('correo electrónico', 150),
                                        validEmail('correo electrónico')
                                    ]"
                                    maxlength="150"
                                    ></v-text-field>
                            </v-col> -->

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  color="blue-grey lighten-2"
                  autocomplete="off"
                  dense
                  class="required"
                  filled
                  v-model="datosContacto.telefonoInstitucional"
                  label="Teléfono institucional"
                  :rules="[
                    required('teléfono institucional'),
                    maxLength('teléfono institucional', 20)
                  ]"
                  maxlength="20"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosContacto.correoElectronicoInstitucional"
                  :label="
                    tipoContactoSeleccionado.id === 8
                      ? 'Correo institucional de la máxima autoridad'
                      : tipoContactoSeleccionado.id === 7
                      ? 'Correo institucional del gerente'
                      : 'Correo institucional'
                  "
                  :rules="[
                    required('correo electrónico'),
                    minLength('correo electrónico', 5),
                    maxLength('correo electrónico', 150),
                    validEmail('correo electrónico')
                  ]"
                  maxlength="150"
                  :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  large
                  :elevation="0"
                  :disabled="!validForm"
                  :loading="btnRegistroContactoLoading"
                >
                  {{
                    accion === 1 ? `Guardar información` : `Guardar información`
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pb-0">
              <v-subheader class="text-h6 black--text pb-0">
                Contactos registrados
              </v-subheader>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla de documentos cargados -->
            <v-col cols="12" md="12" sm="12" class="pt-4">
              <v-data-table
                class="elevation-1"
                :headers="headersContactos"
                :items="contactos"
                :loading="tableContactosLoading"
                hide-default-footer
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.tipo }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>
                      {{ item.telefonoInstitucional }}
                    </td>
                    <td>
                      {{ item.correoElectronicoInstitucional }}
                    </td>
                    <td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadosId === 1
                            ? 'blue lighten-4'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-4'
                            : item.estadosId === 6
                            ? 'cyan lighten-4'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadosId === 1
                            ? 'blue lighten-1'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-1'
                            : item.estadosId === 6
                            ? 'cyan darken-1'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="
                          item.estadosId == 1 &&
                            ((tipoUsuario != `int` && !seccionesBloqueadas) ||
                              tipoUsuario === `int`)
                        "
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarContacto(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar
                      </v-btn>

                      <v-btn
                        v-if="
                          item.estadosId == 1 &&
                            ((tipoUsuario != `int` && !seccionesBloqueadas) ||
                              tipoUsuario === `int`)
                        "
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarDisabled"
                        color="blue-grey lighten-5"
                        @click="obtenerDatosContacto(item.id)"
                      >
                        <v-icon left>mdi-pencil</v-icon> Editar
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->

      <!--Dialog loader -->
      <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
      ></DialogLoader>
      <!---->
    </div>
  </div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import {
  OBTENER_CONTACTOS,
  REGISTRAR_CONTACTO,
  OBTENER_DATOS_CONTACTO,
  ACTUALIZAR_CONTACTO,
  ELIMINAR_CONTACTO
} from "@/core/services/store/contactos/contacto.module";
import {
  OBTENER_DATOS_SECCIONES_PROYECTO,
  ACTUALIZAR_GEO_POSICION_CNS
} from "@/core/services/store/proyectoscns/proyectocns.module";

export default {
  name: "SeccionContactosProyectoCns",
  components: {
    DialogLoader,
    SnackAlert
  },
  props: ["id", "tipoUsuario", "confirmacion", "iaff", "iaffId"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar), iaff indica si viene de la vista del iaff, tiene que estar activo todo

  data() {
    return {
      accion: 1,
      skeletonLoading: false,
      validForm: false,
      validFormPosicion: false,
      btnRegistroPosicionLoading: false,
      datosProyecto: {},
      datosPosicion: {
        direccionFisicaProyecto: "",
        telefonoDirectoProyecto: "",
        latitud: "",
        longitud: ""
      },
      contactos: [],
      tableContactosLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      tiposContacto: [],
      tipoContactoSeleccionado: {},
      btnRegistroContactoLoading: false,
      btnEliminarDisabled: false,
      datosContacto: {
        nombre: "",
        telefonoInstitucional: "",
        correoElectronicoInstitucional: ""
      },
      datosItem: {
        nombreGerente: "",
        correoGerente: "",
        nombreMaximaAutoridad: "",
        correoMaximaAutoridad: "",
        direccionFisicaProyecto: "",
        telefonoDirectoProyecto: ""
      },
      datosIaff: {},
      seccionesBloqueadas: false,
      ...validations
    };
  },

  methods: {
    //Restablecer los campos
    //El tipo se usa para poner la animacion de todo el card (1) o solo la tabla (2)
    resetItems(tipo, proyectoId) {
      this.id = proyectoId;
      this.accion = 1;
      if (tipo === 1) {
        this.skeletonLoading = true;
      }
      if (tipo != 3) {
        this.tableContactosLoading = true;
      }

      this.skeletonLoading = true;
      this.tipoContactoSeleccionado = {};
      this.tipoContactoSeleccionado.id = 8;
      this.datosProyecto = {};

      this.obtenerDatosGeneralesProyecto(this.id);
      this.obtenerTiposContacto();
      this.obtenerContactos(this.id);
      this.limpiarCamposContactos();
    },

    limpiarCamposContactos() {
      this.tipoContactoSeleccionado = {};
      this.datosContacto = {
        nombre: "",
        cargosId: null,
        area: "",
        tipo: 2,
        correoElectronicoPersonal: null,
        telefonoPersonal: null,
        correoElectronicoInstitucional: "",
        telefonoInstitucional: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    //Obtener la información de cobertura
    async obtenerDatosGeneralesProyecto(proyectoId) {
      this.datosItem = {};
      this.skeletonLoading = true;

      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: proyectoId
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosProyecto = res.data;

            //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
            if (this.datosProyecto.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            if (this.iaff) {
              this.obtenerInformacionIafF();
            }

            this.datosPosicion.direccionFisicaProyecto = this.datosProyecto.direccionFisicaProyecto;
            this.datosPosicion.telefonoDirectoProyecto = this.datosProyecto.telefonoDirectoProyecto;
            this.datosPosicion.latitud = this.datosProyecto.latitud;
            this.datosPosicion.longitud = this.datosProyecto.longitud;
          }

          this.skeletonLoading = false;
          //this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
        })
        .catch(() => {
          this.skeletonLoading = false;
        });
    },

    //Obtener la información del iaff para validar su estado y habilitar o deshabilitar secciones
    async obtenerInformacionIafF() {
      this.datosIaff = {};

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `IAFFCNS/${this.iaffId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.datosIaff = res.data;
            if (this.datosIaff.estadosId === 4) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }
            //this.totalSubDivisionCooperante = this.subDivisiones.reduce((a,b) => a + (b['monto'] || 0), 0);
          }
        })
        .catch(() => {
          this.$emit(
            "ocultar-dialog-loader",
            "Ocurrió un error al intentar obtener la información del IAFF, por favor, póngase en contacto con un administrador del sistema."
          );
          this.datosIaff = {};
        });
    },

    //Obtener los tipos de contacto para proyectos CNS (2)
    async obtenerTiposContacto() {
      this.tiposContacto = [];
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "TipoContacto/all/1/2" })
        .then(res => {
          if (res.status === 200) {
            this.tiposContacto = res.data;
          }
        })
        .catch(() => {
          this.tiposContacto = [];
        });
    },

    //Obtener la información de contactos del proyecto
    //El tipo se usa para poner la animacion de todo el card (1) o solo la tabla (2)
    async obtenerContactos(proyectoId) {
      this.contactos = [];
      this.tableContactosLoading = true;

      this.$store
        .dispatch(OBTENER_CONTACTOS, {
          tipoContacto: "PROYECTOSCNSID",
          estadoId: 1,
          id: proyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.contactos = res.data;
          }

          this.tableContactosLoading = false;
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
          this.tableContactosLoading = false;
        });
    },

    async registrarContactos() {
      this.btnRegistroContactoLoading = true;
      this.datosContacto.tipo = 2;
      this.datosContacto.estadosId = 1;
      this.datosContacto.usuarioCreacion = "admin";
      this.datosContacto.tipoContactoId = this.tipoContactoSeleccionado.id;
      this.datosContacto.proyectosCNSId = this.id;

      let distpatch = REGISTRAR_CONTACTO;

      if (this.accion === 2) {
        distpatch = ACTUALIZAR_CONTACTO;
      }

      await this.$store
        .dispatch(distpatch, { datos: this.datosContacto })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerContactos(this.id);
            this.limpiarCamposContactos();
            this.accion = 1;
            this.$emit("actualizar-avance-proyecto", this.id);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroContactoLoading = false;
        })
        .catch(error => {
          this.btnRegistroContactoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Obtener la información de contactos del proyecto cns
    async obtenerDatosSeccion() {
      this.datosItem = {};
      this.cardDatosLoading = true;

      this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          seccion: "ContactosProyecto",
          id: 1
        })
        .then(() => {
          this.datosItem = this.$store.state.proyectocns.datosSeccion;
          this.cardDatosLoading = false;
          this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
        })
        .catch(() => {
          this.cardDatosLoading = false;
        });
    },

    //Obtener los datos de un contacto
    async obtenerDatosContacto(id) {
      this.datosContacto = {};
      this.dialogLoaderText = "Obteniendo información de contacto...";
      this.dialogLoaderVisible = true;

      this.$store
        .dispatch(OBTENER_DATOS_CONTACTO, id)
        .then(res => {
          if (res.status === 200) {
            this.datosContacto = res.data;

            this.tipoContactoSeleccionado = this.tiposContacto.find(
              item => item.id === this.datosContacto.tipoContactoId
            );
            this.accion = 2;
            //console.log(this.tipoContactoSeleccionado)
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `${res.message}`
            );
          }
          this.dialogLoaderVisible = false;
          //this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
        })
        .catch(() => {
          this.dialogLoaderVisible = false;
        });
    },

    //Eliminar contacto
    async eliminarContacto(id) {
      this.btnEliminarDisabled = true;
      await this.$store
        .dispatch(ELIMINAR_CONTACTO, id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerContactos(this.id);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarDisabled = false;
        })
        .catch(error => {
          this.btnEliminarDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    async registrarPosicion() {
      // return 0;

      this.btnRegistroPosicionLoading = true;
      //this.datosPoliticaPublica.proyectosCNSId = this.id;

      await this.$store
        .dispatch(ACTUALIZAR_GEO_POSICION_CNS, {
          id: this.id,
          datos: this.datosPosicion
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroPosicionLoading = false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la actualización. ${error}`
          );
          this.btnRegistroPosicionLoading = false;
        });
    }
  },
  created() {
    this.resetItems(1, this.id);
    //   this.proyectoId = this.id;
  },

  computed: {
    headersContactos() {
      return [
        {
          text: "Id",
          //align: "start",
          sortable: false,
          value: "id",
          align: " d-none"
        },
        {
          text: "Puesto",
          align: "start",
          sortable: true,
          value: "tipo"
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre"
        },
        {
          text: "Teléfono institucional",
          align: "start",
          sortable: true,
          value: "telefonoInstitucional"
        },
        {
          text: "Correo institucional",
          align: "start",
          sortable: true,
          value: "correo"
        },

        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
