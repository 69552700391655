<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
        <v-subheader class="text-h5 black--text">
          Vinculación de proyectos a cobertura y su vinculación a instrumentos
          de planificación
        </v-subheader>
      </v-col>
    </v-row>

    <v-row class="pb-3">
      <v-col cols="12" md="12" sm="12">
        <v-alert outlined color="#1488c2" border="left">
          <p>
            Los campos marcados con un asterisco (<span
              color="red"
              class="red--text"
              >*</span
            >) son obligatorios
          </p>
        </v-alert>
      </v-col>
    </v-row>

    <v-card class="mt-2" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Sector beneficiado
            </v-subheader>
          </v-col>
        </v-row>

        <v-form
          ref="formSectorBeneficiado"
          v-on:submit.prevent="actualizarSectorBeneficiado"
          v-model="validFormSectores"
        >
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosSectorBeneficiado.sectoresId"
                :items="sectoresBeneficiados"
                :loading="sectoresBeneficiadosLoading"
                dense
                filled
                label="Seleccione el sector principal que beneficia"
                item-text="sector"
                item-value="id"
                :no-data-text="
                  sectoresBeneficiados > 0
                    ? 'Seleccione un sector beneficiado'
                    : 'No se han encontrado sectores beneficiados'
                "
                menu-props="auto"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="!seccionesBloqueadas || tipoUsuario == `int`">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="!validFormSectores"
                :loading="btnRegistroSectoresLoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- inicio:: CAD CRS ETC-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Vinculación estratégica a instrumentos territoriales
              <!--Vinculación a otros instrumentos de planificación-->
            </v-subheader>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-on:submit.prevent="actualizarOtrosInstrumentos"
          v-model="validFormOtrosInstrumentos"
          class="pt-4 pl-2 pr-2"
        >
          <v-row class="mt-4">
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosOtrosInstrumentos.aportaPDD"
                class="required"
                :items="aportaPddItems"
                :loading="false"
                dense
                filled
                label="El proyecto aporta al Plan de Desarrollo Departamental (PDD)"
                item-text="text"
                item-value="id"
                menu-props="auto"
                :rules="[
                  selectRequired('Plan de Desarrollo Departamental (PDD)')
                ]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosOtrosInstrumentos.aportaPDM"
                class="required"
                :items="aportaPdmItems"
                :loading="false"
                dense
                filled
                label="El proyecto aporta a Planes de Desarrollo Municipal y Ordenamiento Territorial (PDM-OT)"
                item-text="text"
                item-value="id"
                menu-props="auto"
                :rules="[
                  selectRequired(
                    'Planes de Desarrollo Municipal y Ordenamiento Territorial (PDM-OT)'
                  )
                ]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosOtrosInstrumentos.vinculadoPEI"
                class="required"
                :items="vinculaPeiItems"
                :loading="false"
                dense
                filled
                label="El proyecto se vincula al Plan Estratégico Institucional (PEI)"
                item-text="text"
                item-value="id"
                menu-props="auto"
                :rules="[
                  selectRequired('Plan Estratégico Institucional (PEI)')
                ]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosOtrosInstrumentos.vinculadoPOA"
                class="required"
                :items="vinculaPoaItems"
                :loading="false"
                dense
                filled
                label="El proyecto se vincula al Plan Operativo Anual (POA)"
                item-text="text"
                item-value="id"
                menu-props="auto"
                :rules="[selectRequired('Plan Operativo Anual (POA)')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="!seccionesBloqueadas || tipoUsuario == `int`">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="!validFormOtrosInstrumentos"
                :loading="btnRegistroOtrosInstrumentosLoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- fin:: CAD CRS ETC-->

    <!-- inicio:: datos de Vinculación estratégica -->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Vinculación estratégica a instrumentos nacionales de planificación
              <!--Vinculación del proyecto a los instrumentos de planificación-->
            </v-subheader>
          </v-col>
          <!-- <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                    <v-btn
                        color="blue-grey lighten-5"
                        small
                        class="btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize float-right mt-2"
                        type="submit"
                        :elevation="0"
                        @click="resetItems(3)"
                    >
                        Limpiar campos
                    </v-btn>
                </v-col> -->
        </v-row>

        <v-form
          ref="formCobertura"
          v-on:submit.prevent="registrarCobertura"
          v-model="validForm"
        >
          <!-- <v-row class="mt-4">

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.departamentosId"
                                :items="departamentos"
                                :loading="departamentosLoading"
                                dense
                                class="required"
                                filled
                                label="Departamento"
                                item-text="departamento"
                                item-value="id"
                                @change="obtenerMunicipiosPorDepartamento(datosCobertura.departamentosId, 1)"
                                :no-data-text="
                                    departamentos != null
                                    ? 'Seleccione un departamento'
                                    : 'No se han encontradodepartamentos'
                                "
                                menu-props="auto"
                                :rules="[
                                    selectRequired('departamento')
                                ]"
                                >
                            </v-select>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="datosCobertura.municipiosId"
                                :items="municipios"
                                :loading="municipiosLoading"
                                dense
                                class=""
                                filled
                                label="Municipio"
                                item-text="municipio"
                                item-value="id"
                                :no-data-text="
                                    municipios != null
                                    ? 'Seleccione un municipio'
                                    : 'No se han encontrado municipios'
                                "
                                menu-props="auto"
                                >
                            </v-select>
                        </v-col>

                    </v-row> -->

          <v-row class="mt-6">
            <!--inicio:: pnd -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.pndId"
                :items="pnds"
                :loading="ddPndLoading"
                dense
                class="required"
                filled
                label="Prioridad Nacional de Desarrollo"
                item-text="nombrePND"
                item-value="id"
                @change="obtenerMetasPnd(datosCobertura.pndId)"
                :no-data-text="
                  pnds != null
                    ? 'Seleccione Prioridad Nacional de Desarrollo'
                    : 'No se han encontrado Prioridades Nacionales de Desarrollo'
                "
                menu-props="auto"
                :rules="[selectRequired('pnd')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.metaPNDId"
                :items="metasPnd"
                :loading="ddMetasPndLoading"
                dense
                class="required"
                filled
                label="Meta Prioridad Nacional de Desarrollo"
                item-text="meta"
                item-value="id"
                :no-data-text="
                  metasPnd != null
                    ? 'Seleccione una Meta Prioridad Nacional de Desarrollo'
                    : 'No se han encontrado Metas Prioridad Nacional de Desarrollo'
                "
                menu-props="auto"
                :rules="[selectRequired('meta pnd')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <!--fin :: pnd -->
          </v-row>

          <v-row>
            <!--inicio:: katun -->
            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.ejesKatunId"
                :items="ejesKatun"
                :loading="ddEjesKatunLoading"
                dense
                class="required"
                filled
                label="Eje K´atun"
                item-text="nombreEje"
                item-value="id"
                @change="obtenerPrioridadesKatun(datosCobertura.ejesKatunId)"
                :no-data-text="
                  ejesKatun != null
                    ? 'Seleccione Eje K´atun'
                    : 'No se han encontrado Ejes K´atun'
                "
                menu-props="auto"
                :rules="[selectRequired('Eje K´atun')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.prioridadesKatunId"
                :items="prioridadesKatun"
                :loading="ddPrioridadesKatunLoading"
                dense
                class="required"
                filled
                label="Prioridad K´atun"
                item-text="prioridad"
                item-value="id"
                @change="obtenerMetasKatun(datosCobertura.prioridadesKatunId)"
                :no-data-text="
                  prioridadesKatun != null
                    ? 'Seleccione una prioridad K´atun'
                    : 'No se han encontrado prioridades K´atun'
                "
                menu-props="auto"
                :rules="[selectRequired('prioridad K´atun')]"
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.metasKatunId"
                :items="metasKatun"
                :loading="ddMetasKatunLoading"
                dense
                class="required"
                filled
                label="Meta K´atun"
                item-text="meta"
                item-value="id"
                :no-data-text="
                  metasKatun != null
                    ? 'Seleccione una meta K´atun'
                    : 'No se han encontrado metas K´atun'
                "
                menu-props="auto"
                :rules="[selectRequired('Meta k´atun')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <!--fin :: katun -->
          </v-row>

          <v-row>
            <!--inicio:: pgg -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.pggId"
                :items="pggs"
                :loading="ddPggLoading"
                dense
                class="required"
                filled
                label="Política General de Gobierno"
                item-text="nombrePGG"
                item-value="id"
                @change="obtenerPilaresPgg(datosCobertura.pggId)"
                :no-data-text="
                  pggs != null
                    ? 'Seleccione Política General de Gobierno'
                    : 'No se han encontrado Políticas Generales de Gobierno'
                "
                menu-props="auto"
                :rules="[selectRequired('Política General de Gobierno')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <!--inicio:: pgg -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.pilarPGGId"
                :clearable="true"
                :items="pilaresPgg"
                :loading="ddPilaresPggLoading"
                dense
                class=""
                filled
                label="Pilar Política General de Gobierno"
                item-text="pilar"
                item-value="id"
                @change="obtenerMetasEstrategicas(datosCobertura.pilarPGGId)"
                :no-data-text="
                  pilaresPgg != null
                    ? 'Seleccione un Pilar Política General de Gobierno'
                    : 'No se han encontrado Pilares Política General de Gobierno'
                "
                menu-props="auto"
                :rules="[
                  /*selectRequired('Pilar Política General de Gobierno')*/
                ]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <!--inicio:: metas estrategucas pgg -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.metasEstrategicasPGGId"
                :clearable="true"
                :items="metasEstrategicasPgg"
                :loading="ddMetasEstrategicasPggLoading"
                dense
                class=""
                filled
                label="Meta estratégica Política General de Gobierno"
                item-text="meta"
                item-value="id"
                @change="
                  obtenerObjetivosEstrategicosPgg(
                    datosCobertura.metasEstrategicasPGGId
                  )
                "
                :no-data-text="
                  metasEstrategicasPgg != null
                    ? 'Seleccione una Meta estratégica Política General de Gobierno'
                    : 'No se han encontrado Metas estratégicas Política General de Gobierno'
                "
                menu-props="auto"
                :rules="[
                  // selectRequired(
                  // 'Meta estrategica Política General de Gobiernogg'
                  //),
                ]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <!--inicio:: objetivos estrategucos pgg -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.objetivosEstrategicosPGGId"
                :clearable="true"
                :items="objetivosEstrategicosPgg"
                :loading="ddObjetivosEstrategicosPggLoading"
                dense
                filled
                label="Objetivo estratégico Política General de Gobierno"
                item-text="objetivo"
                item-value="id"
                @change="
                  obtenerObjetivosSectorialessPgg(
                    datosCobertura.objetivosEstrategicosPGGId
                  )
                "
                :no-data-text="
                  objetivosEstrategicosPgg != null
                    ? 'Seleccione un objetivo estratégico Política General de Gobierno'
                    : 'No se han encontrado objetivos estratégicos Política General de Gobierno'
                "
                menu-props="auto"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <!--inicio:: objetivos sectoriales pgg -->
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCobertura.objetivosSectorialesPGGId"
                :clearable="true"
                :items="objetivosSectorialesPgg"
                :loading="ddObjetivosSectorialesPggLoading"
                dense
                filled
                label="Objetivo sectorial Política General de Gobierno"
                item-text="objetivo"
                item-value="id"
                :no-data-text="
                  objetivosSectorialesPgg != null
                    ? 'Seleccione un objetivo sectorial Política General de Gobierno'
                    : 'No se han encontrado objetivos sectoriales Política General de Gobierno'
                "
                menu-props="auto"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>
          </v-row>
          <!--fin: pgg -->

          <!--inicio:: monto -->
          <!-- <v-row>
                        
                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                v-model="datosCobertura.montoSuscrito"
                                dense
                                rows="3"
                                class="required"
                                filled
                                label="Monto suscrito por departamento / municipio"
                                menu-props="auto"
                                :rules="[
                                    required('monto suscrito'),
                                    minLength('monto suscrito', 1),
                                    maxLength('monto suscrito', 15),
                                    decimals('monto suscrito')
                                ]"
                                >
                            </v-text-field>
                        </v-col>

                    </v-row> -->
          <!--fin:: monto -->

          <v-row v-if="!seccionesBloqueadas || tipoUsuario == `int`">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="!validForm"
                :loading="btnRegistroLoading"
              >
                {{
                  vinculacionRegistrada
                    ? `Guardar información`
                    : `Guardar información`
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="mt-4 d-none">
          <v-col cols="12" md="12" sm="12">
            <v-subheader class="text-subtitle-1 black---text">
              Cobertura registrada
            </v-subheader>
            <v-divider class="mt-0 pt-0"></v-divider>
          </v-col>
        </v-row>

        <v-row class="d-none">
          <!--inicio:: tabla de documentos cargados -->
          <v-col cols="12" md="12" sm="12" class="pt-4">
            <v-data-table
              class="elevation-1"
              :headers="headersCoberturas"
              :items="coberturas"
              :loading="tableCoberturaLoading"
              hide-default-footer
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}',
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <!-- <td>{{ item.municipio ? item.municipio + ", " :  ""}} {{item.departamento}}</td> -->
                  <td>{{ item.metaPND }}</td>
                  <td>{{ item.metaODS }}</td>
                  <td>{{ item.metaKatun }}</td>
                  <!--<td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>-->
                  <!-- <td> {{parseFloat(item.montoSuscrito).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</td> -->
                  <td>
                    <v-btn
                      v-if="item.estadosId == 1"
                      class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                      small
                      depressed
                      :disabled="btnEliminarCoberturaDisabled"
                      :loading="btnEliminarCoberturaDisabled"
                      color="blue-grey lighten-5"
                      @click="eliminarItem(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon> Eliminar cobertura
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin:: datos de cobertura -->

    <!-- inicio:: Politica pública-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Política pública
            </v-subheader>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-on:submit.prevent="actualizarPoliticaPublica"
          v-model="validFormPoliticaPublica"
          class="pt-4 pl-2 pr-2"
        >
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosPoliticaPublica.politicaPublicaId"
                class=""
                :items="politicasPublicas"
                :loading="politicaPublicaLoading"
                dense
                filled
                label="Política pública"
                item-text="politica"
                item-value="id"
                :no-data-text="
                  politicasPublicas != null
                    ? 'Seleccione un polítca pública'
                    : 'No se han encontrado polítcas públicas'
                "
                menu-props="auto"
                :rules="[selectRequired('Política pública')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="!seccionesBloqueadas || tipoUsuario == `int`">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="!validFormPoliticaPublica"
                :loading="btnRegistroPoliticaPublicaLoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- fin:: Politica pública-->

    <!-- inicio:: CAD CRS ETC-->
    <v-card class="mt-6" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
            <v-subheader class="text-h6 black--text">
              Vinculación estratégica a instrumentos internacionales
              <!--Vinculación del proyecto al sector de la Organización para la Cooperación y el Desarrollo Económicos (OCDE) -->
            </v-subheader>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-on:submit.prevent="actualizarCRS"
          v-model="validFormOCDE"
          class="pt-4 pl-2 pr-2"
        >
          <v-row class="mt-4">
            <!--inicio:: ods -->
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCRS.odsId"
                :items="ods"
                :loading="ddOdsLoading"
                dense
                class="required"
                filled
                label="Objetivo de Desarrollo Sostenible"
                item-text="nombreODS"
                item-value="id"
                @change="obtenerMetasOds(datosCRS.odsId)"
                :no-data-text="
                  pnds != null
                    ? 'Seleccione Objetivo de Desarrollo Sostenible'
                    : 'No se han encontrado Objetivos de Desarrollo Sostenible'
                "
                menu-props="auto"
                :rules="[
                  selectRequired('Objetivo de Desarrollo Sostenible primario'),
                ]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCRS.metasODSId"
                :items="metasOds"
                :loading="ddMetasOdsLoading"
                dense
                class="required"
                filled
                label="Meta Objetivo de Desarrollo Sostenible"
                item-text="meta"
                item-value="id"
                :no-data-text="
                  metasOds != null
                    ? 'Seleccione una Meta Objetivo de Desarrollo Sostenible'
                    : 'No se han encontrado Meta Objetivo de Desarrollo Sostenible'
                "
                menu-props="auto"
                :rules="[selectRequired('meta ods')]"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              >
              </v-select>
            </v-col>

            <!--fin :: ods -->

            <!-- inicio:: ods secundario-->
            <!-- <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCRS.odsSecundarioId"
                :items="ods"
                :loading="ddOdsLoading"
                :clearable="true"
                dense
                filled
                label="Objetivo de Desarrollo Sostenible secundario"
                item-text="nombreODS"
                item-value="id"
                :no-data-text="
                  pnds != null
                    ? 'Seleccione Objetivo de Desarrollo Sostenible secundario'
                    : 'No se han encontrado Objetivos de Desarrollo Sostenible secundario'
                "
                menu-props="auto"
              >
              </v-select>
            </v-col> -->

            <!--fin :: ods -->
          </v-row>

          <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="cadSeleccionado"
                @change="obtenerCrsPorCadSeleccionado"
                :clearable="true"
                :items="cads"
                class=""
                :loading="cadsLoading"
                dense
                filled
                label="Comité de Ayuda al Desarrollo (CAD)"
                item-text="nombreCAD"
                item-value="id"
                return-object
                :no-data-text="
                  cads != null
                    ? 'Seleccione un CAD'
                    : 'No se han encontrado CADS'
                "
                menu-props="auto"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-select
                v-model="datosCRS.crsId"
                :clearable="true"
                class=""
                :items="crs"
                :loading="crsLoading"
                dense
                filled
                label="Estándar común de informes (CRS)"
                item-text="nombreCRS"
                item-value="id"
                :no-data-text="
                  crs != null ? 'Seleccione un CRS' : 'No se han encontrado CRS'
                "
                menu-props="auto"
                :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="!seccionesBloqueadas || tipoUsuario == `int`">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-btn
                color="light-blue-502"
                class="white--text mb-2 float-right mt-2"
                type="submit"
                :elevation="0"
                :disabled="!validFormOCDE"
                :loading="btnRegistroOCDELoading"
              >
                Guardar información
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- fin:: CAD CRS ETC-->

    <AsigOdsSecundarioComponent
      ref="AsigOdsSecundarioComponent"
      :proyectoId="id"
      :tipoProyecto="1"
      :tipoAccion="tipoAccion"
      :seccionesBloqueadas="seccionesBloqueadas"
      :tipoUsuario="tipoUsuario"
    />

    <!--inicio:: monto suscrito por actividad -->
    <v-card class="mt-6" outlined v-if="habilitarMontoTerritorial">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
            <v-subheader class="text-h6 black--text">
              Cobertura territorial
            </v-subheader>
          </v-col>
        </v-row>

        <v-row>
          <!--inicio:: tabla detalle de monto suscrito por departamento -->
          <v-col cols="12" md="12" sm="12" class="pt-4">
            <v-data-table
              dense
              class="elevation-1"
              :headers="headersMontoDepartamento"
              :items="montosDepartamento"
              :loading="tableLoadingMontosDepto"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}',
              }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.departamento }}</td>
                  <td class="text-right">
                    {{
                      parseFloat(item.monto).toLocaleString("es-GT", {
                        style: "currency",
                        currency: "GTQ",
                      })
                    }}
                  </td>
                  <!--<td>{{ item.tipoCambio }}</td>
                                    <td>{{ item.montoTotalQuetzales }}</td>-->

                  <!-- <td>
                                        <v-btn
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            :disabled="btnEliminarMontoDepartamentoDisabled"
                                            color="blue-grey lighten-5"
                                            @click="eliminarMontoDepartamento(item.id)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar
                                        </v-btn>
                                    </td> -->
                </tr>
              </template>

              <template v-slot:no-data>
                <p class="text-h7">
                  <v-icon left>mdi-alert</v-icon> No existe información
                  registrada de montos por departamento
                </p>
              </template>
            </v-data-table>

            <template v-slot:no-data>
              <p class="text-h7">
                <v-icon left>mdi-alert</v-icon> No existe información registrada
                de montos suscritos por departamento
              </p>
            </template>
          </v-col>
          <!--fin:: tabla detalle de monto suscrito por departamento -->

          <!-- inicio: total de la secion -->
          <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
            <v-list-item two-line>
              <v-list-item-content class="pb-0 pt-0">
                <v-list-item-title class="text-h6"
                  >Monto total</v-list-item-title
                >
                <v-list-item-subtitle class="text-h6">{{ parseFloat(totalMontoSuscritoDepartamento).toLocaleString("es-GT", { style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!--fin:: total de la seccion -->
        </v-row>

        <!-- <v-row v-if="1>2">

                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                    <v-select
                        v-model="cuentaMontoSuscritoActividadSeleccionado"
                        :items="cuentaMontoSuscritoActividadItems"
                        :loading="false"
                        dense
                        filled
                        class="required"
                        return-object   
                        label="¿Cuenta con detalle del monto suscrito por actividad?"
                        item-text="text"
                        item-value="id"
                        menu-props="auto"
                    ></v-select>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <v-divider class="mt-0 pt-0"></v-divider>
                </v-col>
            </v-row>

            <v-row>
                 <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                        v-model="departamentoActividadSeleccionado"
                        :items="departamentosActividad"
                        :loading="departamentosActividadLoading"
                        dense
                        class="required"
                        filled
                        label="Departamento"
                        item-text="departamento"
                        item-value="id"
                        return-object
                        @change="obtenerMunicipiosPorDepartamento(departamentoActividadSeleccionado.id,2)"
                        :no-data-text="
                            departamentos != null
                            ? 'Seleccione un departamento'
                            : 'No se han encontradodepartamentos'
                        "
                        menu-props="auto"
                        >
                    </v-select>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-select
                        v-model="municipioActividadSeleccionado"
                        :items="municipiosActividad"
                        :loading="municipiosActividadLoading"
                        dense
                        class=""
                        filled
                        label="Municipio"
                        item-text="municipio"
                        item-value="id"
                        return-object
                        :no-data-text="
                            municipios != null
                            ? 'Seleccione un municipio'
                            : 'No se han encontrado municipios'
                        "
                        menu-props="auto"
                        >
                    </v-select>
                </v-col>
            </v-row>-->

        <!--inicio:: monto -->
        <!--<v-row>
                
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                    <v-text-field
                        v-model="datosActividad.monto"
                        dense
                        rows="3"
                        class="required"
                        filled
                        label="Monto"
                        menu-props="auto"
                        :rules="[
                            required('monto'),
                            minLength('monto', 1),
                            maxLength('monto', 15),
                            decimals('monto')
                        ]"
                        >
                    </v-text-field>
                </v-col>

            </v-row>-->
        <!--fin:: monto -->

        <!--<v-row>
                 <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                                
                    <v-btn
                        color="light-blue-502"
                        class="white--text mb-2 float-right mt-2"
                        type="submit"
                        :elevation="0"
                        :disabled="!validForm"
                        :loading="btnRegistroLoading"
                    >
                        Agregar
                    </v-btn>
                </v-col>
            </v-row> -->
      </v-card-text>
    </v-card>

    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

//Seccion ods secundario
import AsigOdsSecundarioComponent from "./secciones/AsigOdsSecundarioComponent.vue";

import {
  OBTENER_DATOS_SECCIONES_PROYECTO,
  ACTUALIZAR_SECTOR_BENEFICIADO_CNS,
  ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES,
  ACTUALIZAR_OTROS_INSTRUMENTOS_CNS,
  ACTUALIZAR_POLITICA_PUBLICA_CNS,
} from "@/core/services/store/proyectoscns/proyectocns.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";

import {
  OBTENER_COBERTURAS_CNS,
  REGISTRAR_COBERTURA_CNS,
  ACTUALIZAR_COBERTURA_CNS,
  ELIMINAR_COBERTURA_CNS,
} from "@/core/services/store/proyectoscns/coberturas/coberturacns.module";

import { OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

export default {
  name: "SeccionCoberturaVinculacion",
  props: ["id", "tipoAccion", "tipoUsuario", "confirmacion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  data() {
    return {
      validFormSectores: false,
      btnRegistroSectoresLoading: false,

      validForm: false,

      validFormOCDE: false,
      btnRegistroOCDELoading: false,
      validFormOtrosInstrumentos: false,
      btnRegistroOtrosInstrumentosLoading: false,
      validFormPoliticaPublica: false,
      btnRegistroPoliticaPublicaLoading: false,
      btnRegistroLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      ddOdsLoading: false,
      btnEliminarCoberturaDisabled: false,
      sectoresBeneficiadosLoading: false,
      sectorBeneficiadoId: 0,
      sectoresBeneficiados: [],
      tableCoberturaLoading: false,
      coberturas: [],
      departamentosLoading: false,
      departamentoSeleccionado: {},
      departamentos: [],
      municipioSeleccionado: {},
      municipios: [],
      municipiosLoading: false,
      pndSeleccionada: {},
      ddPndLoading: false,
      ods: [],
      odsSeleccionada: {},
      ddMetasOdsLoading: false,
      metasOds: [],
      pnds: [],
      metaPndSeleccionada: {},
      ddMetasPndLoading: false,
      metasPnd: [],
      ejeKatunSeleccionado: {},
      ejesKatun: [],
      ddEjesKatunLoading: false,
      ddPrioridadesKatunLoading: false,
      prioridadesKatun: [],
      prioridadKatunSeleccionada: {},
      ddMetasKatunLoading: false,
      metasKatun: [],
      pggs: [],
      pggSeleccionada: {},
      ddPggLoading: false,
      pilaresPgg: [],
      pilarPggSeleccionado: {},
      ddPilaresPggLoading: false,
      ddMetasEstrategicasPggLoading: false,
      metasEstrategicasPgg: [],
      metaEstrategicaPggSeleccionada: {},
      objetivoEstrategicoPggSeleccionado: {},
      objetivosEstrategicosPgg: [],
      ddObjetivosEstrategicosPggLoading: false,
      objetivoSectorialSeleccionado: {},
      ddObjetivosSectorialesPggLoading: false,
      objetivosSectorialesPgg: [],

      municipiosActividadLoading: false,
      cadSeleccionado: {},
      cadsLoading: false,
      cads: [],
      crsSeleccionadoId: 0,
      crs: [],
      crsLoading: false,
      politicaPubilcaSeleccionada: {},
      politicasPublicas: [],
      politicaPublicaLoading: false,
      politicaPubilcaLoading: false,
      aportaPddSeleccionado: {},
      aportaPddItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      aportaPdmSeleccionado: 0,
      aportaPdmItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      vinculaPeiSeleccionado: 0,
      vinculaPeiItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      vinculaPoaSeleccionado: 0,
      vinculaPoaItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      cuentaMontoSuscritoActividadSeleccionado: 0,
      cuentaMontoSuscritoActividadItems: [
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      departamentosActividadLoading: false,
      departamentoActividadSeleccionado: {},
      departamentosActividad: [],
      municipioActividadSeleccionado: {},
      municipiosActividad: [],

      actividadesLoading: false,
      actividades: [],

      datosItem: {
        sectoresBeneficiadosId: 0
      },
      datosActividad: {
        departamentosId: 0,
        municipíosId: null,
        actividadesId: 0,
        monto: ""
      },
      datosCobertura: {
        id: 0,
        proyectosCNSId: this.id,
        //departamentosId: 0,
        //municipiosId: 0,
        pndId: 0,
        metaPNDId: 0,
        prioridadesKatunId: 0,
        ejesKatunId: 0,
        metasKatunId: 0,
        //odsId: 0,
        //metasODSId: 0,
        pggId: 0,
        pilarPGGId: 0,
        metasEstrategicasPGGId: 0,
        //odsSecundarioId: 0,
        objetivosSectorialesPGGId: 0,
        objetivosEstrategicosPGGId: 0,
        //montoSuscrito: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      datosSectorBeneficiado: {
        proyectosCNSId: this.id,
        sectoresId: 0,
        usuarioActualizacoin: "admin"
      },
      datosCRS: {
        proyectosCNSId: this.id,
        crsId: 0,
        odsId: 0,
        metasODSId: 0,
        odsSecundarioId: null,
        usuarioActualizacoin: "admin"
      },
      datosOtrosInstrumentos: {
        proyectosCNSId: this.id,
        aportaPDD: 0,
        aportaPDM: 0,
        vinculadoPEI: 0,
        vinculadoPOA: 0,
        usuarioActualizacoin: "admin"
      },
      datosPoliticaPublica: {
        proyectosCNSId: this.id,
        politicaPublicaId: 0,
        usuarioActualizacoin: "admin"
      },

      ejecucionesActividadTerritorio: [],
      tableLoadingEjecucionesTerritorio: false,
      totalEjecucionActividadTerritorio: 0,

      validFormClasificacion: false,

      montosDepartamento: [],
      tableLoadingMontosDepto: false,
      totalMontoSuscritoDepartamento: "0.00",

      vinculacionRegistrada: false,
      accionVinculacion: 1,
      habilitarMontoTerritorial: false,
      seccionesBloqueadas: false,
      ...validations

      //cadSeleccionado: {},
    };
  },

  components: {
    DialogLoader,
    SnackAlert,
    AsigOdsSecundarioComponent
  },

  methods: {
    //Restablecer los campos
    //El tipo se usa para poner la animacion de todo el card (1) o solo la tabla (2)
    resetItems(tipo, proyectoId) {
      this.id = proyectoId;
      if (tipo === 1) {
        this.skeletonLoading = true;
      }
      if (tipo != 3) {
        this.tableCoberturaLoading = true;
      }

      this.vinculacionRegistrada = false;
      this.accionVinculacion = 1;
      this.pndSeleccionada = {};
      this.odsSeleccionada = {};
      this.ejeKatunSeleccionado = {};
      this.prioridadKatunSeleccionada = {};
      this.pggSeleccionada = {};
      this.pilarPggSeleccionado = {};
      this.metaEstrategicaPggSeleccionada = {};
      this.objetivoEstrategicoPggSeleccionado = {};

      //datosCobertura.objetivosEstrategicosPGGId

      this.datosCobertura = {
        id: 0,
        proyectosCNSId: this.id,
        //departamentosId: 0,
        //municipiosId: 0,
        pndId: 0,
        metaPNDId: 0,
        prioridadesKatunId: 0,
        ejesKatunId: 0,
        metasKatunId: 0,
        //odsId: 0,
        //metasODSId: 0,
        pggId: 0,
        pilarPGGId: 0,
        metasEstrategicasPGGId: 0,
        odsSecundarioId: 0,
        objetivosSectorialesPGGId: 0,
        objetivosEstrategicosPGGId: 0,
        //montoSuscrito: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      };

      this.datosSectorBeneficiado = {
        proyectosCNSId: this.id,
        sectoresId: 0,
        usuarioActualizacoin: "admin"
      };

      this.datosCRS = {
        proyectosCNSId: this.id,
        crsId: 0,
        odsId: 0,
        metasODSId: 0,
        odsSecundarioId: null,
        usuarioActualizacoin: "admin"
      };
      this.cadSeleccionado = {};

      this.datosOtrosInstrumentos = {
        proyectosCNSId: this.id,
        aportaPDD: 0,
        aportaPDM: 0,
        vinculadoPEI: 0,
        vinculadoPOA: 0,
        usuarioActualizacoin: "admin"
      };

      this.datosPoliticaPublica = {
        proyectosCNSId: this.id,
        politicaPublicaId: 0,
        usuarioActualizacoin: "admin"
      };
      this.habilitarMontoTerritorial = false;

      this.obtenerDatosGeneralesProyecto(this.id).then(() => {
        this.obtenerCoberturaProyecto(this.id);
        this.obtenerMontosSuscritosDepto(this.id);
      });
      this.obtenerSectoresBeneficiados();
      this.obtenerDepartamentos();
      this.obtenerPnds();
      this.obtenerOds();
      this.obtenerEjesKatun();
      this.obtenerPgg();
      // this.obtenerActividades();

      this.obtenerPoliticasPublicas();
    },

    //Obtener la información de cobertura
    async obtenerDatosGeneralesProyecto(proyectoId) {
      this.datosItem = {};
      this.cardDatosLoading = true;

      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: proyectoId
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.datosItem = res.data;

            //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
            if (this.datosItem.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            this.datosItem.cuentaConOpinionTecnica
              ? (this.tipoProyectoId = this.datosItem.cuentaConOpinionTecnica)
              : (this.tipoProyectoId = 0);

            this.datosItem.sectoresId
              ? (this.datosSectorBeneficiado.sectoresId = this.datosItem.sectoresId)
              : (this.datosSectorBeneficiado.sectoresId = 0);

            this.datosItem.politicaPublicaId
              ? (this.datosPoliticaPublica.politicaPublicaId = this.datosItem.politicaPublicaId)
              : (this.datosPoliticaPublica.politicaPublicaId = 0);

            //Otros instrumentos de planificación
            this.datosItem.aportaPDD
              ? (this.datosOtrosInstrumentos.aportaPDD = this.datosItem.aportaPDD)
              : (this.datosOtrosInstrumentos.aportaPDD = 0);
            this.datosItem.aportaPDM
              ? (this.datosOtrosInstrumentos.aportaPDM = this.datosItem.aportaPDM)
              : (this.datosOtrosInstrumentos.aportaPDM = 0);
            this.datosItem.vinculadoPEI
              ? (this.datosOtrosInstrumentos.vinculadoPEI = this.datosItem.vinculadoPEI)
              : (this.datosOtrosInstrumentos.vinculadoPEI = 0);
            this.datosItem.vinculadoPOA
              ? (this.datosOtrosInstrumentos.vinculadoPOA = this.datosItem.vinculadoPOA)
              : (this.datosOtrosInstrumentos.vinculadoPOA = 0);


            //Habilitar/Deshabilitar proyección de montos a nivel territorial
            if (this.datosItem.detalleMontoPorDepartamento === 1) {
              this.habilitarMontoTerritorial = true;
            } else {
              this.habilitarMontoTerritorial = false;
            }
            //Asignar cad y crs

            this.obtenerCad().then(() => {
              if (this.datosItem.cadId) {
                this.cadSeleccionado = this.cads.find(
                  item => item.id === this.datosItem.cadId
                );

                if (this.cadSeleccionado.id != 0) {
                  this.obtenerCrsPorCadSeleccionado().then(() => {
                    this.datosCRS.crsId = this.datosItem.crsId;
                  });
                }
              }
            });
          }

          this.skeletonLoading = false;
          //this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
        })
        .catch(() => {
          this.skeletonLoading = false;
        });
    },

    //Sectores beneficiados
    async obtenerSectoresBeneficiados() {
      this.sectoresBeneficiadosLoading = true;
      this.sectoresBeneficiados = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Sectores/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.sectoresBeneficiados = res.data;
          }
          this.sectoresBeneficiadosLoading = false;
        })
        .catch(() => {
          this.sectoresBeneficiados = [];
          this.sectoresBeneficiadosLoading = false;
        });
    },

    //Obtener los departamentos
    async obtenerDepartamentos() {
      this.departamentosLoading = true;
      this.departamentosActividadLoading = true;
      this.departamentos = [];
      this.departamentosActividad = [];
      await this.$store
        .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
        .then(res => {
          if (res.status === 200) {
            this.departamentos = res.data;
            this.departamentosActividad = res.data;
          }
          this.departamentosLoading = false;
          this.departamentosActividadLoading = false;
        })
        .catch(() => {
          this.departamentos = [];
          this.departamentosActividad = [];
          this.departamentosLoading = false;
          this.departamentosActividadLoading = false;
        });
    },

    //Obtener municipios por departamento
    async obtenerMunicipiosPorDepartamento(departamentosId, tipo) {
      this.municipiosLoading = true;
      this.datosItem.municipiosId = null;
      let deptoId = 0;
      tipo === 1 ? (this.municipios = []) : (this.municipiosActividad = []);
      deptoId = departamentosId;

      await this.$store
        .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, deptoId) //1 representa al país 1 (Guatemala)
        .then(res => {
          if (res.status === 200) {
            tipo === 1
              ? (this.municipios = res.data)
              : (this.municipiosActividad = res.data);
          }
          tipo === 1
            ? (this.municipiosLoading = false)
            : (this.municipiosActividadLoading = false);
        })
        .catch(() => {
          tipo === 1 ? (this.municipios = []) : (this.municipiosActividad = []);
          tipo === 1
            ? (this.municipiosLoading = false)
            : (this.municipiosActividadLoading = false);
        });
    },

    //obtener las PNDS
    async obtenerPnds() {
      this.ddPndLoading = true;
      this.pnds = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "PND/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.pnds = res.data;
          }
          this.ddPndLoading = false;
        })
        .catch(() => {
          this.pnds = [];
          this.ddPndLoading = false;
        });
    },

    //obtenerMetasPnd por pnd id
    async obtenerMetasPnd(pndId) {
      this.ddMetasPndLoading = true;
      this.metasPnd = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `MetaPND/all/1/${pndId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.metasPnd = res.data;
          }
          this.ddMetasPndLoading = false;
        })
        .catch(() => {
          this.pnds = [];
          this.ddMetasPndLoading = false;
        });
    },

    //Obtener las ods
    async obtenerOds() {
      this.ddOdsLoading = true;
      this.ods = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `ODS/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.ods = res.data;
          }
          this.ddOdsLoading = false;
        })
        .catch(() => {
          this.pnds = [];
          this.ddOdsLoading = false;
        });
    },

    //Obtener metas ods por ods id
    async obtenerMetasOds(odsId) {
      this.ddMetasOdsLoading = true;
      this.metasOds = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `MetasODS/all/1/${odsId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.metasOds = res.data;
          }
          this.ddMetasOdsLoading = false;
        })
        .catch(() => {
          this.metasOds = [];
          this.ddMetasOdsLoading = false;
        });
    },

    //Obtener los ejes katun
    async obtenerEjesKatun() {
      this.ddEjesKatunLoading = true;
      this.ejesKatun = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `EjesKatun/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.ejesKatun = res.data;
          }
          this.ddEjesKatunLoading = false;
        })
        .catch(() => {
          this.ejesKatun = [];
          this.ddEjesKatunLoading = false;
        });
    },

    //Obtener las metas katun
    async obtenerPrioridadesKatun(katunId) {
      this.ddPrioridadesKatunLoading = true;
      this.prioridadesKatun = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `PrioridadesKatun/all/1/${katunId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.prioridadesKatun = res.data;
          }
          this.ddPrioridadesKatunLoading = false;
        })
        .catch(() => {
          this.prioridadesKatun = [];
          this.ddPrioridadesKatunLoading = false;
        });
    },

    //Obtener las metas katun
    async obtenerMetasKatun(prioridadKatunId) {
      this.ddMetasKatunLoading = true;
      this.datosCobertura.metasKatunId = 0;
      this.metasKatun = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `MetasKatun/all/1/${prioridadKatunId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.metasKatun = res.data;
          }
          this.ddMetasKatunLoading = false;
        })
        .catch(() => {
          this.prioridadesKatun = [];
          this.ddMetasKatunLoading = false;
        });
    },

    //Obtener pgg
    async obtenerPgg() {
      this.pggs = [];
      this.pilaresPgg = [];
      this.metasEstrategicasPgg = [];
      this.objetivosEstrategicosPgg = [];
      this.objetivosSectorialesPgg = [];

      this.pggSeleccionada = {};
      this.ddPggLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `PGG/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.pggs = res.data;
          }
          this.ddPggLoading = false;
        })
        .catch(() => {
          this.pggs = [];
          this.ddPggLoading = false;
        });
    },

    //Obtener los pilares segun pgg
    async obtenerPilaresPgg(pggId) {
      this.pilaresPgg = [];
      this.metasEstrategicasPgg = [];
      this.objetivosEstrategicosPgg = [];
      this.objetivosSectorialesPgg = [];

      this.pilarPggSeleccionado = {};
      this.ddPilaresPggLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `PilarPGG/all/1/${pggId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.pilaresPgg = res.data;
          }
          this.ddPilaresPggLoading = false;
        })
        .catch(() => {
          this.pilaresPgg = [];
          this.ddPilaresPggLoading = false;
        });
    },

    //Obtener las metas estrategicas pgg por pilar pgg id
    async obtenerMetasEstrategicas(pilarPggId) {
      this.metaEstrategicaPggSeleccionada = {};
      this.ddMetasEstrategicasPggLoading = true;
      this.objetivosEstrategicosPgg = [];
      this.objetivosSectorialesPgg = [];
      this.metasEstrategicasPgg = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `MetasEstrategicasPGG/all/1/${pilarPggId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.metasEstrategicasPgg = res.data;
          }
          this.ddMetasEstrategicasPggLoading = false;
        })
        .catch(() => {
          this.metasEstrategicasPgg = [];
          this.ddMetasEstrategicasPggLoading = false;
        });
    },

    //Obtener los objetivos estrategicos PGG por meta PGG id
    async obtenerObjetivosEstrategicosPgg(metaEstrategicaPggId) {
      this.objetivoEstrategicoPggSeleccionado = {};
      this.ddObjetivosEstrategicosPggLoading = true;
      this.objetivosEstrategicosPgg = [];
      this.objetivosSectorialesPgg = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ObjetivosEstrategicosPGG/all/1/${metaEstrategicaPggId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.objetivosEstrategicosPgg = res.data;
          }
          this.ddObjetivosEstrategicosPggLoading = false;
        })
        .catch(() => {
          this.objetivosEstrategicosPgg = [];
          this.ddObjetivosEstrategicosPggLoading = false;
        });
    },

    //Obteener los objetivos secotriales PGG por objetivo estrategico pgg Id
    async obtenerObjetivosSectorialessPgg(objetivoEstrategicoId) {
      this.ddObjetivosSectorialesPggLoading = true;
      //this.datosCobertura.ObjetivosSectorialesPGGId =0;
      this.objetivosSectorialesPgg = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ObjetivosSectorialesPGG/all/1/${objetivoEstrategicoId}`,
        })
        .then(res => {
          if (res.status === 200) {
            this.objetivosSectorialesPgg = res.data;
          }
          this.ddObjetivosSectorialesPggLoading = false;
        })
        .catch(() => {
          this.objetivosSectorialesPgg = [];
          this.ddObjetivosSectorialesPggLoading = false;
        });
    },

    //Registrar Datos de cobertura
    async registrarCobertura() {
      this.btnRegistroLoading = true;
      this.datosCobertura.estadosId = 1;
      this.datosCobertura.usuarioCreacion = "admin";
      this.datosCobertura.proyectosCNDId = this.id;

      let dispatch = REGISTRAR_COBERTURA_CNS;

      if (this.accionVinculacion === 2) {
        dispatch = ACTUALIZAR_COBERTURA_CNS;
        this.datosCobertura.id = this.coberturas[0].id;
      }

      if (this.datosCobertura.municipiosId === 0) {
        delete this.datosCobertura.municipiosId;
      }

      if (this.datosCobertura.objetivosEstrategicosPGGId === 0) {
        delete this.datosCobertura.objetivosEstrategicosPGGId;
      }

      if (this.datosCobertura.objetivosSectorialesPGGId === 0) {
        delete this.datosCobertura.objetivosSectorialesPGGId;
      }

      if (this.datosCobertura.odsSecundarioId === 0) {
        delete this.datosCobertura.odsSecundarioId;
      }

      //Validar monto que sea mayor a cero 00
      if (parseFloat(this.datosCobertura.monto) <= 0) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          "El monto debe ser mayor a 0.00"
        );
      }

      //console.log(this.datosCobertura);

      await this.$store
        .dispatch(dispatch, { datos: this.datosCobertura })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.accionVinculacion = 2;
            //this.obtenerCoberturaProyecto(this.id);
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          this.btnRegistroLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Eliminar registro de cobertura
    async eliminarItem(id) {
      this.btnEliminarCoberturaDisabled = true;

      await this.$store
        .dispatch(ELIMINAR_COBERTURA_CNS, id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerCoberturaProyecto(this.id);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarCoberturaDisabled = false;
        })
        .catch(error => {
          this.btnEliminarCoberturaDisabled = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    /*
        // Obtener los registros de cobertura almacenados para el proyecto
        */
    async obtenerCoberturaProyecto(proyectoId) {
      //Obtener los ods setOdsSecundarios
      if (this.$refs.AsigOdsSecundarioComponent) {
        this.$refs.AsigOdsSecundarioComponent.obtenerOds();
        this.$refs.AsigOdsSecundarioComponent.obtenerOdsRegistrados(1, this.id);
      }

      this.tableCoberturaLoading = true;
      this.coberturas = [];
      await this.$store
        .dispatch(OBTENER_COBERTURAS_CNS, { tipo: 1, id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.coberturas = res.data;
            this.datosCobertura.id = this.coberturas[0].id;

            //Vinculación internacional
            if (this.coberturas[0].odsId) {
              this.datosCRS.odsId = this.coberturas[0].odsId;
              this.obtenerMetasOds(this.datosCRS.odsId).then(() => {
                if (this.coberturas[0].metasODSId) {
                  this.datosCRS.metasODSId = this.coberturas[0].metasODSId;
                }
              });
            }

            if (this.vinculacionRegistrada == false) {
              this.datosCobertura.pndId = this.coberturas[0].pndId;
              this.obtenerMetasPnd(this.datosCobertura.pndId).then(() => {
                this.datosCobertura.metaPNDId = this.coberturas[0].metaPNDId;
              });

              /*
                        
                        this.datosCobertura.odsId = this.coberturas[0].odsId;
                        this.obtenerMetasOds(this.datosCobertura.odsId).then(()=>{
                            this.datosCobertura.metasODSId = this.coberturas[0].metasODSId;
                        });
                        */

              this.datosCobertura.odsSecundarioId = this.coberturas[0].odsSecundarioId;
              this.datosCobertura.ejesKatunId = this.coberturas[0].ejesKatunId;
              this.obtenerPrioridadesKatun(
                this.datosCobertura.ejesKatunId
              ).then(() => {
                this.datosCobertura.prioridadesKatunId = this.coberturas[0].prioridadesKatunId;

                this.obtenerMetasKatun(
                  this.datosCobertura.prioridadesKatunId
                ).then(() => {
                  this.datosCobertura.metasKatunId = this.coberturas[0].metasKatunId;
                });
              });

              this.datosCobertura.pggId = this.coberturas[0].pggId;
              this.obtenerPilaresPgg(this.datosCobertura.pggId).then(() => {
                this.datosCobertura.pilarPGGId = this.coberturas[0].pilarPGGId;

                this.obtenerMetasEstrategicas(
                  this.datosCobertura.pilarPGGId
                ).then(() => {
                  if (this.coberturas[0].metasEstrategicasPGGId) {
                    this.datosCobertura.metasEstrategicasPGGId = this.coberturas[0].metasEstrategicasPGGId;

                    this.obtenerObjetivosEstrategicosPgg(
                      this.datosCobertura.metasEstrategicasPGGId
                    ).then(() => {
                      if (this.coberturas[0].objetivosEstrategicosPGGId) {
                        this.datosCobertura.objetivosEstrategicosPGGId = this.coberturas[0].objetivosEstrategicosPGGId;
                      } else {
                        this.datosCobertura.objetivosEstrategicosPGGId = null;
                      }

                      if (this.coberturas[0].objetivosSectorialesPGGId) {
                        this.obtenerObjetivosSectorialessPgg(
                          this.datosCobertura.objetivosEstrategicosPGGId
                        ).then(() => {
                          this.datosCobertura.objetivosSectorialesPGGId = this.coberturas[0].objetivosSectorialesPGGId;
                        });
                      } else {
                        this.datosCobertura.objetivosSectorialesPGGId = null;
                      }
                    });
                  } else {
                    this.datosCobertura.metasEstrategicasPGGId = null;
                    this.datosCobertura.objetivosEstrategicosPGGId = null;
                  }
                });
              });
            }

            this.vinculacionRegistrada = true;
            this.accionVinculacion = 2;
          } else {
            this.vinculacionRegistrada = false;
            this.accionVinculacion = 1;
          }
          this.tableCoberturaLoading = false;
        })
        .catch(() => {
          this.vinculacionRegistrada = false;
          this.coberturas = [];
          this.tableCoberturaLoading = false;
        });
    },

    //Obtener Acvitidades
    async obtenerActividades() {
      this.actividadesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Actividades" })
        .then(res => {
          if (res.status === 200) {
            this.actividades = res.data;
          }
          this.actividadesLoading = false;
        })
        .catch(() => {
          this.actividades = [];
          this.actividadesLoading = false;
        });
    },

    //Obtener CAD
    async obtenerCad() {
      this.cads = [];
      this.cadsLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `CAD/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.cads = res.data;
          }
          this.cadsLoading = false;
        })
        .catch(() => {
          this.cads = [];
          this.cadsLoading = false;
        });
    },

    //Obtener CRS por Cad
    async obtenerCrsPorCadSeleccionado() {
      this.crs = [];

      if (this.cadSeleccionado != null) {
        this.crsLoading = true;
        await this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, {
            endpoint: `CRS/all/1/${this.cadSeleccionado.id}`,
          })
          .then(res => {
            if (res.status === 200) {
              this.crs = res.data;
            }
            this.crsLoading = false;
          })
          .catch(() => {
            this.crs = [];
            this.crsLoading = false;
          });
      } else {
        this.datosCRS.crsId = 0;
      }
    },

    //Obtener las politicas públicas
    async obtenerPoliticasPublicas() {
      this.politicaPublicaLoading = true;
      this.politicasPublicas = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `PoliticaPublica/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.politicasPublicas = res.data;
          }
          this.politicaPublicaLoading = false;
        })
        .catch(() => {
          this.politicasPublicas = [];
          this.politicaPublicaLoading = false;
        });
    },

    async actualizarSectorBeneficiado() {
      this.btnRegistroSectoresLoading = true;
      this.datosSectorBeneficiado.proyectosCNSId = this.id;

      await this.$store
        .dispatch(ACTUALIZAR_SECTOR_BENEFICIADO_CNS, {
          id: this.id,
          datos: this.datosSectorBeneficiado,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroSectoresLoading = false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la actualización. ${error}`
          );
          this.btnRegistroSectoresLoading = false;
        });
    },

    async actualizarCRS() {
      this.btnRegistroOCDELoading = true;
      this.datosCRS.proyectosCNSId = this.id;
      if (this.cadSeleccionado != null) {
        this.datosCRS.cadId = this.cadSeleccionado.id;
      }
      else {
        this.datosCRS.cadId = null;
        this.datosCRS.crsId = null;
      }

      await this.$store
        .dispatch(ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES, {
          id: this.id,
          datos: this.datosCRS
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroOCDELoading = false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la actualización. ${error}`
          );
          this.btnRegistroOCDELoading = false;
        });
    },

    async actualizarOtrosInstrumentos() {
      this.btnRegistroOtrosInstrumentosLoading = true;
      this.datosOtrosInstrumentos.proyectosCNSId = this.id;

      await this.$store
        .dispatch(ACTUALIZAR_OTROS_INSTRUMENTOS_CNS, {
          id: this.id,
          datos: this.datosOtrosInstrumentos,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroOtrosInstrumentosLoading = false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la actualización. ${error}`
          );
          this.btnRegistroOtrosInstrumentosLoading = false;
        });
    },

    async actualizarPoliticaPublica() {
      this.btnRegistroPoliticaPublicaLoading = true;
      this.datosPoliticaPublica.proyectosCNSId = this.id;

      await this.$store
        .dispatch(ACTUALIZAR_POLITICA_PUBLICA_CNS, {
          id: this.id,
          datos: this.datosPoliticaPublica,
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          //console.log(res)
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroPoliticaPublicaLoading = false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante la actualización. ${error}`
          );
          this.btnRegistroPoliticaPublicaLoading = false;
        });
    },

    /*  async obtenerEjecucionesPorActividadDeptoMuni(proyectoId){
            
            this.tableLoadingEjecucionesTerritorio=true;
            this.ejecucionesActividadTerritorio = [];
            this.totalEjecucionActividadTerritorio=0;
            await this.$store
            .dispatch(OBTENER_EJECUCIONES_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS, {estadoId: 1, id: proyectoId})
            .then(res => {
                if (res.status === 200) {
                    this.ejecucionesActividadTerritorio = res.data;
                    this.totalEjecucionActividadTerritorio = this.ejecucionesActividadTerritorio.reduce((a,b) => a + (b['montoQuetzales'] || 0), 0);

                }
                this.tableLoadingEjecucionesTerritorio=false;
            })
            .catch(() => {
                this.ejecucionesActividadTerritorio = [];
                this.tableLoadingEjecucionesTerritorio=false;
            });
        },
 */

    //Obtener el lsitado de proyeccion de desempbolosos registrados
    async obtenerMontosSuscritosDepto(proyectoId) {
      this.montosDepartamento = [];
      this.tableLoadingMontosDepto = true;
      this.totalMontoSuscritoDepartamento = "0.00";

      await this.$store
        .dispatch(OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.montosDepartamento = res.data;
            this.totalMontoSuscritoDepartamento = this.montosDepartamento.reduce(
              (a, b) => parseFloat(a) + (parseFloat(b["monto"]) || 0),
              0
            );
          }
          this.tableLoadingMontosDepto = false;
        })
        .catch(() => {
          this.montosDepartamento = [];
          this.tableLoadingMontosDepto = false;
        });
    },

    habilitarCoberturaTerritorial(tipo) {
      this.habilitarMontoTerritorial = false;
      if (tipo === 1) this.habilitarMontoTerritorial = true;
    }
  },

  created() {
    this.resetItems(1, this.id);
  },

  computed: {
    headersCoberturas() {
      return [
        {
          text: "Id",
          //align: "start",
          sortable: false,
          value: "id",
          align: " d-none"
        },
        /* {
                    text: "Municipio / Departamento",
                    align: "start",
                    sortable: true,
                    value: "nombreMunicipio"
                }, */
        {
          text: "Meta PND",
          align: "start",
          sortable: true,
          value: "nombre"
        },
        {
          text: "Meta ODS",
          align: "start",
          sortable: true,
          value: "correo"
        },
        {
          text: "Meta K`atun",
          align: "start",
          sortable: true,
          value: "metaKatun"
        },
        /*  {
                    text: "Monto suscrito",
                    align: "start",
                    sortable: true,
                    value: "monto"
                }, */
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: ""
        },
      ];
    },

    headersEjecucionSubdivisionTerritorio() {
      return [
        {
          text: "Actividad",
          align: "start",
          sortable: false,
          //divider: true,
          value: "nombreTipoDatoFinanciero"
        },
        {
          text: "Municipio y Departamento",
          align: "start",
          sortable: true,
          //divider: true,
          value: "nombreMoneda"
        },
        {
          text: "Monto Quetzales",
          align: "center",
          sortable: true,
          //divider: true,
          value: "nombreMoneda"
        },
        /* {
                    text: "Accion",
                    align: "start",
                    sortable: true,
                    //divider: true,
                    value: "nombreMoneda"
                }, */
      ];
    },

    headersMontoDepartamento() {
      return [
        {
          text: "Departamento",
          align: "start",
          sortable: false,
          value: "nombreTipoDatoFinanciero"
        },
        {
          text: "Monto GTQ",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        },
        /* {
                    text: "Tipo de Cambio",
                    align: "start",
                    sortable: true,
                    value: "tipoCambio"
                },
                {
                    text: "Monto Total Quetzales",
                    align: "start",
                    sortable: true,
                    value: "montoTotalQuetzales"
                },*/
        /* {
                    text: "Accion",
                    align: "start",
                    sortable: true,
                    value: "nombreMoneda"
                }, */
      ];
    }
  }
};
</script>
