<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="!skeletonLoading">
      <v-row>
        <v-col cols="12" md="12" sm="12" class="pt-0 mt-4 pb-0 mb-0">
          <v-subheader class="text-h5 black--text">
            Carga de documentos del proyecto
          </v-subheader>
        </v-col>
      </v-row>

      <v-row class="pb-3">
        <v-col cols="12" md="12" sm="12">
          <v-alert outlined color="#1488c2" border="left">
            <p class="mb-1">
              Los campos marcados con un asterisco (
              <span color="red" class="red--text">*</span>) son obligatorios
            </p>
          </v-alert>
        </v-col>
      </v-row>

      <v-form
        ref="formInstitucionesBeneficiarias"
        v-on:submit.prevent="actualizarCuentaOpinionTecnica"
        v-model="validFormOpinionTecnica"
      >
        <v-row>
          <v-col cols="12" md="7" sm="12" class="pt-6 pb-0">
            <v-select
              v-model="cuentaOpinionTecnica"
              :items="cuentaOpinionTecniaItems"
              :loading="false"
              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
              dense
              filled
              class="required"
              return-object
              label="¿El proyecto cuenta con opinión técnica?"
              item-text="text"
              item-value="id"
              menu-props="auto"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="5"
            sm="12"
            class="pt-6 pb-0"
            v-if="
              (tipoUsuario != `int` && !seccionesBloqueadas) ||
                tipoUsuario === `int`
            "
          >
            <v-btn
              color="light-blue-502"
              class="white--text mb-2 float-right"
              type="submit"
              medium
              :elevation="0"
              :disabled="
                !validFormOpinionTecnica ||
                  cuentaOpinionTecnica.id === 0 ||
                  (tipoUsuario == `ext` && seccionesBloqueadas)
              "
              :loading="btnRegistroCuentaOpinionTecnicaLoading"
            >
              Actualizar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col cols="12" md="12">
          <v-divider class="mt-0 pt-0"></v-divider>
        </v-col>
      </v-row>

      <v-card class="mt-6" outlined>
        <v-card-text>
          <v-form
            ref="form"
            v-on:submit.prevent="cargarArchivo"
            v-model="validForm"
            v-if="
              (tipoUsuario === `ext` && !seccionesBloqueadas) ||
                tipoUsuario === `int`
            "
          >
            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  v-model="tipoDocumentoSeleccionado"
                  :items="documentosCns"
                  :loading="false"
                  dense
                  filled
                  class="required"
                  return-object
                  label="Seleccione el tipo de documento a cargar"
                  item-text="documento"
                  item-value="id"
                  menu-props="auto"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <template>
                  <v-file-input
                    id="docFile"
                    dense
                    filled
                    placeholder="Seleccionar archivo .pdf"
                    :label="
                      tipoDocumentoSeleccionado.documento
                        ? `Seleccionar documento para ` +
                          tipoDocumentoSeleccionado.documento
                        : `Por favor, seleccione un tipo de documento`
                    "
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[fileRequired('documento')]"
                    :show-size="1000"
                    ref="docFile"
                    class="required"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                        text-overline
                                        grey--text
                                        text--darken-3
                                        mx-2
                                    "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0"> </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validForm"
                  :loading="btnRegistroLoading"
                >
                  Agregar documento
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-alert
                  outlined
                  type="warning"
                  color="deep-orange lighten-1"
                  dense
                >
                  El peso de los archivos no debe de superar los 20Mb
                </v-alert>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-divider></v-divider>
            <v-col cols="12" md="12" sm="12" class="pb-0">
              <v-subheader class="text-h6 black--text pb-0">
                Documentos cargados
              </v-subheader>
            </v-col>

            <!--inicio:: tabla de documentos cargados -->
            <v-col cols="12" md="12" sm="12" class="pt-4">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="documentosCargados"
                :loading="tableLoadingDocumentos"
                hide-default-footer
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.documento }}</td>
                    <td>
                      <v-chip
                        label
                        class="ma-2"
                        :color="
                          item.cargado === 0
                            ? 'deep-orange lighten-4'
                            : item.cargado > 0
                            ? 'teal lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.cargado === 0
                            ? 'deep-orange lighten-1'
                            : item.cargado > 0
                            ? 'teal lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.cargado === 0 ? `Sin cargar` : `Cargado` }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="item.cargado > 0"
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        :disabled="btnEliminarLoading"
                        depressed
                        color="blue-grey lighten-5"
                        @click="descargarArchivo(item.path)"
                      >
                        <v-icon left>mdi-file-download-outline</v-icon>
                        Visualizar
                      </v-btn>
                      <v-btn
                        v-if="
                          item.cargado > 0 &&
                            ((tipoUsuario != `int` && !seccionesBloqueadas) ||
                              tipoUsuario === `int`)
                        "
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        :disabled="btnEliminarLoading"
                        :loading="btnEliminarLoading"
                        depressed
                        color="blue-grey lighten-5"
                        @click="eliminarArchivo(item.asignacionId)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de proyección de fuente cooperante
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla de documentos cargados -->
          </v-row>
        </v-card-text>
      </v-card>
      <!--Inicio:: Snack alert-->
      <SnackAlert ref="snackalert"></SnackAlert>
      <!-- Fin:: Snack alert-->
    </div>
  </div>
</template>

<script>
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import {
  OBTENER_DATOS_SECCIONES_PROYECTO,
  ACTUALIZAR_CUENTA_CUENTA_OPINION_TECNIA_CNS
} from "@/core/services/store/proyectoscns/proyectocns.module";
import { OBTENER_DOCUMENTOS_CNS } from "@/core/services/store/proyectoscns/documentoscns/documentocns.module";
import {
  OBTENER_DOCUMENTOS_PROYECTO_CNS,
  ASIGNAR_DOCUMENTO_CNS,
  ELIMINAR_ASIGNACION_DOCUMENTO_CNS
} from "@/core/services/store/proyectoscns/documentoscns/asignaciondocumentocns.module";
import DownloadFile from "@/core/untils/downloadfile.js";
export default {
  name: "SeccionDocumentosProyecto",
  props: ["id", "tipoUsuario", "confirmacion"], //Tipo de usuario puede ser ext (externo) o int (interno administrador), confirmacion (si se van a bloquear los elementos porque solo se van a visualizar)
  components: {
    SnackAlert
  },
  data() {
    return {
      skeletonLoading: false,
      validForm: false,
      btnRegistroLoading: false,
      tableLoadingDocumentos: false,
      tipoProyectoId: 0,
      tiposDocumento: [
        { id: 1, text: "Documento de justificación" },
        { id: 2, text: "Documento de opinión técnica" },
        { id: 3, text: "Documento del proyecto" },
        { id: 4, text: "Documento de convenio" }
      ],
      tipoDocumento: { id: 0, text: "Seleccione un tipo de documento" },
      tipoDocumentoSeleccionado: {},
      archivo: [],
      datosItem: {},
      datosArchivo: {
        path: "",
        proyectosCNSId: this.id,
        documentosCNSId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      datosCuentaOpinionTecnica: {
        cuentaOpinionTecnica: 0
      },
      validFormOpinionTecnica: false,
      cuentaOpinionTecnica: { id: 0, text: "Seleccione" },
      cuentaOpinionTecniaItems: [
        { id: 0, text: "Seleccione" },
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      btnRegistroCuentaOpinionTecnicaLoading: false,
      documentosCns: [],
      documentosCargados: [],
      btnEliminarLoading: false,
      seccionesBloqueadas: false,
      ...validations
    };
  },

  methods: {
    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 20) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (20Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    resetItems(tipo, proyectoId) {
      if (tipo === 2) {
        this.skeletonLoading = true;
      }
      this.cuentaOpinionTecnica = { id: 0, text: "Seleccione" };
      this.resetForm();
      this.obtenerDatosGeneralesProyecto(proyectoId).then(() => {
        this.obtenerDocumentosCargados(proyectoId);
      });
    },

    resetForm() {
      this.tipoDocumentoSeleccionado = {};

      this.datosArchivo = {
        path: "",
        proyectosCNSId: this.id,
        documentosCNSId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    async obtenerDocumentosCns() {
      this.documentosCns = [];

      // this.cardDatosLoading = true;

      await this.$store
        .dispatch(OBTENER_DOCUMENTOS_CNS, {
          estadoId: 1,
          tipoProyectoId: this.tipoProyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.documentosCns = res.data;
            //this.cardDatosLoading = false;
          }
        })
        .catch(() => {
          this.documentosCns = [];
        });
    },

    //Obtener la información de cobertura
    async obtenerDatosGeneralesProyecto(proyectoId) {
      this.datosItem = {};
      this.cardDatosLoading = true;
      //console.log(proyectoId)
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: proyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.datosItem = res.data;
            //console.log(this.datosItem)
            //Bloquear las secciones si es usuario externo y si el proyecto no está en estado registro
            if (this.datosItem.estadoProyectoId != 1) {
              this.seccionesBloqueadas = true;
            } else {
              this.seccionesBloqueadas = false;
            }

            if (this.confirmacion) {
              this.seccionesBloqueadas = true;
            }

            this.tipoProyectoId = this.datosItem.cuentaConOpinionTecnica;
            if (this.datosItem.cuentaConOpinionTecnica) {
              this.cuentaOpinionTecnica = this.cuentaOpinionTecniaItems.find(
                item => item.id == this.datosItem.cuentaConOpinionTecnica
              );
            } else {
              this.cuentaOpinionTecnica = { id: 0, text: "Seleccione" };
            }

            this.obtenerDocumentosCns();
          }

          this.skeletonLoading = false;
          //this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
        })
        .catch(() => {
          this.skeletonLoading = false;
        });
    },

    //Actualizar el campo "El proyecto cuenta con opinion tecnica"
    async actualizarCuentaOpinionTecnica() {
      this.btnRegistroCuentaOpinionTecnicaLoading = true;

      this.datosCuentaOpinionTecnica.id = this.id;
      this.datosCuentaOpinionTecnica.cuentaOpinionTecnica = this.cuentaOpinionTecnica.id;
      this.datosCuentaOpinionTecnica.usuarioActualizacion = "admin";

      await this.$store
        .dispatch(ACTUALIZAR_CUENTA_CUENTA_OPINION_TECNIA_CNS, {
          datos: this.datosCuentaOpinionTecnica
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.tipoProyectoId = this.cuentaOpinionTecnica.id;
            this.obtenerDocumentosCns();
            this.obtenerDocumentosCargados(this.id);

            //this.cuentaOpinionTecnica.id===1 ? this.h = true : this.h = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroCuentaOpinionTecnicaLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnRegistroCuentaOpinionTecnicaLoading = false;
        });
    },

    //Obtener los documentos documentos cargados
    async obtenerDocumentosCargados(proyectoId) {
      this.documentosCargados = [];
      //tipo===1 ? this.skeletonLoading = true : this.tableLoadingDocumentos =true;

      await this.$store
        .dispatch(OBTENER_DOCUMENTOS_PROYECTO_CNS, {
          estadoId: 1,
          proyectoId: proyectoId,
          tipoProyectoId: this.tipoProyectoId
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.documentosCargados = res.data;
            /* 

                         data.pathDocuemntoJustificacion ? this.documentosCargados.push({id: 1, tipoDocumento: 'Documento de Justificación', estadosId: 1, estado: 'Cargado', path: data.pathDocuemntoJustificacion}) : 
                                               this.documentosCargados.push({id: 1, tipoDocumento: 'Documento de Justificación', estadosId: 2, estado: 'No cargado', path: ''});

                        data.pathDocumentoOpinionTecnica ? this.documentosCargados.push({id: 1, tipoDocumento: 'Documento de Opinión Técnica', estadosId: 1, estado: 'Cargado', path: data.pathDocumentoOpinionTecnica}) : 
                                               this.documentosCargados.push({id: 1, tipoDocumento: 'Documento de Opinión Técnica', estadosId: 2, estado: 'No cargado', path: ''});

                        
                        data.pathDocumentoProyecto ? this.documentosCargados.push({id: 1, tipoDocumento: 'Documento del Proyecto', estadosId: 1, estado: 'Cargado', path: data.pathDocumentoProyecto}) : 
                                               this.documentosCargados.push({id: 1, tipoDocumento: 'Documento del Proyecto', estadosId: 2, estado: 'No cargado', path: ''});

                        data.pathConvenio ? this.documentosCargados.push({id: 1, tipoDocumento: 'Documento de Convenio', estadosId: 1, estado: 'Cargado', path: data.pathConvenio}) : 
                                               this.documentosCargados.push({id: 1, tipoDocumento: 'Documento de Convenio', estadosId: 2, estado: 'No cargado', path: ''});
                        
                        
                        tipo===1 ? this.skeletonLoading = false : this.tableLoadingDocumentos =false; */
          }
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.documentosCargados = [];
          /* tipo===1 ? this.skeletonLoading = false : this.tableLoadingDocumentos =false; */
        });
    },

    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnRegistroLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cns\\documentos\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            this.datosArchivo.pathDocumentoRespaldo = res.pathArchivo;
            this.datosArchivo.path = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.registrarPath();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnRegistroLoading = false;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnRegistroLoading = false;
        });
    },

    //Registrar los path en la db
    async registrarPath() {
      this.datosArchivo.documentosCNSId = this.tipoDocumentoSeleccionado.id;
      this.datosArchivo.proyectosCNSId = this.id;

      await this.$store
        .dispatch(ASIGNAR_DOCUMENTO_CNS, { datos: this.datosArchivo })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDocumentosCargados(this.id);
            this.$emit("actualizar-avance-proyecto", this.id);
            //if(this.archivo && this.archivo .size){
            //console.log("aui")
            //document.querySelector('#respaldoFile').value = '';
            //this.$refs.respaldoFile.reset();
            //this.$refs.docFile.reset();
            this.archivo = [];
            document.querySelector("#docFile").value = "";
            this.$refs.docFile.reset();
            this.resetForm();
            //this.$refs['respaldoFile'][0].reset();
            //}

            this.btnRegistroLoading = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroLoading = false;
        });

      this.btnRegistroLoading = false;
    },

    async eliminarArchivo(id) {
      this.btnEliminarLoading = true;

      await this.$store
        .dispatch(ELIMINAR_ASIGNACION_DOCUMENTO_CNS, id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDocumentosCargados(this.id);
            //this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarLoading = false;
        })
        .catch(error => {
          this.btnEliminarLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro, por favor, póngase en contacto con un administrador del sistema. REF: ${error}`
          );
        });
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */
      DownloadFile.download(path);
    }
  },

  created() {
    // this.obtenerDocumentosCargados(this.id, 1);
    this.resetItems(2, this.id);
    //this.obtenerDocumentosCns();
  },

  computed: {
    headers() {
      {
        return [
          {
            text: "Tipo de documento",
            align: "start",
            sortable: false,
            value: "tipoDocumento"
          },
          {
            text: "Estado",
            align: "start",
            sortable: false,
            value: "estado"
          },
          {
            text: "Acciones",
            align: "start",
            sortable: true,
            value: "nombreMoneda"
          }
        ];
      }
    }
  }
};
</script>
